import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import MyLoader from "../../../common/MyLoader";
import InactiveServices from "../../../common/InactiveServices";
import AddUserForm from "./AddUserForm";
import SubmittedUser from "./SubmittedUser";

const SubTabForFRM = () => {
  const [activePhase, setActivePhase] = useState("Phase1");
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setActivePhase("Phase1");
  }, []);

  const handlePhaseClick = (phase) => {
    setActivePhase(phase);
  };

  return (
    <div>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec subtab-card">
        <div className="ds-header-sec">
          <Row>
            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-3  ds-inner-card">
                <Card.Body>
                  <div className="merchant-tab-btn">
                    <ul className="report-link nav-report-btn submenu-tab-admin">
                      <li
                        className={activePhase === "Phase1" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase1")}
                      >
                        Add User Form {activePhase === "Phase1"}
                      </li>

                      <li
                        className={activePhase === "Phase2" ? "active" : ""}
                        onClick={() => handlePhaseClick("Phase2")}
                      >
                        Submitted {activePhase === "Phase2"}
                      </li>
                    </ul>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <div className="admin-tab-content">
            {activePhase === "Phase1" ? (
              <AddUserForm setShowLoader={setShowLoader} />
            ) : activePhase === "Phase2" ? (
              <SubmittedUser />
            ) : (
              <InactiveServices />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubTabForFRM;
