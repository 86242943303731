import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { config, getCompleteDomain, getServerEnvironment } from '../../../utils/helper';

const SeamlessPaymentIntegration = () => {

    const {Name, panelApiDocsUrl}=config(getServerEnvironment(getCompleteDomain()))

    const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const htmlContent = `<html>

  <head>
  <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
  <meta name=Generator content="Microsoft Word 12 (filtered)">
  <title>Payment Gateway Integration SDK Kit</title>
  <style>
  <!--
   /* Font Definitions */
   @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
  @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;}
  @font-face
      {font-family:"Palatino Linotype";
      panose-1:2 4 5 2 5 5 5 3 3 4;}
   /* Style Definitions */
   p.MsoNormal, li.MsoNormal, div.MsoNormal
      {margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  h1
      {mso-style-link:"Heading 1 Char";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:1.45pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:14.0pt;
      font-family:"Calibri","sans-serif";}
  h2
      {mso-style-link:"Heading 2 Char";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.05pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  h3
      {mso-style-link:"Heading 3 Char";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:77.05pt;
      margin-bottom:.0001pt;
      line-height:12.15pt;
      text-autospace:none;
      font-size:10.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoHeader, li.MsoHeader, div.MsoHeader
      {mso-style-link:"Header Char";
      margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoFooter, li.MsoFooter, div.MsoFooter
      {mso-style-link:"Footer Char";
      margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
      {mso-style-link:"Body Text Char";
      margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:10.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  p.TableParagraph, li.TableParagraph, div.TableParagraph
      {mso-style-name:"Table Paragraph";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.95pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  span.HeaderChar
      {mso-style-name:"Header Char";
      mso-style-link:Header;
      font-family:"Calibri","sans-serif";}
  span.FooterChar
      {mso-style-name:"Footer Char";
      mso-style-link:Footer;
      font-family:"Calibri","sans-serif";}
  span.Heading2Char
      {mso-style-name:"Heading 2 Char";
      mso-style-link:"Heading 2";
      font-family:"Calibri","sans-serif";
      font-weight:bold;}
  span.BodyTextChar
      {mso-style-name:"Body Text Char";
      mso-style-link:"Body Text";
      font-family:"Calibri","sans-serif";}
  span.Heading1Char
      {mso-style-name:"Heading 1 Char";
      mso-style-link:"Heading 1";
      font-family:"Calibri","sans-serif";
      font-weight:bold;}
  span.Heading3Char
      {mso-style-name:"Heading 3 Char";
      mso-style-link:"Heading 3";
      font-family:"Calibri","sans-serif";
      font-weight:bold;}
  .MsoPapDefault
      {text-autospace:none;}
   /* Page Definitions */
   @page Section1
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section1
      {page:Section1;}
  @page Section2
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section2
      {page:Section2;}
  @page Section3
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section3
      {page:Section3;}
  @page Section4
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section4
      {page:Section4;}
  @page Section5
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section5
      {page:Section5;}
  @page Section6
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section6
      {page:Section6;}
  @page Section7
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section7
      {page:Section7;}
  @page Section8
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section8
      {page:Section8;}
  @page Section9
      {size:612.0pt 792.0pt;
      margin:68.0pt 58.0pt 53.0pt 57.0pt;}
  div.Section9
      {page:Section9;}
  -->
  </style>
  
  </head>
  
  <body lang=EN-US>
  
  <div class=Section1>
  
  <p class=MsoNormal><span style='font-size:10.0pt'>                                                </span></p>
  
  </div>
  
  <span style='font-size:10.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:always'>
  </span>
  
  <div class=Section2>
  
  <h1 style='margin-top:0cm;margin-right:.45pt;margin-bottom:0cm;margin-left:
  468.0pt;margin-bottom:.0001pt;text-indent:36.0pt'><span style='font-size:17.0pt'>****Version History<span
  style='letter-spacing:-.2pt'>****</span></span></h1>
  
  <p class=MsoBodyText><b>&nbsp;</b></p>
  
  <p class=MsoBodyText><b>&nbsp;</b></p>
  
  <p class=MsoBodyText style='margin-top:.05pt'><b>&nbsp;</b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:11.3pt;border-collapse:collapse;border:none'>
   <tr style='height:39.3pt'>
    <td width=104 valign=top style='width:77.75pt;border:solid black 1.0pt;
    background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.3pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b>&nbsp;</b></p>
    <p class=TableParagraph align=center style='margin-left:.15pt;text-align:
    center'><b><span style='letter-spacing:-.2pt'>Date</span></b></p>
    </td>
    <td width=77 valign=top style='width:57.4pt;border:solid black 1.0pt;
    border-left:none;background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.3pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b>&nbsp;</b></p>
    <p class=TableParagraph align=center style='margin-top:0cm;margin-right:.2pt;
    margin-bottom:0cm;margin-left:.5pt;margin-bottom:.0001pt;text-align:center'><b><span
    style='letter-spacing:-.1pt'>Version</span></b></p>
    </td>
    <td width=315 valign=top style='width:236.55pt;border:solid black 1.0pt;
    border-left:none;background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.3pt'>
    <p class=TableParagraph style='margin-top:7.0pt;margin-right:20.1pt;
    margin-bottom:0cm;margin-left:22.05pt;margin-bottom:.0001pt;text-indent:36.35pt;
    line-height:97%'><b>Amendment Comments (relating to version being introduced)</b></p>
    </td>
    <td width=1350 valign=top style='width:1012.85pt;border:solid black 1.0pt;
    border-left:none;background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.3pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b>&nbsp;</b></p>
    <p class=TableParagraph align=center style='margin-left:4.15pt;text-align:
    center'><b><span style='letter-spacing:-.1pt'>Owner</span></b></p>
    </td>
   </tr>
   <tr style='height:29.0pt'>
    <td width=104 valign=top style='width:77.75pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:29.0pt'>
    <p class=TableParagraph align=center style='margin-top:8.0pt;margin-right:
    .1pt;margin-bottom:0cm;margin-left:.15pt;margin-bottom:.0001pt;text-align:
    center'><span style='color:blue;letter-spacing:-.1pt'>11.09.2023</span></p>
    </td>
    <td width=77 valign=top style='width:57.4pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;padding:0cm 0cm 0cm 0cm;
    height:29.0pt'>
    <p class=TableParagraph align=center style='margin-top:8.0pt;margin-right:
    0cm;margin-bottom:0cm;margin-left:.5pt;margin-bottom:.0001pt;text-align:center'><span
    style='color:blue;letter-spacing:-.1pt'>1.0.0</span></p>
    </td>
    <td width=315 valign=top style='width:236.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:29.0pt'>
    <p class=TableParagraph style='margin-top:8.0pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:33.8pt;margin-bottom:.0001pt'><span style='color:blue'>Payment gateway document for <span
    style='letter-spacing:-.25pt'>API</span></span></p>
    </td>
    <td width=1350 valign=top style='width:1012.85pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:29.0pt'>
    <p class=TableParagraph align=center style='margin-top:7.95pt;margin-right:
    .95pt;margin-bottom:0cm;margin-left:4.15pt;margin-bottom:.0001pt;text-align:
    center'><span style='font-size:10.0pt;color:#365F91;letter-spacing:-.1pt'>${Name}</span></p>
    </td>
   </tr>
  </table>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 align=left
   style='border-collapse:collapse;border:none;margin-left:6.75pt;margin-right:
   6.75pt'>
   <tr style='height:14.6pt'>
    <td width=192 valign=top style='width:144.15pt;border:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:14.6pt'>
    <p class=TableParagraph style='margin-top:1.85pt;line-height:11.75pt'><b><span
    style='font-size:10.0pt;color:#365F91;letter-spacing:-.1pt'>Method</span></b></p>
    </td>
    <td width=192 valign=top style='width:144.15pt;border:solid black 1.0pt;
    border-left:none;padding:0cm 0cm 0cm 0cm;height:14.6pt'>
    <p class=TableParagraph style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:11.75pt'><b><span
    style='font-size:10.0pt;color:#365F91;letter-spacing:-.1pt'>Environment</span></b></p>
    </td>
    <td width=1467 valign=top style='width:1100.45pt;border:solid black 1.0pt;
    border-left:none;padding:0cm 0cm 0cm 0cm;height:14.6pt'>
    <p class=TableParagraph style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.05pt;margin-bottom:.0001pt;line-height:11.75pt'><b><span
    style='font-size:10.0pt;color:#365F91;letter-spacing:-.25pt'>URL</span></b></p>
    </td>
   </tr>
   <tr style='height:31.65pt'>
    <td width=192 valign=top style='width:144.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:31.65pt'>
    <p class=TableParagraph style='margin-top:1.95pt'><span style='font-size:
    10.0pt;color:#365F91;letter-spacing:-.2pt'>POST</span></p>
    </td>
    <td width=192 valign=top style='width:144.15pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:31.65pt'>
    <p class=TableParagraph style='margin-top:1.95pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.85pt;margin-bottom:.0001pt'><span style='font-size:10.0pt;
    color:#365F91;letter-spacing:-.1pt'>Production</span></p>
    </td>
    <td width=1467 valign=top style='width:1100.45pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:31.65pt'>
    <p class=TableParagraph style='margin-top:.9pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.05pt;margin-bottom:.0001pt'><a
    href=${panelApiDocsUrl.paymentIntegrationKitUrl}><span style='color:blue;letter-spacing:
    -.1pt'>${panelApiDocsUrl.paymentIntegrationKitUrl}</span></a></p>
    </td>
   </tr>
  </table>
  
  <p class=MsoNormal align=center style='text-align:center'><span
  style='font-size:10.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal align=center style='text-align:center'><span
  style='font-size:10.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-left:5.05pt'><b><span style='font-size:13.0pt'>Note:</span></b><span
  style='font-size:13.0pt'>Send post request with encrypted data using given <span
  style='letter-spacing:-.1pt'> parameter.</span></span></p>
  
  <p class=MsoNormal style='margin-top:.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>{</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:-.1pt'>&quot;1000000000007&quot;,</span></span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:237.5pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;enckey&quot;:&quot;8b3a40g766d94345b9d82ad02cfb1df1&quot;,
  &quot;orderNo&quot;: &quot;ORD23062614402635162&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-left:45.6pt'><span style='font-size:13.0pt'>&quot;amount&quot;:<span
  style='letter-spacing:-.1pt'>&quot;100&quot;,</span></span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;currency&quot;:<span
  style='letter-spacing:-.1pt'>&quot;INR&quot;,</span></span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnReqType&quot;:<span
  style='letter-spacing:-.2pt'>&quot;S&quot;,</span></span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:324.1pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;respUrl&quot;:&quot;<a
  href="http://www.google.com/"><span style='color:windowtext;text-decoration:
  none'>www.google.com</span></a>&quot;, &quot;emailId&quot;: &quot;<a
  href="mailto:test@gmail.com"><span style='color:windowtext;text-decoration:
  none'>test@gmail.com</span></a>&quot;, &quot;mobileNo&quot;:
  &quot;9090909090&quot;</span></p>
  
  <p class=MsoNormal style='margin-left:41.05pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Encrypt entire data with your
  enckey and post the payload (encrypteddata) and <span style='letter-spacing:-.2pt'> mid.</span></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:8.15pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h2><span style='font-size:13.0pt'>Actual<span style='letter-spacing:-.1pt'>Request:</span></span></h2>
  
  <p class=MsoNormal style='margin-top:.15pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  color:black;letter-spacing:-.5pt;background:#E8F1FD'>{</span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
  color:black;letter-spacing:-.1pt;background:#E8F1FD'>&quot;mid&quot;:&quot;SWIPEPROD100007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  color:black;background:#E8F1FD'>&quot;payload&quot;: <span style='letter-spacing:
  -.1pt'>&quot;xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsyzoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7wofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=&quot;</span></span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt;line-height:12.15pt'><span
  style='font-size:13.0pt;color:black;letter-spacing:-.5pt;background:#E8F1FD'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:2.4pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:28.45pt;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Response:</span></b><span
  style='font-size:13.0pt'>Once the transaction completed successfully ,Process will be redirect to merchant provided
  return URL with below mentioned parameter.</span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:324.1pt;margin-bottom:
  0cm;margin-left:41.15pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;message&quot;:&quot;SUCCESS&quot;,
  &quot;statusCode&quot;: &quot;OK&quot;, &quot;responseData&quot;: {</span></p>
  
  <p class=MsoNormal style='margin-top:.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:49.3pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;amount&quot;:<span style='letter-spacing:-.1pt'>&quot;100&quot;,</span></span></p>
  
  <p class=MsoNormal style='margin-left:49.3pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;orderNo&quot;:<span style='letter-spacing:-.1pt'>&quot;ORD23091114541117906&quot;,</span></span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:237.5pt;margin-bottom:
  0cm;margin-left:47.15pt;margin-bottom:.0001pt;text-indent:2.15pt'><span
  style='font-size:13.0pt'>&quot;created_on&quot;:&quot;MonSep11
  14:54:31IST2023&quot;, <span style='letter-spacing:-.1pt'>&quot;qrString&quot;:</span></span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:8.5pt;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>&quot;upi://pay?ver=01&amp;mode=15&amp;am=100.00&amp;mam=100.00&amp;cu=INR&amp;pa=XXXXXXXXXXXXXXXXXXX&amp;pn=XXXXXXXXXXXXXXXX&amp;</span><span
  style='font-size:13.0pt'>mc=8299&amp;tr=Zenex52149242658716179885&amp;tn=Pay to
  Merchant&amp;mid=ZENEX001&amp;msid=ZENEX001- <span style='letter-spacing:-.1pt'>ANUBHAV&amp;mtid=ZENEX001-001&quot;,</span></span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:313.45pt;margin-bottom:
  0cm;margin-left:49.3pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:
  &quot;SWIPEPROD100007&quot;, &quot;txnId&quot;:&quot;52149242658716179885&quot;</span></p>
  
  <p class=MsoNormal style='margin-left:41.15pt;line-height:10.95pt'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>}</span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt;line-height:10.95pt'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>}</span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h2 style='margin-top:2.45pt'><span style='font-size:13.0pt;letter-spacing:
  -.1pt'>Callback:</span></h2>
  
  <p class=MsoNormal style='margin-top:6.7pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt;line-height:150%'><span
  style='font-size:13.0pt;line-height:150%'>Merchant needs to provide callback
  endpoint where payment engine will provide callback response with below mention
  <span style='letter-spacing:-.1pt'> parameter.</span></span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:313.45pt;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;line-height:150%'><span
  style='font-size:13.0pt;line-height:150%'>CallbackURL: Provided by merchant Data
  sent in callback:</span></p>
  
  <p class=MsoNormal style='margin-left:41.05pt;line-height:10.8pt'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>{</span></p>
  
  <p class=MsoNormal style='margin-top:5.5pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;<span
  style='color:#2A00FF;background:#E8F1FD'>mid</span><span style='color:black'>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;1000000000007&quot;,</span></span></span></p>
  
  <p class=MsoNormal style='margin-top:6.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;text-indent:4.05pt;line-height:
  147%'><span style='font-size:13.0pt;line-height:147%'>&quot;<span
  style='color:#2A00FF;background:#E8F1FD'>data</span><span style='color:black'>&quot;:
  &quot;data will be encrypted string, merchant will need to decrypt the data
  using above mentioned encryption <span style='letter-spacing:-.1pt'>alogrithm&quot;,</span></span></span></p>
  
  <p class=MsoNormal style='margin-top:.5pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:45.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;<span
  style='color:#2A00FF;background:#E8F1FD'>signature</span><span
  style='color:black'>&quot;:&quot; XXXXXXXXXXXXXXX <span style='letter-spacing:
  -.5pt'>&quot;</span></span></span></p>
  
  <p class=MsoNormal style='margin-top:5.9pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:5.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'> Signature/Checksum:</span></b><span
  style='font-size:13.0pt'> Signature is check sum value that is generate to check the data integrity.For generate
  checksum/signature please use response field at given sequence-</span></p>
  
  <p class=MsoNormal style='margin-left:5.05pt'><span style='font-size:13.0pt'>Mid+txnId+orderNo+<span
  style='letter-spacing:-.1pt'>txnStatus</span></span></p>
  
  <p class=MsoNormal style='margin-top:13.35pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'> Kindly decrypt the data and recalculate the signature using above mentioned 4 parameter and compare both
  the signature.</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-left:5.05pt'><span style='font-size:13.0pt'>Signature/checksum generation <span
  style='letter-spacing:-.1pt'>  algorithm:</span></span></p>
  
  <p class=MsoBodyText style='margin-top:1.25pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-left:5.05pt'><span style='font-size:13.0pt'>Prepare data and call <span
  style='color:black;background:silver'> get ChecksumCRC32 method to generate the <span
  style='letter-spacing:-.1pt'> checksum : </span></span></span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>String <span
  style='color:#6A3D3D'>checksum Str </span> = <span style='color:#6A3D3D'>transaction</span>.getMid() + <span
  style='color:#6A3D3D'> transaction </span> .getTxnId() + <span style='color:#6A3D3D'>transaction</span> .getOrderNo() + 
  <span style='color:#6A3D3D;letter-spacing:-.1pt'>transaction</span><span
  style='letter-spacing:-.1pt'>.getTxnStatus();</span></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:123.45pt;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Input Stream <span
  style='color:#6A3D3D'> target Stream </span>=<b><span style='color:#7E0054'> new </span></b>Byte Array Input Stream(<span
  style='color:#6A3D3D'> checksum Str </span> .getBytes()); <span style='color:#6A3D3D'> checkSum
  </span> = <i> <span style='color:black;background:silver'>get ChecksumCRC32 </span> </i> <span
  style='color:black'>(</span><span style='color:#6A3D3D'>target Stream</span><span
  style='color:black'>, </span><span style='color:#6A3D3D'> checksum Str</span><span
  style='color:black'>.length());</span></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:2.25pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-left:5.05pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>Algorithm:</span></p>
  
  <p class=MsoNormal style='margin-top:.15pt;margin-right:53.0pt;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;text-indent:-36.0pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>public static long </span></b><span
  style='font-size:13.0pt;color:black;background:silver'>getChecksumCRC32</span><span
  style='font-size:13.0pt;color:black'>(Input Stream </span><span
  style='font-size:13.0pt;color:#6A3D3D'> stream </span><span style='font-size:
  13.0pt;color:black'>, </span><b><span style='font-size:13.0pt;color:#7E0054'> int
  </span></b><span style='font-size:13.0pt;color:#6A3D3D'>buffer Size</span><span
  style='font-size:13.0pt;color:black'>) </span><b><span style='font-size:13.0pt;
  color:#7E0054'>throws </span></b><span style='font-size:13.0pt;color:black'>IO Exception
  { CheckedInputStream</span><span style='font-size:13.0pt;color:#6A3D3D'>checked Input Stream </span><span
  style='font-size:13.0pt;color:black'> = </span><b><span style='font-size:13.0pt;
  color:#7E0054' >new </span></b><span style='font-size:13.0pt;color:black'> Checked Input Stream(</span><span
  style='font-size:13.0pt;color:#6A3D3D'> stream </span><span style='font-size:
  13.0pt;color:black'>,</span><b><span style='font-size:13.0pt;color:#7E0054'> new </span></b><span
  style='font-size:13.0pt;color:black'>CRC32()); </span><b><span
  style='font-size:13.0pt;color:#7E0054'>byte</span></b><span style='font-size:
  13.0pt;color:black'>[] </span><span style='font-size:13.0pt;color:#6A3D3D'>buffer
  </span><span style='font-size:13.0pt;color:black'>= </span><b><span
  style='font-size:13.0pt;color:#7E0054'>new byte</span></b><span
  style='font-size:13.0pt;color:black'>[</span><span style='font-size:13.0pt;
  color:#6A3D3D'>bufferSize</span><span style='font-size:13.0pt;color:black'>];</span></p>
  
  <p class=MsoBodyText style='margin-left:41.05pt;line-height:12.2pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>while</span></b><span style='font-size:
  13.0pt'>(<span style='color:#6A3D3D'>checked Input Stream </span>.read(<span
  style='color:#6A3D3D'>buffer</span>,0,<span style='color:#6A3D3D'>buffer</span>.<span
  style='color:#0000C0'>length</span>)&gt;=0)<span style='letter-spacing:-.5pt'>{</span></span></p>
  
  <p class=MsoNormal style='margin-left:41.05pt'><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><b><i><span style='font-size:
  13.0pt;color:#0000C0'>LOGGER</span></i></b><span style='font-size:13.0pt'>.info(<span
  style='color:#2A00FF'>&quot;Checksum:&quot;</span>+<span style='color:#6A3D3D;
  letter-spacing:-.1pt'>checkedInputStream</span><span style='letter-spacing:
  -.1pt'>.getChecksum().getValue());</span></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>return</span></b><span style='font-size:13.0pt;color:#6A3D3D;
  letter-spacing:-.1pt'>checked Input Stream</span><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>.get Checksum().getValue();</span></p>
  
  <p class=MsoNormal style='margin-left:7.3pt;line-height:12.15pt'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:12.15pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h2 style='margin-top:.05pt'><span style='font-size:13.0pt'>Encryption<span
  style='letter-spacing:-.1pt'> Algorithm</span></span></h2>
  
  <p class=MsoBodyText style='margin-left:5.05pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>public </span></b><span style='font-size:13.0pt'>String encrypt Data (String<span
  style='color:#6A3D3D'> plain text </span>,String<span style='color:#6A3D3D'> encrypt Key </span>)<span
  style='letter-spacing:-.5pt'> {</span></span></p>
  
  <h3 style='margin-left:41.05pt;line-height:normal'><span style='font-size:13.0pt;
  color:#7E0054'>try</span><span style='font-size:13.0pt;letter-spacing:-.5pt;
  font-weight:normal'>{</span></h3>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:28.45pt;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>SecretKeySpec<span
  style='color:#6A3D3D'>secretKeySpec</span>=<b><span style='color:#7E0054'>new</span></b>SecretKeySpec(<span
  style='color:#6A3D3D'>encryptKey</span>.getBytes(<span style='color:#2A00FF'>&quot;UTF-8&quot;</span>),<span
  style='color:#2A00FF'>&quot;AES&quot;</span>); Cipher <span style='color:#6A3D3D'>cipher
  </span>= Cipher.<i>getInstance</i>(<span style='color:#2A00FF'>&quot;AES/CBC/PKCS5Padding&quot;</span>);
  <span style='color:#6A3D3D'>cipher</span>.init(Cipher.<b><i><span
  style='color:#0000C0'>ENCRYPT_MODE</span></i></b>, <span style='color:#6A3D3D'>secretKeySpec</span>);</span></p>
  
  <p class=MsoBodyText style='margin-left:77.05pt;line-height:12.2pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>byte</span></b><span style='font-size:
  13.0pt'>[]<span style='color:#6A3D3D'>ivBytes</span>=<span style='color:#6A3D3D;
  letter-spacing:-.1pt'>cipher</span><span style='letter-spacing:-.1pt'>.getParameters().getParameterSpec(IvParameterSpec.<b><span
  style='color:#7E0054'>class</span></b>).getIV();</span></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt;line-height:12.15pt'><b><span
  style='font-size:13.0pt;color:#7E0054;letter-spacing:-.1pt'>byte</span></b><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>[]<span style='color:#6A3D3D'>encryptedData</span>=<span
  style='color:#6A3D3D'>cipher</span>.doFinal(<span style='color:#6A3D3D'>plaintext</span>.getBytes(<span
  style='color:#2A00FF'>&quot;UTF-8&quot;</span>));</span></p>
  
  <p class=MsoBodyText style='margin-left:77.05pt;line-height:12.15pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>byte</span></b><span style='font-size:
  13.0pt'>[]<span style='color:#6A3D3D'>combined Bytes</span>=<b><span
  style='color:#7E0054'>newbyte</span></b>[<span style='color:#6A3D3D'>ivBytes</span>.<span
  style='color:#0000C0'>length</span>+<span style='color:#6A3D3D;letter-spacing:
  -.1pt'>encryptedData</span><span style='letter-spacing:-.1pt'>.<span
  style='color:#0000C0'>length</span>];</span></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:always'>
  </span>
  
  <div class=Section3>
  
  <p class=MsoBodyText style='margin-top:2.45pt;margin-right:28.45pt;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>System.<i>arraycopy</i>(<span
  style='color:#6A3D3D'>ivBytes</span>, 0, <span style='color:#6A3D3D'>combinedBytes</span>,
  0, <span style='color:#6A3D3D'>ivBytes</span>.<span style='color:#0000C0'>length</span>);
  System.<i>arraycopy</i>(<span style='color:#6A3D3D'>encryptedData</span>,0,<span
  style='color:#6A3D3D'>combinedBytes</span>,<span style='color:#6A3D3D'>ivBytes</span>.<span
  style='color:#0000C0'>length</span>,<span style='color:#6A3D3D'>encryptedData</span>.<span
  style='color:#0000C0'>length</span>); <b><span style='color:#7E0054'>return </span></b>Base64.<i>getEncoder</i>().encodeToString(<span
  style='color:#6A3D3D'>combinedBytes</span>);</span></p>
  
  <p class=MsoNormal style='margin-top:.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}<b><span style='color:#7E0054'>catch</span></b>(Exception<span
  style='color:#6A3D3D'>e</span>)<span style='letter-spacing:-.5pt'>{</span></span></p>
  
  <p class=MsoBodyText style='margin-left:77.05pt;line-height:12.15pt'><b><i><span
  style='font-size:13.0pt;color:#0000C0'>LOGGER</span></i></b><span
  style='font-size:13.0pt'>.error(<span style='color:#2A00FF'>&quot;Exceptioninencrypt():encryptMData-&quot;</span>+<span
  style='color:#6A3D3D'>encryptKey</span>+<span style='color:#2A00FF'>&quot;:Message-&quot;</span><span
  style='letter-spacing:-.5pt'>+</span></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:auto'>
  </span>
  
  <div class=Section4>
  
  <p class=MsoBodyText style='margin-left:5.05pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>e.getMessage());</span></p>
   
  
  <p class=MsoNormal align=center style='margin-left:12.15pt;text-align:center'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>}</span></p>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all>
  </span>
  
  <p class=MsoNormal style='margin-top:.05pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-left:1.95pt;line-height:12.15pt'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>}</span></p>
  
  <h3 style='margin-left:1.95pt'><span style='font-size:13.0pt;color:#7E0054'>return<span
  style='letter-spacing:-.1pt'>null</span></span><span style='font-size:13.0pt;
  letter-spacing:-.1pt;font-weight:normal'>;</span></h3>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:auto'>
  </span>
  
  <div class=Section5>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-left:41.05pt;line-height:12.15pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>public</span></b><span style='font-size:
  13.0pt'>StringdecryptData(String<span style='color:#6A3D3D'>encryptedString</span>,String<span
  style='color:#6A3D3D'>encryptKey</span>)<span style='letter-spacing:-.5pt'>{</span></span></p>
  
  <h3><span style='font-size:13.0pt;color:#7E0054'>try</span><span
  style='font-size:13.0pt;letter-spacing:-.5pt;font-weight:normal'>{</span></h3>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:113.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>byte</span></b><span style='font-size:13.0pt'>[]<span
  style='color:#6A3D3D'>encryptedData</span>=<span style='letter-spacing:-.1pt'>Base64.<i>getDecoder</i>().decode(<span
  style='color:#6A3D3D'>encryptedString</span>);</span></span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:123.45pt;margin-bottom:
  0cm;margin-left:113.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>byte</span></b><span style='font-size:13.0pt'>[] <span
  style='color:#6A3D3D'>ivBytes </span>= <b><span style='color:#7E0054'>new byte</span></b>[16];
  System.<i>arraycopy</i>(<span style='color:#6A3D3D'>encryptedData</span>,0,<span
  style='color:#6A3D3D'>ivBytes</span>,0,16);</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-left:113.05pt'><b><span style='font-size:
  13.0pt;color:#7E0054'>byte</span></b><span style='font-size:13.0pt'>[] <span
  style='color:#6A3D3D'>encryptedBytes </span>= <b><span style='color:#7E0054'>new
  byte</span></b>[<span style='color:#6A3D3D'>encryptedData</span>.<span
  style='color:#0000C0'>length </span>- 16]; System.<i>arraycopy</i>(<span
  style='color:#6A3D3D'>encryptedData</span>, 16, <span style='color:#6A3D3D'>encryptedBytes</span>,
  0, <span style='color:#6A3D3D'>encryptedData</span>.<span style='color:#0000C0'>length
  </span>- 16); SecretKeySpec<span style='color:#6A3D3D'>secretKeySpec</span>=<b><span
  style='color:#7E0054'>new</span></b>SecretKeySpec(<span style='color:#6A3D3D'>encryptKey</span>.getBytes(<span
  style='color:#2A00FF'>&quot;UTF-8&quot;</span>),<span style='color:#2A00FF'>&quot;AES&quot;</span>);
  IvParameterSpec <span style='color:#6A3D3D'>ivParameterSpec </span>= <b><span
  style='color:#7E0054'>new </span></b>IvParameterSpec(<span style='color:#6A3D3D'>ivBytes</span>);</span></p>
  
  <p class=MsoBodyText style='margin-top:.1pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:auto'>
  </span>
  
  <div class=Section6>

  <p class=MsoBodyText style='margin-left:5.05pt'><span style='font-size:13.0pt'>e.getMessage());</span></p>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all>
  </span>
  
  <p class=MsoBodyText style='margin-top:3.0pt;margin-right:76.15pt;margin-bottom:
  0cm;margin-left:37.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Cipher
  cipher = Cipher.getInstance(&quot;AES/CBC/PKCS5Padding&quot;); cipher.init(Cipher.DECRYPT_MODE,secretKeySpec,ivParameterSpec);
  byte[] decryptedData = cipher.doFinal(encryptedBytes);</span></p>
  
  <p class=MsoNormal style='margin-left:37.95pt;line-height:12.2pt'><span
  style='font-size:13.0pt'>returnnewString(decryptedData);</span></p>
  
  <p class=MsoNormal style='margin-left:1.95pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}catch(Exceptione){</span></p>
  
  <p class=MsoBodyText style='margin-left:37.95pt;line-height:12.15pt'><b><i><span
  style='font-size:13.0pt;color:#0000C0'>LOGGER</span></i></b><span
  style='font-size:13.0pt'>.error(<span style='color:#2A00FF'>&quot;ExceptionindecryptMData():encryptKey-&quot;</span>+<span
  style='color:#6A3D3D'>encryptKey</span>+<span style='color:#2A00FF'>&quot;:Message-&quot;</span><span
  style='letter-spacing:-.5pt'>+</span></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:always'>
  </span>
  
  <div class=Section7>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:77.05pt;margin-bottom:.0001pt;line-height:12.15pt'><span
  style='font-size:13.0pt;letter-spacing:-.5pt'>}</span></p>
  
  <h3><span style='font-size:13.0pt;color:#7E0054'>return<span style='letter-spacing:
  -.1pt'>null</span></span><span style='font-size:13.0pt;letter-spacing:-.1pt;
  font-weight:normal'>;</span></h3>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  letter-spacing:-.5pt'>}</span></b></p>
  
  <h1 style='margin-top:13.35pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  33.0pt;margin-bottom:.0001pt'><span style='color:#1F467A;letter-spacing:-.1pt'>Appendix:</span></h1>
  
  <p class=MsoNormal style='margin-top:9.4pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><b>1.Response <span style='letter-spacing:
  -.1pt'> Codes : </span></b></p>
  
  <p class=MsoBodyText style='margin-top:.4pt'><b><span style='font-size:7.0pt'>&nbsp;</span></b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:33.5pt;border-collapse:collapse;border:none'>
   <tr style='height:26.35pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:26.35pt'>
    <p class=TableParagraph style='line-height:13.4pt'><b>Response <span
    style='letter-spacing:-.2pt'> Code </span></b></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border:solid black 1.0pt;
    border-left:none;padding:0cm 0cm 0cm 0cm;height:26.35pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'><b>Response <span
    style='letter-spacing:-.1pt'> Message </span></b></p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'><span style='letter-spacing:
    -.25pt'>200</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'><span
    style='letter-spacing:-.1pt'>Success</span></p>
    </td>
   </tr>
   <tr style='height:22.05pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.05pt'>
    <p class=TableParagraph style='line-height:13.4pt'><span style='letter-spacing:
    -.25pt'>417</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.05pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'><span
    style='letter-spacing:-.1pt'>Fail/Exception</span></p>
    </td>
   </tr>
   <tr style='height:22.15pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.15pt'>
    <p class=TableParagraph style='margin-top:.3pt'><span style='letter-spacing:
    -.25pt'>198</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.15pt'>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.2pt;margin-bottom:.0001pt'><span style='letter-spacing:
    -.1pt'>Transaction processing </span></p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'><span style='letter-spacing:
    -.25pt'>197</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'>Rejected by <span
    style='letter-spacing:-.1pt'> acquirer</span></p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'><span style='letter-spacing:
    -.25pt'>99</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'><span
    style='letter-spacing:-.1pt'>failed</span></p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'><span style='letter-spacing:
    -.25pt'>194</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'><span
    style='letter-spacing:-.1pt'>Declined</span></p>
    </td>
   </tr>
   <tr style='height:36.8pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:36.8pt'>
    <p class=TableParagraph style='line-height:13.4pt'><span style='letter-spacing:
    -.25pt'>193</span></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:36.8pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.2pt;margin-bottom:.0001pt;line-height:105%'>Authorization success but error processing
    recurring payment</p>
    </td>
   </tr>
  </table>
  
  <p class=MsoNormal>&nbsp;</p>
  
  </div>
  
  <span style='font-size:10.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:always'>
  </span>
  
  <div class=Section8>
  
  <p class=MsoBodyText style='margin-top:7.55pt'><b>&nbsp;</b></p>
  
  <p class=MsoBodyText style='margin-top:10.9pt'><b><span style='font-size:11.0pt'>&nbsp;</span></b></p>
  
  </div>
  
  <span style='font-size:9.0pt;font-family:"Calibri","sans-serif"'><br clear=all
  style='page-break-before:always'>
  </span>
  
  <div class=Section9>
  
  <h2 style='margin-top:2.45pt'>&nbsp;</h2>
  
  </div>
  
  </body>
  
  </html>
  `;

  return (
    <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
      <Container>
        <div className="ds-header-sec">
          <Row>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success mb-3" onClick={goBack}>
                {" "}
                &#8592; Back
              </button>
            </div>

            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4 ds-header-card">
                <Card.Body>
                  <div
                    className="merchant-tab-btn  p-3  "
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  ></div>
                </Card.Body>
              </Card>
              {/* ./card */}
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-3" onClick={goBack}>
              {" "}
              &#8592; Back
            </button>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default SeamlessPaymentIntegration