import React, { useEffect, useState } from "react";
import { Col, Row, Card, Modal } from "react-bootstrap";
import Papa from "papaparse";
import { MDBDataTable } from "mdbreact";
import {
  viewPayoutRequest,
  viewPayoutRequestByMid,
} from "../../services/apiService";
import jwtDecode from "jwt-decode";
import { FiDownload } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ViewPayoutResponse from "./ViewPayoutResponse";

const ViewRequestPayout = ({
  setShowLoader,
  responseData,
  setCustomMultiApiResponse,
}) => {
  const [active, setActive] = useState("customer");
  const [activeButton, setActiveButton] = useState(1);
  // const [apiResponse, setApiResponse] = useState([]);
  const [errorText, setErrorText] = useState("");
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const [viewResponseModal, setViewResponseModal] = useState(false);
  const decodedToken = jwtDecode(token);
  const [viewResponseTxnId, setViewResponseTxnId] = useState()
  const [viewResponseRefId, setViewResponseRefId] = useState()

  const { mid } = decodedToken;

  const navigate = useNavigate();
  const toggleViewResponseModal = () => {
    setViewResponseModal(!viewResponseModal);
  }

  const setViewResponseTxnAndRefId = (referenceId, txnId) => {
    setViewResponseRefId(referenceId)
    setViewResponseTxnId(txnId)
  }

  const handleToolsButtonClick = (reportType, activeButton) => {
    setActiveButton(activeButton);
  };

  const payload = {
    mid: mid,
    payoutFor: `${activeButton === 1 ? "customer" : "vendor"}`,
  };

  const call = async (payload, token, setCustomMultiApiResponse) => {
    try {
      setShowLoader(true);
      const res = await viewPayoutRequestByMid(mid, token);
      // console.log(res);
 

      if (res.statusCode === 200) {
        setShowLoader(false);
        setCustomMultiApiResponse(res.data );
       
        setErrorText("");
      } else {
        setCustomMultiApiResponse([] );
        setErrorText("Internet Disconnected");
        setShowLoader(false);
      }
    } catch (error) {
      setErrorText("Sometthing went wrong");
      // Handle the error here, e.g., display an error message to the user.
    }
  };

  const calculateTotalAmountForSuccess = (tableData) => {
    // Filter the data to include only "success" transactions
    const successTransactions = tableData?.filter(
      (item) => item.txnStatus?.toUpperCase() === "SUCCESS"
    );

    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    // Calculate the total sum of amounts for success transactions
  };

  const totalAmountForSuccess = calculateTotalAmountForSuccess(responseData);

  useEffect(() => {
    call(payload, token, setCustomMultiApiResponse);
  }, [activeButton]);

  const column = [
    {
      label: "Created Date",
      field: "createdDate",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Created Time",
      field: "createdTime",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Vendor Name",
      field: "fullName",
      sort: "disabled",
    },
    ...(activeButton === 2
      ? [
          {
            label: "Category",
            field: "category",
            sort: "asc",
          },
        ]
      : []),
    {
      label: "Mobile",
      field: "mobileNo",
      sort: "asc",
      width: "auto",
    },
    { label: "Txn Request", field: "txnRequestTime", width: "auto" },
    { label: "Txn Response", field: "txnResponseTime", width: "auto" },
    {
      label: "Callback Received",
      field: "callbackReceiveTime",
      width: "auto",
    },
    {
      label: "Callback Send To Merchant",
      field: "callbackSendTime",
      width: "auto",
    },
    {
      label: "Callback Acknowlegment Received",
      field: "merchantcallbackResponseTime",
      width: "auto",
    },
    { label: "Callback Json Received from bank", field: "callbackJsonReceived", width: "auto" },
    // { label: "Callback Json", field: "callbackJSON", width: "auto" },
    // {
    //   label: "Merchant Callback Received",
    //   field: "merchantCallbackRecived",
    //   width: "auto",
    // },
    { label: "Merchant Callback json", field: "merchantcallbackResponse", width: "auto" },
    {
      label: "VPA / UPI ID",
      field: "upi",
      sort: "asc",
      width: "auto",
    },
    {
      label: "e-Mail ID",
      field: "email",
      sort: "asc",
      width: "850px",
    },

    {
      label: "Reference ID",
      field: "referenceId",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transfer Type",
      field: "transferType",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction ID",
      field: "txnId",
      sort: "asc",
      width: "auto",
    },
    {
      label: "UTR Number",
      field: "rrn",
      sort: "asc",
      width: "auto",
    },

    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Account Number",
      field: "accountNumber",
      sort: "asc",
      width: "auto",
    },
    {
      label: "IFSC Code",
      field: "ifscCode",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Request Status",
      field: "payoutStatus",
      sort: "disabled",
      width: "400px",
    },
    {
      label: "Transaction Status",
      field: "txnStatus",
      sort: "disabled",
      width: "400px",
    },
    {
      label: "Action",
      field: "action",
      sort: "disabled",
      width: "400px",
    },
  ];

  function handleViewButtonClick(referenceId, txnId) {
    //  Navigate( )
    navigate(`/ViewPayoutResponse/${referenceId}/${txnId}`);
  }
  console.log(responseData)

  const row = responseData?.map((item) => {
    return {
      createdDate: item.createdDate !== null ? item.createdDate : "",
      createdTime: item.createdTime !== null ? item.createdTime : "",
        txnRequestTime: item?.txnRequestTime?.split(" ")[1],
       txnResponseTime: item?.txnResponseTime?.split(" ")[1],
       callbackReceiveTime: item?.callbackReceiveTime?.split(" ")[1],
       callbackSendTime: item?.callbackSendTime?.split(" ")[1],
       merchantcallbackResponseTime: item?.merchantcallbackResponseTime?.split(" ")[1],
       "callbackJsonReceived":item?.callbackJson ? "Received" : "Not Received",
       merchantcallbackResponse: item.merchantcallbackResponse,
      amount: item.amount !== null ? item.amount : "",
      transferType: item.transferType !== null ? item.transferType : "",
      referenceId: item.referenceId !== null ? item.referenceId : "",
      txnId: item.txnId !== null ? item.txnId : "",
      category: item.category !== null ? item.category : "",
      accountNumber: item.accountNumber !== null ? item.accountNumber : "",
      ifscCode: item.ifscCode !== null ? item.ifscCode : "",
      rrn: item.rrn !== null ? item.rrn : "",
      email: item.email !== null ? item.email : "",
      fullName: item.fullName !== null ? item.fullName : "",
      mobileNo: item.mobileNo !== null ? item.mobileNo : "",
      payoutFor: item.payoutFor !== null ? item.payoutFor : "",
      upi: item.upi !== null ? item.upi : "",
      payoutStatus:
        item.payoutStatus === "reject"
          ? "REJECTED"
          : item.payoutStatus !== null
          ? item.payoutStatus?.toUpperCase()
          : "NA",
      txnStatus:
        item.txnStatus === null || item.txnStatus === ""
          ? "NA"
          : item.txnStatus === "panding"
          ? "PENDING"
          : item.txnStatus?.toUpperCase(),
      action:
        item.txnStatus === "pending" || item.txnStatus === "panding" ? (
          <button
            className="btn btn-primary btn-sm"
            // onClick={() => handleViewButtonClick(item.referenceId, item.txnId)}
            onClick={() => {
              setViewResponseTxnAndRefId(item.referenceId, item.txnId)
              toggleViewResponseModal()}
            }
          >
            View Response
          </button>
        ) : (
          ""
        ),
    };
  });
  console.log(row);
  const data2 = {
    columns: column,
    rows: row,
  };

  function handleDownloadExcel() {
    try {
      // Assuming you have access to the originalData
      const apiData = row;

      // Extract the header labels from the data (adjust this based on your data structure)
      // const headerLabels = Object.keys(apiData[0]);
      const headerLabels = Object.keys(apiData[0]).slice(0, -1);

      // Extract the data from the originalData
      const apiRows = apiData.map((item) => Object.values(item));

      // Combine header with data
      const dataWithHeader = [headerLabels, ...apiRows];

      // Convert the data with header to CSV format using PapaParse
      const csv = Papa.unparse(dataWithHeader);

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "PayoutReport.csv"; // Change the file name as needed
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div>
      <Row>
        <Col xs={12} lg={12} md={12}>
          <Card className="shadow border-0 mb-4 ds-header-card">
            {/* <Card.Body className="border border-3"> */}
            <Card.Body className="">
              {/* <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
              {/* <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        <li
                          className={activeButton === 1 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("customer", 1)}
                        >
                          Customer
                        </li>
                        <li
                          className={activeButton === 2 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("vendor", 2)}
                        >
                          Vendor
                        </li>
                      </ul>
                    </div> */}
              {/* </Card.Body>
                </Card> */}
              {/* ./card */}
              {/* </Col> */}
              {/* </Row> */}

              <Col className="d-flex align-items-center flex-column justify-center">
                <MDBDataTable
                  className="w-100 dataTable overflow-x-scroll"
                  data={data2}
                  footer="none"
                  striped
                  bordered
                  entries={50}
                  exportToCSV
                  responsive
                  theadColor="#333"
                  // small
                />
              </Col>
            </Card.Body>
          </Card>
          {/* ./card */}
        </Col>
        <div className=" w-100 d-flex justify-content-between">
          <div className=" ">
            <button
              className="advanced-btn btn btn-primary text-capitlize"
              onClick={handleDownloadExcel}
            >
              <FiDownload className="d-none" />
              <i class="bi bi-arrow-down-circle"></i> Download CSV
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-between w-25 gap-4 border boeder-1 p-2 ">
            <lable className="fs-4 w-50">Total Amount</lable>
            <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
              {totalAmountForSuccess}
            </span>
          </div>
        </div>
      </Row>
      <Modal
        size="lg"
        show={viewResponseModal}
        onHide={toggleViewResponseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Payout Response:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="admin-view-details">
            <Card className="shadow border-0 mb-4 ds-header-card">
              <Card.Body>
                <Row>
                  
                  <Col xs={12} xl={12} lg={12} sm={12}>
                    <ViewPayoutResponse txnId={viewResponseTxnId} referenceId={viewResponseRefId} toggleViewResponseModal={toggleViewResponseModal} />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ViewRequestPayout;
