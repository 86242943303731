import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row, Button } from "react-bootstrap";
import swal from "sweetalert";
import { SWIPELINC_API } from "../../utils/constant";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { DateTime } from "luxon";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../../utils/helper";
import { stopSpecialCharacterInput } from "../../utils/validations";
import CheckExportConst from "./UserManagement/CheckexportConst";

const AddPayoutBalance = ({ setShowLoader }) => {
  const [activeButton, setactiveButton] = useState();
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);

  const [virtualAccountType, setVirtualAccountType] = useState("");
  const [transactionType, setTransactionType] = useState("WALLET");
  const [utrNumber, setUtrNumber] = useState("");
  const [date, setDate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [accountDetails, setAccountDetails] = useState({
    account: "",
    ifsc: "",
  });

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  const { bankDeatails } = config(getServerEnvironment(getCompleteDomain()));
  const handleAmount = (e) => {
    const value = e.target.value;
    const numberPattern = /^[1-9]\d*$/; // Updated pattern to disallow 0 and negative numbers

    if (numberPattern.test(value)) {
      setAmount(value);
      setError("");
      setShowTransactionDetails(value > 100000);
      //   setShowTransactionDetails(value > 100000);
    } else {
      setAmount("");
      setError("Withdraw Amount must be a positive number.");
    }
  };
  const handleUtrNumber = (e) => {
    const value = e.target.value;

    setUtrNumber(value);
    //   setShowTransactionDetails(value > 100000);
  };
  const handleDateInputChange = (event) => {
    const newDate = event.target.value;

    // Create a DateTime object from the input value
    const inputDateTime = DateTime.fromISO(newDate, { zone: "UTC" });

    // Check if the input date is valid (i.e., it was successfully parsed)
    if (!inputDateTime.isValid) {
      swal({
        title: "Invalid Date",
        text: "Please enter a valid date.",
        icon: "warning",
      });
      return;
    }

    // Check if the input date is greater than today's date
    const today = DateTime.now().startOf("day");
    console.log(today, inputDateTime);

    if (inputDateTime.toISODate() > today.toISODate()) {
      swal({
        title: "Invalid Date",
        text: "Please select a date that is not in the future.",
        icon: "warning",
      });
    } else {
      setDate(inputDateTime.toISODate()); // Assuming you want to store the date as a string (ISO format)
    }
  };
  const handleTransactionTypeChange = async (type) => {
    setTransactionType(type);

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/Util/getAccountDetail`,
        { accountType: type, mid },
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        setAccountDetails(response.data.data);
        setShowLoader(false);
      } else {
        setAccountDetails({
          account: "",
          ifsc: "",
        });
        setShowLoader(false);
      }
    } catch (error) {
      console.error("Error fetching account details:", error);
      setAccountDetails({
        account: "",
        ifsc: "",
      });
      setShowLoader(false);
    }
  };

  const HandleSubmit = async () => {
    const DataForSubmit = {
      amount: amount,
      utr: utrNumber,
      transactionDate: date,
      mid: mid,
      transferMode: transactionType,
    };
    console.log(DataForSubmit);
    // alert("kjhkjhkjhk----------")
    // Calculate the grand total of selected rows' amounts
    if (!utrNumber.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }
    if (!date.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }
    if (!transactionType.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }
    if (!amount.length) {
      swal({
        title: "Alert",
        text: "Please fill all the details.",
        icon: "error",
      });
      return;
    }

    if (isSubmitting) {
      // Prevent multiple submissions while one is in progress
      return;
    }

    setIsSubmitting(true);

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${SWIPELINC_API}MerchantPanel/merchant/wallet/save`,
        DataForSubmit,
        {
          headers: {
            Authentication: `Bearer ${token}`,
          },
        }
      );

      if (response.data.statusCode === 309) {
        swal({
          title: "Alert",
          text: response.data.message,
          icon: "error",
        });
        setShowLoader(false);

        return;
      }

      if (response.data.statusCode === 200) {
        swal({
          title: "Success",
          text: "Your Payout request has been submitted successfully.",
          icon: "success",
        });
        setDate("");
        setUtrNumber("");
        setAmount("");
        setTransactionType("");
        console.log("Submitted Successfully");
        setShowLoader(false);
      } else {
        swal({
          title: "Failed",
          text: "Something went Wrong.",
          icon: "error",
        });
        setShowLoader(false);
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: "Something Went Wrong.",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false); // Reset the submission status to false
    }
  };

  const {
    AddWalletBalancePrivilages,
    hasPrivilagesIMPS,
    hasPrivilagesUPI,
    hasPrivilagesWallet,
  } = CheckExportConst();

  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="w-50   d-flex flex-column ">
                      {/* <div className="filter-field-area">
                        <div className="form-group mb-3 d-flex flex-column align-items-start ml-5 gap-3">
                          <div className="merchant-tab-btn mb-4">
                            <ul className="report-link nav-report-btn">
                              <li
                                className={activeButton === 1 ? "active" : ""}
                                onClick={() => handleToolsButtonClick(1, "upi")}
                              >
                                To UPI
                              </li>
                              <li
                                className={activeButton === 2 ? "active" : ""}
                                onClick={() =>
                                  handleToolsButtonClick(2, "imps")
                                }
                              >
                                To IMPS
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> */}
                      <div className="w-100 merchant-form-info d-flex justify-content-center mt-5">
                        <Col>
                          <div className="form-group w-75">
                            <label htmlFor="amount" className="form-label">
                              Enter Amount
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="input-field-validate">
                              <input
                                type="text"
                                value={amount}
                                onChange={handleAmount}
                                className="form-control w-100"
                              />
                            </div>
                          </div>
                        </Col>
                      </div>

                      <>
                        <Col className="d-flex justify-content-center">
                          <div className="w-100   mt-3 d-flex flex-column gap-3">
                            <label
                              htmlFor="transactionType"
                              className="form-label mb-1"
                            >
                              Balance Added To Be
                            </label>
                            <div className="w-50   d-flex justify-content-around gap-3">
                              <div className="form-group d-flex gap-3">
                                {hasPrivilagesIMPS ? (
                                  <>
                                    <input
                                      type="radio"
                                      name="transactionType"
                                      value="IMPS"
                                      checked={transactionType === "IMPS"}
                                      onChange={() =>
                                        handleTransactionTypeChange("IMPS")
                                      }
                                    />
                                    <span
                                      className={`${
                                        transactionType === "IMPS"
                                          ? "fw-bold"
                                          : ""
                                      }`}
                                    >
                                      IMPS
                                    </span>
                                  </>
                                ) : null}
                              </div>
                              <div className="form-group d-flex gap-3">
                                {hasPrivilagesUPI ? (
                                  <>
                                    <input
                                      type="radio"
                                      name="transactionType"
                                      value="IMPS"
                                      checked={transactionType === "UPI"}
                                      onChange={() =>
                                        handleTransactionTypeChange("UPI")
                                      }
                                    />
                                    <span
                                      className={`${
                                        transactionType === "UPI"
                                          ? "fw-bold"
                                          : ""
                                      }`}
                                    >
                                      UPI
                                    </span>
                                  </>
                                ) : null}
                              </div>

                              <div className="form-group d-flex gap-3">
                                {hasPrivilagesWallet ? (
                                  <>
                                    <input
                                      type="radio"
                                      name="transactionType"
                                      value="IMPS"
                                      checked={transactionType === "WALLET"}
                                      onChange={() =>
                                        handleTransactionTypeChange("WALLET")
                                      }
                                    />{" "}
                                    <span
                                      className={`${
                                        transactionType === "WALLET"
                                          ? "fw-bold"
                                          : ""
                                      }`}
                                    >
                                      WALLET
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </Col>

                        <div className="py-4">
                          <div className="merchant-form-info d-flex justify-content-center mt-4">
                            <Col>
                              <div className="form-group w-75">
                                <label htmlFor="amount" className="form-label">
                                  UTR Number
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="input-field-validate">
                                  <input
                                    type="text"
                                    value={utrNumber}
                                    onChange={handleUtrNumber}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                          <div className="merchant-form-info d-flex justify-content-center mt-4">
                            <Col>
                              <div className="form-group w-75">
                                <label htmlFor="amount" className="form-label">
                                  Transaction Date
                                  <sup className="text-danger">*</sup>
                                </label>
                                <div className="input-field-validate">
                                  <input
                                    type="date"
                                    value={date}
                                    onChange={handleDateInputChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </>

                      <Button className="w-25 ml-5" onClick={HandleSubmit}>
                        Submit
                      </Button>
                    </div>

                    <div className="w-50">
                      <div className="d-flex justify-content-center mt-4">
                        <div className="w-100  border border-1 py-3 pt-4 rounded-4 shadow-lg">
                          <div className="merchant-form-info d-flex justify-content-center px-5">
                            <Col>
                              {transactionType !== "UPI" &&
                                transactionType !== "IMPS" && (
                                  <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                                    <label
                                      htmlFor="amount"
                                      className="form-label w-25"
                                    >
                                      Account Beneficiary Name
                                    </label>
                                    <div className="input-field-validate w-75">
                                      <input
                                        type="text"
                                        value={bankDeatails.BName}
                                        readOnly
                                        className="form-control w-100"
                                      />
                                    </div>
                                  </div>
                                )}
                            </Col>
                          </div>
                          <div className="merchant-form-info d-flex justify-content-center px-5">
                            <Col>
                              {transactionType !== "UPI" &&
                                transactionType !== "IMPS" && (
                                  <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                                    <label
                                      htmlFor="amount"
                                      className="form-label w-25"
                                    >
                                      Bank Name
                                      {/* <sup className="text-danger">*</sup> */}
                                    </label>
                                    <div className="input-field-validate w-75">
                                      <input
                                        type="text"
                                        value={bankDeatails.bankName}
                                        // value={amount}
                                        // onChange={handleAmount}
                                        readOnly
                                        className="form-control w-100"
                                      />
                                    </div>
                                  </div>
                                )}
                            </Col>
                          </div>
                          <div className="merchant-form-info d-flex justify-content-center px-5">
                            <Col>
                              <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                                <label
                                  htmlFor="amount"
                                  className="form-label w-25"
                                >
                                  Account Number
                                  {/* <sup className="text-danger">*</sup> */}
                                </label>
                                <div className="input-field-validate w-75">
                                  <input
                                    type="text"
                                    value={
                                      transactionType === "WALLET"
                                        ? bankDeatails.Account
                                        : accountDetails.account
                                    }
                                    // value={amount}
                                    // onChange={handleAmount}
                                    readOnly
                                    className="form-control w-100"
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>

                          <div className="merchant-form-info d-flex justify-content-center px-5">
                            <Col>
                              <div className="w-100   form-group d-flex gap-5 align-items-center justify-content-evenly ">
                                <label
                                  htmlFor="amount"
                                  className="form-label w-25"
                                >
                                  IFSC Code
                                </label>
                                <div className="input-field-validate w-75">
                                  <input
                                    type="text"
                                    value={
                                      transactionType === "WALLET"
                                        ? bankDeatails.ifsc
                                        : accountDetails.ifsc
                                    }
                                    readOnly
                                    className="form-control w-100"
                                  />
                                </div>
                              </div>
                            </Col>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default AddPayoutBalance;
