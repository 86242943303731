import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { config, getCompleteDomain, getServerEnvironment } from "../../../utils/helper";


const PaymentIntegrationKit = () => {
  const navigate = useNavigate();
  const {Name, panelApiDocsUrl}=config(getServerEnvironment(getCompleteDomain()))

  const goBack = () => {
    navigate(-1);
  };

  const htmlContent = ` <html>

  <head>
  <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
  <meta name=Generator content="Microsoft Word 12 (filtered)">
  <title>Payment Gateway Integration SDK Kit</title>
  <style>
  <!--
   /* Font Definitions */
   @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;}
  @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;}
  @font-face
      {font-family:"Palatino Linotype";
      panose-1:2 4 5 2 5 5 5 3 3 4;}
   /* Style Definitions */
   p.MsoNormal, li.MsoNormal, div.MsoNormal
      {margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  h1
      {mso-style-link:"Heading 1 Char";
      margin-top:1.75pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:33.0pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:14.0pt;
      font-family:"Calibri","sans-serif";}
  h2
      {mso-style-link:"Heading 2 Char";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.05pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  h3
      {mso-style-link:"Heading 3 Char";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.05pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      font-weight:normal;}
  h4
      {mso-style-link:"Heading 4 Char";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:41.05pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:10.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoHeader, li.MsoHeader, div.MsoHeader
      {mso-style-link:"Header Char";
      margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoFooter, li.MsoFooter, div.MsoFooter
      {mso-style-link:"Footer Char";
      margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
      {mso-style-link:"Body Text Char";
      margin:0cm;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:10.0pt;
      font-family:"Calibri","sans-serif";}
  p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {margin-top:9.4pt;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:43.9pt;
      margin-bottom:.0001pt;
      text-indent:-10.95pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  p.TableParagraph, li.TableParagraph, div.TableParagraph
      {mso-style-name:"Table Paragraph";
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.95pt;
      margin-bottom:.0001pt;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";}
  span.HeaderChar
      {mso-style-name:"Header Char";
      mso-style-link:Header;
      font-family:"Calibri","sans-serif";}
  span.FooterChar
      {mso-style-name:"Footer Char";
      mso-style-link:Footer;
      font-family:"Calibri","sans-serif";}
  span.Heading3Char
      {mso-style-name:"Heading 3 Char";
      mso-style-link:"Heading 3";
      font-family:"Calibri","sans-serif";}
  span.BodyTextChar
      {mso-style-name:"Body Text Char";
      mso-style-link:"Body Text";
      font-family:"Calibri","sans-serif";}
  span.Heading2Char
      {mso-style-name:"Heading 2 Char";
      mso-style-link:"Heading 2";
      font-family:"Calibri","sans-serif";
      font-weight:bold;}
  span.Heading4Char
      {mso-style-name:"Heading 4 Char";
      mso-style-link:"Heading 4";
      font-family:"Calibri","sans-serif";
      font-weight:bold;}
  span.Heading1Char
      {mso-style-name:"Heading 1 Char";
      mso-style-link:"Heading 1";
      font-family:"Calibri","sans-serif";
      font-weight:bold;}
  .MsoPapDefault
      {text-autospace:none;}
   /* Page Definitions */
   @page Section1
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section1
      {page:Section1;}
  @page Section2
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section2
      {page:Section2;}
  @page Section3
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section3
      {page:Section3;}
  @page Section4
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section4
      {page:Section4;}
  @page Section5
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section5
      {page:Section5;}
  @page Section6
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section6
      {page:Section6;}
  @page Section7
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section7
      {page:Section7;}
  @page Section8
      {size:612.0pt 792.0pt;
      margin:68.0pt 57.0pt 53.0pt 57.0pt;}
  div.Section8
      {page:Section8;}
   /* List Definitions */
   ol
      {margin-bottom:0cm;}
  ul
      {margin-bottom:0cm;}
  -->
  </style>
  
  </head>
  
  <body lang=EN-US>
  
  <div class=Section1>
  
  <p class=MsoBodyText style='margin-top:.25pt'><b><span style='font-size:10.5pt'>&nbsp;</span></b></p>
  
  <h1 align=center style='margin-top:1.75pt;margin-right:170.8pt;margin-bottom:
  0cm;margin-left:170.8pt;margin-bottom:.0001pt;text-align:center'><span
  style='font-size:16.0pt'>****<span style='letter-spacing:-.75pt'> </span></span>Version<span
  style='letter-spacing:-.35pt'> </span>History<span style='letter-spacing:-.45pt'>
  </span>****</h1>
  
  <p class=MsoBodyText><b>&nbsp;</b></p>
  
  <p class=MsoBodyText><b>&nbsp;</b></p>
  
  <p class=MsoBodyText><b>&nbsp;</b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:11.3pt;border-collapse:collapse;border:none'>
   <tr style='height:39.7pt'>
    <td width=303 valign=top style='width:227.1pt;border:solid black 1.0pt;
    background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.7pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b>&nbsp;</b></p>
    <p class=TableParagraph align=center style='margin-top:0cm;margin-right:12.4pt;
    margin-bottom:0cm;margin-left:12.55pt;margin-bottom:.0001pt;text-align:center'><b>Date</b></p>
    </td>
    <td width=224 valign=top style='width:167.65pt;border:solid black 1.0pt;
    border-left:none;background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.7pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b>&nbsp;</b></p>
    <p class=TableParagraph align=center style='margin-top:0cm;margin-right:9.85pt;
    margin-bottom:0cm;margin-left:10.15pt;margin-bottom:.0001pt;text-align:center'><b>Version</b></p>
    </td>
    <td width=921 valign=top style='width:690.9pt;border:solid black 1.0pt;
    border-left:none;background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.7pt'>
    <p class=TableParagraph style='margin-top:7.0pt;margin-right:26.6pt;
    margin-bottom:0cm;margin-left:22.05pt;margin-bottom:.0001pt;text-indent:36.35pt;
    line-height:97%'><b>Amendment Comments (relating <span style='letter-spacing:
    -2.35pt'> </span><span style='letter-spacing:-.05pt'> to</span><span
    style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.05pt'>version</span><span
    style='letter-spacing:-.35pt'> </span>being<span style='letter-spacing:-.65pt'>
    </span>introduced)</b></p>
    </td>
    <td width=405 valign=top style='width:303.6pt;border:solid black 1.0pt;
    border-left:none;background:#CCCCCC;padding:0cm 0cm 0cm 0cm;height:39.7pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b>&nbsp;</b></p>
    <p class=TableParagraph align=center style='margin-top:0cm;margin-right:29.0pt;
    margin-bottom:0cm;margin-left:33.15pt;margin-bottom:.0001pt;text-align:center'><b>Owner</b></p>
    </td>
   </tr>
   <tr style='height:29.3pt'>
    <td width=303 valign=top style='width:227.1pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:29.3pt'>
    <p class=TableParagraph align=center style='margin-top:8.0pt;margin-right:
    12.5pt;margin-bottom:0cm;margin-left:12.55pt;margin-bottom:.0001pt;
    text-align:center'><span style='color:blue'>11.09.2023</span></p>
    </td>
    <td width=224 valign=top style='width:167.65pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:29.3pt'>
    <p class=TableParagraph align=center style='margin-top:8.0pt;margin-right:
    9.65pt;margin-bottom:0cm;margin-left:10.15pt;margin-bottom:.0001pt;
    text-align:center'><span style='color:blue'>1.0.0</span></p>
    </td>
    <td width=921 valign=top style='width:690.9pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:29.3pt'>
    <p class=TableParagraph style='margin-top:8.0pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:33.8pt;margin-bottom:.0001pt'><span style='color:blue'>Payment<span
    style='letter-spacing:-.1pt'> </span>gateway<span style='letter-spacing:-.4pt'>
    </span>document<span style='letter-spacing:-.15pt'> </span>for<span
    style='letter-spacing:-.3pt'> </span>API</span></p>
    </td>
    <td width=405 valign=top style='width:303.6pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:29.3pt'>
    <p class=TableParagraph align=center style='margin-top:7.95pt;margin-right:
    29.95pt;margin-bottom:0cm;margin-left:33.15pt;margin-bottom:.0001pt;
    text-align:center'><span style='font-size:10.0pt;color:#365F91'>${Name}</span></p>
    </td>
   </tr>
  </table>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0 align=left
   style='border-collapse:collapse;border:none;margin-left:6.75pt;margin-right:
   6.75pt'>
   <tr style='height:21.05pt'>
    <td width=555 valign=top style='width:416.1pt;border:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:21.05pt'>
    <p class=TableParagraph style='margin-top:1.85pt;line-height:11.75pt'><b><span
    style='font-size:10.0pt;color:#365F91'>Method</span></b></p>
    </td>
    <td width=555 valign=top style='width:416.1pt;border:solid black 1.0pt;
    border-left:none;padding:0cm 0cm 0cm 0cm;height:21.05pt'>
    <p class=TableParagraph style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:11.75pt'><b><span
    style='font-size:10.0pt;color:#365F91'>Environment</span></b></p>
    </td>
    <td width=574 valign=top style='width:430.4pt;border:solid black 1.0pt;
    border-left:none;padding:0cm 0cm 0cm 0cm;height:21.05pt'>
    <p class=TableParagraph style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.05pt;margin-bottom:.0001pt;line-height:11.75pt'><b><span
    style='font-size:10.0pt;color:#365F91'>URL</span></b></p>
    </td>
   </tr>
   <tr style='height:45.7pt'>
    <td width=555 valign=top style='width:416.1pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:45.7pt'>
    <p class=TableParagraph style='margin-top:1.95pt'><span style='font-size:
    10.0pt;color:#365F91'>POST</span></p>
    </td>
    <td width=555 valign=top style='width:416.1pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:45.7pt'>
    <p class=TableParagraph style='margin-top:1.95pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.85pt;margin-bottom:.0001pt'><span style='font-size:10.0pt;
    color:#365F91'>Production</span></p>
    </td>
    <td width=574 valign=top style='width:430.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:45.7pt'>
    <p class=TableParagraph style='margin-top:.9pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.05pt;margin-bottom:.0001pt'><a
    href=${panelApiDocsUrl.paymentIntegrationKitUrl}><span style='color:blue;letter-spacing:
    -.05pt'>${panelApiDocsUrl.paymentIntegrationKitUrl}</span></a></p>
    </td>
   </tr>
  </table>
  
  
  <p class=MsoNormal><span style='font-size:10.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-top:2.8pt'><b><span style='font-size:13.0pt'>Note:<span
  style='letter-spacing:-.15pt'> </span></span></b><span style='font-size:13.0pt'>Send<span
  style='letter-spacing:-.1pt'> </span>post<span style='letter-spacing:-.05pt'> </span>request<span
  style='letter-spacing:-.1pt'> </span>with encrypted<span style='letter-spacing:
  -.1pt'> </span>data<span style='letter-spacing:-.2pt'> </span>using<span
  style='letter-spacing:-.1pt'> </span>given<span style='letter-spacing:-.05pt'> </span>parameter.</span></h3>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;1000000000007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:197.9pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;enckey&quot;:<span
  style='letter-spacing:.9pt'> </span>&quot;8b3a40g766d94345b9d82ad02cfb1df1&quot;,<span
  style='letter-spacing:-2.1pt'> </span>&quot;orderNo&quot;:<span
  style='letter-spacing:-.05pt'> </span>&quot;ORD23062614402635162&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-left:45.6pt'><span style='font-size:13.0pt'>&quot;amount&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;100&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;currency&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;INR&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnReqType&quot;:
  &quot;S&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:326.5pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;respUrl&quot;:<span
  style='letter-spacing:.35pt'> </span>&quot;<a href="http://www.google.com/"><span
  style='color:windowtext;text-decoration:none'>www.google.com</span></a>&quot;,<span
  style='letter-spacing:-2.1pt'> </span>&quot;emailId&quot;:<span
  style='letter-spacing:.2pt'> </span>&quot;<a href="mailto:test@gmail.com"><span
  style='color:windowtext;text-decoration:none'>test@gmail.com</span></a>&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;mobileNo&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;9090909090&quot;</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Encrypt<span
  style='letter-spacing:.05pt'> </span>entire<span style='letter-spacing:.1pt'> </span>data<span
  style='letter-spacing:.2pt'> </span>with<span style='letter-spacing:.05pt'> </span>your<span
  style='letter-spacing:.15pt'> </span>enc key<span style='letter-spacing:.05pt'>
  </span>and<span style='letter-spacing:.05pt'> </span>post<span
  style='letter-spacing:.05pt'> </span>the<span style='letter-spacing:.15pt'> </span>payload<span
  style='letter-spacing:.2pt'> </span>(encrypted<span style='letter-spacing:.05pt'>
  </span>data)<span style='letter-spacing:.15pt'> </span>and<span
  style='letter-spacing:.2pt'> </span>mid.</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:8.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Actual<span
  style='letter-spacing:-.15pt'> </span>Request:</span></b></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  background:#E8F1FD'>{</span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
  background:#E8F1FD'> </span><span style='font-size:13.0pt;background:#E8F1FD'> &quot;mid&quot;:&quot;SWIPEPROD100007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  background:#E8F1FD'> </span><span style='font-size:13.0pt;background:#E8F1FD'> &quot;payload&quot;:</span><span
  style='font-size:13.0pt;letter-spacing:.05pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt;background:#E8F1FD'>&quot;xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsy</span><span
  style='font-size:13.0pt'> <span style='background:#E8F1FD'>zoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZ</span><span
  style='letter-spacing:.05pt'> </span><span style='background:#E8F1FD'>ptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7w</span><span
  style='letter-spacing:-2.15pt'> </span><span style='letter-spacing:-.05pt;
  background:#E8F1FD'>ofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=&quot;</span></span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt;line-height:12.15pt'><span
  style='font-size:13.0pt;background:#E8F1FD'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.55pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-right:34.5pt'><b><span style='font-size:13.0pt'>Response : </span></b><span
  style='font-size:13.0pt'>Once the transaction completed successfully, process
  will be redirect to merchant provided <span style='letter-spacing:-2.35pt'> </span> return<span
  style='letter-spacing:-.1pt'> </span>URL with below<span style='letter-spacing:
  -.05pt'> </span>mentioned parameter.</span></h3>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:370.55pt;margin-bottom:
  0cm;margin-left:41.15pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;message&quot;:<span
  style='letter-spacing:.25pt'> </span>&quot;SUCCESS&quot;,<span
  style='letter-spacing:-1.9pt'> </span>&quot;statusCode&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;OK&quot;,<span style='letter-spacing:
  .05pt'> </span>&quot;response Data&quot;:<span style='letter-spacing:-.05pt'> </span>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:49.3pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;amount&quot;:<span style='letter-spacing:.15pt'>
  </span>&quot;100&quot;,</span></p>
  
  <p class=MsoNormal style='margin-left:49.3pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;orderNo&quot;:<span style='letter-spacing:.35pt'>
  </span>&quot;ORD23091114541117906&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:272.6pt;margin-bottom:
  0cm;margin-left:47.15pt;margin-bottom:.0001pt;text-indent:2.15pt'><span
  style='font-size:13.0pt'>&quot;created_on&quot;:<span style='letter-spacing:
  .2pt'> </span>&quot;Mon<span style='letter-spacing:.15pt'> </span>Sep<span
  style='letter-spacing:.1pt'> </span>11<span style='letter-spacing:.3pt'> </span>14:54:31<span
  style='letter-spacing:.1pt'> </span>IST<span style='letter-spacing:.15pt'> </span>2023&quot;,<span
  style='letter-spacing:-1.9pt'> </span>&quot;qrString&quot;:</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:9.5pt;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;upi://pay?ver=01&amp;mode=15&amp;am=100.00&amp;mam=100.00&amp;cu=INR&amp;pa=XXXXXXXXXXXXXXXXXXX&amp;pn=XXXXXXXXXXXXXXXX&amp;<span
  style='letter-spacing:.05pt'> </span>mc=8299&amp;tr=Zenex52149242658716179885&amp;tn=Pay
  to<span style='letter-spacing:.2pt'> </span>Merchant&amp;mid=ZENEX001&amp;msid=ZENEX001-<span
  style='letter-spacing:.05pt'> </span>ANUBHAV&amp;mtid=ZENEX001-001&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:314.4pt;margin-bottom:
  0cm;margin-left:49.3pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:.05pt'> </span>&quot;SWIPEPROD100007&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;txnId&quot;:<span style='letter-spacing:
  .15pt'> </span>&quot;52149242658716179885&quot;</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.15pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <h3 style='margin-top:2.8pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  0cm;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Note:<span
  style='letter-spacing:-.15pt'> </span></span></b><span style='font-size:13.0pt'>Send<span
  style='letter-spacing:-.1pt'> </span>post<span style='letter-spacing:-.05pt'> </span>request<span
  style='letter-spacing:-.1pt'> </span>with encrypted<span style='letter-spacing:
  -.1pt'> </span>data<span style='letter-spacing:-.2pt'> </span>using<span
  style='letter-spacing:-.1pt'> </span>given<span style='letter-spacing:-.05pt'> </span>parameter.</span></h3>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;1000000000007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:197.9pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;enckey&quot;:<span
  style='letter-spacing:.9pt'> </span>&quot;8b3a40g766d94345b9d82ad02cfb1df1&quot;,<span
  style='letter-spacing:-2.1pt'> </span>&quot;orderNo&quot;:<span
  style='letter-spacing:-.05pt'> </span>&quot;ORD23062614402635162&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-left:45.6pt'><span style='font-size:13.0pt'>&quot;amount&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;100&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;currency&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;INR&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnReqType&quot;:
  &quot;S&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:326.5pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;respUrl&quot;:<span
  style='letter-spacing:.35pt'> </span>&quot;<a href="http://www.google.com/"><span
  style='color:windowtext;text-decoration:none'>www.google.com</span></a>&quot;,<span
  style='letter-spacing:-2.1pt'> </span>&quot;emailId&quot;:<span
  style='letter-spacing:.2pt'> </span>&quot;<a href="mailto:test@gmail.com"><span
  style='color:windowtext;text-decoration:none'>test@gmail.com</span></a>&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;mobileNo&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;9090909090&quot;</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Encrypt<span
  style='letter-spacing:.05pt'> </span>entire<span style='letter-spacing:.1pt'> </span>data<span
  style='letter-spacing:.2pt'> </span>with<span style='letter-spacing:.05pt'> </span>your<span
  style='letter-spacing:.15pt'> </span>enc key<span style='letter-spacing:.05pt'>
  </span>and<span style='letter-spacing:.05pt'> </span>post<span
  style='letter-spacing:.05pt'> </span>the<span style='letter-spacing:.15pt'> </span>payload<span
  style='letter-spacing:.2pt'> </span>(encrypted<span style='letter-spacing:.05pt'>
  </span>data)<span style='letter-spacing:.15pt'> </span>and<span
  style='letter-spacing:.2pt'> </span>mid.</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:8.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Actual<span
  style='letter-spacing:-.15pt'> </span>Request:</span></b></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  background:#E8F1FD'>{</span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
  background:#E8F1FD'> </span><span style='font-size:13.0pt;background:#E8F1FD'> &quot;mid&quot;:&quot;SWIPEPROD100007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  background:#E8F1FD'> </span><span style='font-size:13.0pt;background:#E8F1FD'> &quot;payload&quot;:</span><span
  style='font-size:13.0pt;letter-spacing:.05pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt;background:#E8F1FD'>&quot;xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsy</span><span
  style='font-size:13.0pt'> <span style='background:#E8F1FD'>zoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZ</span><span
  style='letter-spacing:.05pt'> </span><span style='background:#E8F1FD'>ptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7w</span><span
  style='letter-spacing:-2.15pt'> </span><span style='letter-spacing:-.05pt;
  background:#E8F1FD'>ofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=&quot;</span></span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt;line-height:12.15pt'><span
  style='font-size:13.0pt;background:#E8F1FD'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.55pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-right:34.5pt'><b><span style='font-size:13.0pt'> Response : </span></b><span
  style='font-size:13.0pt'>Once the transaction completed successfully, process
  will be redirect to merchant provided <span style='letter-spacing:-2.35pt'> </span> return<span
  style='letter-spacing:-.1pt'> </span>URL with below<span style='letter-spacing:
  -.05pt'> </span>mentioned parameter.</span></h3>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:370.55pt;margin-bottom:
  0cm;margin-left:41.15pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;message&quot;:<span
  style='letter-spacing:.25pt'> </span>&quot;SUCCESS&quot;,<span
  style='letter-spacing:-1.9pt'> </span>&quot;statusCode&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;OK&quot;,<span style='letter-spacing:
  .05pt'> </span>&quot;response Data&quot;:<span style='letter-spacing:-.05pt'> </span>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:49.3pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;amount&quot;:<span style='letter-spacing:.15pt'>
  </span>&quot;100&quot;,</span></p>
  
  <p class=MsoNormal style='margin-left:49.3pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;orderNo&quot;:<span style='letter-spacing:.35pt'>
  </span>&quot;ORD23091114541117906&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:272.6pt;margin-bottom:
  0cm;margin-left:47.15pt;margin-bottom:.0001pt;text-indent:2.15pt'><span
  style='font-size:13.0pt'>&quot;created_on&quot;:<span style='letter-spacing:
  .2pt'> </span>&quot;Mon<span style='letter-spacing:.15pt'> </span>Sep<span
  style='letter-spacing:.1pt'> </span>11<span style='letter-spacing:.3pt'> </span>14:54:31<span
  style='letter-spacing:.1pt'> </span>IST<span style='letter-spacing:.15pt'> </span>2023&quot;,<span
  style='letter-spacing:-1.9pt'> </span>&quot;qrString&quot;:</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:9.5pt;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;upi://pay?ver=01&amp;mode=15&amp;am=100.00&amp;mam=100.00&amp;cu=INR&amp;pa=XXXXXXXXXXXXXXXXXXX&amp;pn=XXXXXXXXXXXXXXXX&amp;<span
  style='letter-spacing:.05pt'> </span>mc=8299&amp;tr=Zenex52149242658716179885&amp;tn=Pay
  to<span style='letter-spacing:.2pt'> </span>Merchant&amp;mid=ZENEX001&amp;msid=ZENEX001-<span
  style='letter-spacing:.05pt'> </span>ANUBHAV&amp;mtid=ZENEX001-001&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:314.4pt;margin-bottom:
  0cm;margin-left:49.3pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:.05pt'> </span>&quot;SWIPEPROD100007&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;txnId&quot;:<span style='letter-spacing:
  .15pt'> </span>&quot;52149242658716179885&quot;</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.15pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='line-height:10.95pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-top:2.8pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  0cm;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Note:<span
  style='letter-spacing:-.15pt'> </span></span></b><span style='font-size:13.0pt'>Send<span
  style='letter-spacing:-.1pt'> </span>post<span style='letter-spacing:-.05pt'> </span>request<span
  style='letter-spacing:-.1pt'> </span>with encrypted<span style='letter-spacing:
  -.1pt'> </span>data<span style='letter-spacing:-.2pt'> </span>using<span
  style='letter-spacing:-.1pt'> </span>given<span style='letter-spacing:-.05pt'> </span>parameter.</span></h3>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;1000000000007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:197.9pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;enckey&quot;:<span
  style='letter-spacing:.9pt'> </span>&quot;8b3a40g766d94345b9d82ad02cfb1df1&quot;,<span
  style='letter-spacing:-2.1pt'> </span>&quot;orderNo&quot;:<span
  style='letter-spacing:-.05pt'> </span>&quot;ORD23062614402635162&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-left:45.6pt'><span style='font-size:13.0pt'>&quot;amount&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;100&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;currency&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;INR&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnReqType&quot;:
  &quot;S&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:326.5pt;margin-bottom:
  0cm;margin-left:45.6pt;margin-bottom:.0001pt;line-height:115%'><span
  style='font-size:13.0pt;line-height:115%'>&quot;respUrl&quot;:<span
  style='letter-spacing:.35pt'> </span>&quot;<a href="http://www.google.com/"><span
  style='color:windowtext;text-decoration:none'>www.google.com</span></a>&quot;,<span
  style='letter-spacing:-2.1pt'> </span>&quot;emailId&quot;:<span
  style='letter-spacing:.2pt'> </span>&quot;<a href="mailto:test@gmail.com"><span
  style='color:windowtext;text-decoration:none'>test@gmail.com</span></a>&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;mobileNo&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;9090909090&quot;</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:1.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Encrypt<span
  style='letter-spacing:.05pt'> </span>entire<span style='letter-spacing:.1pt'> </span>data<span
  style='letter-spacing:.2pt'> </span>with<span style='letter-spacing:.05pt'> </span>your<span
  style='letter-spacing:.15pt'> </span>enc key<span style='letter-spacing:.05pt'>
  </span>and<span style='letter-spacing:.05pt'> </span>post<span
  style='letter-spacing:.05pt'> </span>the<span style='letter-spacing:.15pt'> </span>payload<span
  style='letter-spacing:.2pt'> </span>(encrypted<span style='letter-spacing:.05pt'>
  </span>data)<span style='letter-spacing:.15pt'> </span>and<span
  style='letter-spacing:.2pt'> </span>mid.</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:8.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Actual<span
  style='letter-spacing:-.15pt'> </span>Request:</span></b></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  background:#E8F1FD'>{</span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
  background:#E8F1FD'> </span><span style='font-size:13.0pt;background:#E8F1FD'> &quot;mid&quot;:&quot;SWIPEPROD100007&quot;,</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  background:#E8F1FD'> </span><span style='font-size:13.0pt;background:#E8F1FD'> &quot;payload&quot;:</span><span
  style='font-size:13.0pt;letter-spacing:.05pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt;background:#E8F1FD'>&quot;xjnv8ROPm7uN29w6SDhX85asQtqStDicKvkLda1ZoEemsP6Pu8iKPfsAllRuFIqu7Ez60WYiusm1WzbBjkA/c6mwAsy</span><span
  style='font-size:13.0pt'> <span style='background:#E8F1FD'>zoani4Q7TTsVRmII/QmRHXn07uBcWK1GxqT5EA++8Bl2b6J9fTW4H+wZN2wnkWAU4sTBOanjnCJn/uXz08BJBwUZ</span><span
  style='letter-spacing:.05pt'> </span><span style='background:#E8F1FD'>ptyA+vnq2O/rHkoqyFbUi2izOg4lmtbS56zw3xskihZTYsUSz5y4v1ZYyMkY/ZXHTLpv6eqtrjurep9tu4zPZZXlOzbYq7w</span><span
  style='letter-spacing:-2.15pt'> </span><span style='letter-spacing:-.05pt;
  background:#E8F1FD'>ofXnyn1WXocN/CBXx1X5N1cCQGB8ZvC3/prJr9IeJRKkMh2GqUHbs1GdJQvoLUrl0Di4hcFMHiFJhoG57h/N0cH78=&quot;</span></span></p>
  
  <p class=MsoBodyText style='margin-left:33.0pt;line-height:12.15pt'><span
  style='font-size:13.0pt;background:#E8F1FD'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.55pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-right:34.5pt'><b><span style='font-size:13.0pt'>Response : </span></b><span
  style='font-size:13.0pt'>Once the transaction completed successfully, process
  will be redirect to merchant provided <span style='letter-spacing:-2.35pt'> </span> return<span
  style='letter-spacing:-.1pt'> </span>URL with below<span style='letter-spacing:
  -.05pt'> </span>mentioned parameter.</span></h3>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:370.55pt;margin-bottom:
  0cm;margin-left:41.15pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;message&quot;:<span
  style='letter-spacing:.25pt'> </span>&quot;SUCCESS&quot;,<span
  style='letter-spacing:-1.9pt'> </span>&quot;statusCode&quot;:<span
  style='letter-spacing:.1pt'> </span>&quot;OK&quot;,<span style='letter-spacing:
  .05pt'> </span>&quot;response Data&quot;:<span style='letter-spacing:-.05pt'> </span>{</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:49.3pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;amount&quot;:<span style='letter-spacing:.15pt'>
  </span>&quot;100&quot;,</span></p>
  
  <p class=MsoNormal style='margin-left:49.3pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>&quot;orderNo&quot;:<span style='letter-spacing:.35pt'>
  </span>&quot;ORD23091114541117906&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:272.6pt;margin-bottom:
  0cm;margin-left:47.15pt;margin-bottom:.0001pt;text-indent:2.15pt'><span
  style='font-size:13.0pt'>&quot;created_on&quot;:<span style='letter-spacing:
  .2pt'> </span>&quot;Mon<span style='letter-spacing:.15pt'> </span>Sep<span
  style='letter-spacing:.1pt'> </span>11<span style='letter-spacing:.3pt'> </span>14:54:31<span
  style='letter-spacing:.1pt'> </span>IST<span style='letter-spacing:.15pt'> </span>2023&quot;,<span
  style='letter-spacing:-1.9pt'> </span>&quot;qrString&quot;:</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:9.5pt;margin-bottom:0cm;
  margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;upi://pay?ver=01&amp;mode=15&amp;am=100.00&amp;mam=100.00&amp;cu=INR&amp;pa=XXXXXXXXXXXXXXXXXXX&amp;pn=XXXXXXXXXXXXXXXX&amp;<span
  style='letter-spacing:.05pt'> </span>mc=8299&amp;tr=Zenex52149242658716179885&amp;tn=Pay
  to<span style='letter-spacing:.2pt'> </span>Merchant&amp;mid=ZENEX001&amp;msid=ZENEX001-<span
  style='letter-spacing:.05pt'> </span>ANUBHAV&amp;mtid=ZENEX001-001&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:314.4pt;margin-bottom:
  0cm;margin-left:49.3pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;mid&quot;:<span
  style='letter-spacing:.05pt'> </span>&quot;SWIPEPROD100007&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;txnId&quot;:<span style='letter-spacing:
  .15pt'> </span>&quot;52149242658716179885&quot;</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.15pt;margin-bottom:.0001pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoNormal style='margin-left:33.0pt;line-height:10.95pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoNormal style='line-height:10.95pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='line-height:10.95pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h2 style='margin-top:2.45pt'><span style='font-size:13.0pt'>Callback:</span></h2>
  
  <p class=MsoNormal style='margin-top:6.7pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt;line-height:150%'><span
  style='font-size:13.0pt;line-height:150%'>Merchant<span style='letter-spacing:
  .15pt'> </span>needs<span style='letter-spacing:.05pt'> </span>to<span
  style='letter-spacing:.25pt'> </span>provide callback<span style='letter-spacing:
  .1pt'> </span>endpoint<span style='letter-spacing:.1pt'> </span>where<span
  style='letter-spacing:.1pt'> </span>payment<span style='letter-spacing:.1pt'> </span>engine<span
  style='letter-spacing:.15pt'> </span>will<span style='letter-spacing:.1pt'> </span>provide<span
  style='letter-spacing:.05pt'> </span>callback<span style='letter-spacing:.1pt'>
  </span>response <span style='letter-spacing:.1pt'> </span>with<span
  style='letter-spacing:.15pt'> </span>below<span style='letter-spacing:.25pt'> </span>mention <span
  style='letter-spacing:-1.9pt'> </span> parameter.</span></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:323.5pt;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;line-height:150%'><span
  style='font-size:13.0pt;line-height:150%'>Callback<span style='letter-spacing:
  .05pt'> </span>URL:<span style='letter-spacing:.15pt'> </span>provided <span
  style='letter-spacing:.15pt'> </span>by<span style='letter-spacing:.05pt'> </span> merchant <span
  style='letter-spacing:-1.9pt'> </span> Data<span style='letter-spacing:.05pt'> </span>sent
  in<span style='letter-spacing:-.05pt'> </span>callback:</span></p>
  
  <p class=MsoNormal style='margin-left:41.05pt;line-height:10.8pt'><span
  style='font-size:13.0pt'>{</span></p>
  
  <p class=MsoNormal style='margin-top:5.5pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;<span
  style='color:#2A00FF;background:#E8F1FD'>mid</span>&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;1000000000007&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:6.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;text-indent:4.05pt;line-height:
  147%'><span style='font-size:13.0pt;line-height:147%'>&quot;<span
  style='color:#2A00FF;background:#E8F1FD'>data</span>&quot;:<span
  style='letter-spacing:.05pt'> </span>&quot;data<span style='letter-spacing:
  .05pt'> </span>will<span style='letter-spacing:.15pt'> </span>be encrypted<span
  style='letter-spacing:.15pt'> </span>string,<span style='letter-spacing:.15pt'>
  </span>merchant<span style='letter-spacing:.1pt'> </span>will<span
  style='letter-spacing:.1pt'> </span>need<span style='letter-spacing:.15pt'> </span>to<span
  style='letter-spacing:.05pt'> </span>decrypt<span style='letter-spacing:.05pt'>
  </span>the<span style='letter-spacing:.15pt'> </span>data<span
  style='letter-spacing:.15pt'> </span>using<span style='letter-spacing:.05pt'> </span>above
  mentioned <span style='letter-spacing:.15pt'> </span>encryption <span
  style='letter-spacing:-1.9pt'> </span> alogrithm&quot;,</span></p>
  
  <p class=MsoNormal style='margin-top:.5pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:45.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;<span
  style='color:#2A00FF;background:#E8F1FD'>signature</span>&quot;:<span
  style='letter-spacing:.15pt'> </span>&quot;<span style='letter-spacing:.05pt'> </span>XXXXXXXXXXXXXXX
  &quot;</span></p>
  
  <p class=MsoNormal style='margin-top:5.9pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-top:.05pt;margin-right:10.85pt;margin-bottom:0cm;margin-left:
  5.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>Signature/Checksum:
  </span></b><span style='font-size:13.0pt'>Signature is checksum value that is
  generate to check the data integrity. For generate <span style='letter-spacing:
  -2.35pt'> </span>checksum/signature please<span style='letter-spacing:-.1pt'> </span>use
  response <span style='letter-spacing:-.1pt'> </span> field<span style='letter-spacing:
  -.05pt'> </span>at<span style='letter-spacing:-.1pt'> </span>given sequence-</span></h3>
  
  <p class=MsoNormal style='margin-left:5.05pt'><span style='font-size:13.0pt'>Mid<span
  style='letter-spacing:-.1pt'> </span>+<span style='letter-spacing:-.15pt'> </span>txnId<span
  style='letter-spacing:-.1pt'> </span>+<span style='letter-spacing:-.1pt'> </span>orderNo
  +<span style='letter-spacing:-.15pt'> </span>txnStatus</span></p>
  
  <p class=MsoBodyText style='margin-top:.5pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3 style='margin-right:5.1pt'><span style='font-size:13.0pt'>Kindly decrypt
  the data and recalculate the signature using above mentioned 4 parameter and
  compare both<span style='letter-spacing:-2.35pt'> </span>the signature.</span></h3>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-left:5.05pt'><span style='font-size:13.0pt'>Signature/checksum<span
  style='letter-spacing:-.05pt'> </span>generation<span style='letter-spacing:
  -.15pt'> </span>algorithm:</span></p>
  
  <p class=MsoBodyText style='margin-top:.25pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoNormal style='margin-top:2.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Prepare<span
  style='letter-spacing:-.2pt'> </span>data<span style='letter-spacing:-.1pt'> </span>and<span
  style='letter-spacing:-.3pt'> </span>call<span style='letter-spacing:-.05pt'> </span><span
  style='background:lightgrey'>getChecksumCRC32 method<span style='letter-spacing:
  -.1pt'> </span>to<span style='letter-spacing:-.05pt'> </span>generate<span
  style='letter-spacing:-.15pt'> </span>the<span style='letter-spacing:-.15pt'> </span>checksum:</span></span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:104.0pt;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>String
  <span style='color:#6A3D3D'>checksumStr </span>= <span style='color:#6A3D3D'>transaction</span>.getMid()+
  <span style='color:#6A3D3D'>transaction</span>.getTxnId() + <span
  style='color:#6A3D3D'>transaction</span>.getOrderNo() +<span style='letter-spacing:
  -2.15pt'> </span><span style='color:#6A3D3D'>transaction</span>.getTxnStatus();</span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:159.85pt;margin-bottom:
  0cm;margin-left:5.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>InputStream
  <span style='color:#6A3D3D'>targetStream </span>= <b><span style='color:#7E0054'>new
  </span></b>ByteArrayInputStream(<span style='color:#6A3D3D'>checksumStr</span>.getBytes());<span
  style='letter-spacing:-2.2pt'> </span><span style='color:#6A3D3D'>checkSum </span>=<span
  style='letter-spacing:-.05pt'> </span><i><span style='background:lightgrey'>getChecksumCRC32</span></i>(<span
  style='color:#6A3D3D'>targetStream</span>, <span style='color:#6A3D3D'>checksumStr</span>.length());</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.4pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h3><span style='font-size:13.0pt'>Algorithm:</span></h3>
  
  <p class=MsoNormal style='margin-top:.1pt;margin-right:53.3pt;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;text-indent:-36.0pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>public static long </span></b><span
  style='font-size:13.0pt;background:lightgrey'>getChecksumCRC32</span><span
  style='font-size:13.0pt'>(InputStream <span style='color:#6A3D3D'>stream</span>,
  <b><span style='color:#7E0054'>int </span></b><span style='color:#6A3D3D'>bufferSize</span>)
  <b><span style='color:#7E0054'>throws </span></b>IOException {<span
  style='letter-spacing:.05pt'> </span>CheckedInputStream<span style='letter-spacing:
  -.3pt'> </span><span style='color:#6A3D3D'>checkedInputStream<span
  style='letter-spacing:-.2pt'> </span></span>=<span style='letter-spacing:-.3pt'>
  </span><b><span style='color:#7E0054'>new<span style='letter-spacing:-.2pt'> </span></span></b>CheckedInputStream(<span
  style='color:#6A3D3D'>stream</span>,<span style='letter-spacing:-.25pt'> </span><b><span
  style='color:#7E0054'>new<span style='letter-spacing:-.2pt'> </span></span></b>CRC32());<span
  style='letter-spacing:-2.1pt'> </span><b><span style='color:#7E0054'>byte</span></b>[]<span
  style='letter-spacing:-.1pt'> </span><span style='color:#6A3D3D'>buffer<span
  style='letter-spacing:.05pt'> </span></span>=<span style='letter-spacing:-.05pt'>
  </span><b><span style='color:#7E0054'>new<span style='letter-spacing:.05pt'> </span>byte</span></b>[<span
  style='color:#6A3D3D'>bufferSize</span>];</span></p>
  
  <p class=MsoBodyText style='margin-left:41.05pt;line-height:12.2pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>while<span style='letter-spacing:-.1pt'>
  </span></span></b><span style='font-size:13.0pt'>(<span style='color:#6A3D3D'>checkedInputStream</span>.read(<span
  style='color:#6A3D3D'>buffer</span>,<span style='letter-spacing:-.15pt'> </span>0,<span
  style='letter-spacing:-.1pt'> </span><span style='color:#6A3D3D'>buffer</span>.<span
  style='color:#0000C0'>length</span>)<span style='letter-spacing:-.2pt'> </span>&gt;=<span
  style='letter-spacing:-.2pt'> </span>0)<span style='letter-spacing:-.15pt'> </span>{</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><b><i><span style='font-size:
  13.0pt;color:#0000C0'>LOGGER</span></i></b><span style='font-size:13.0pt'>.info(<span
  style='color:#2A00FF'>&quot;Check<span style='letter-spacing:-.2pt'> </span>sum<span
  style='letter-spacing:1.75pt'> </span>:&quot;<span style='letter-spacing:-.25pt'>
  </span></span>+<span style='letter-spacing:-.15pt'> </span><span
  style='color:#6A3D3D'>checkedInputStream</span>.getChecksum().getValue());</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>return<span style='letter-spacing:-.55pt'> </span></span></b><span
  style='font-size:13.0pt;color:#6A3D3D'>checkedInputStream</span><span
  style='font-size:13.0pt'>.getChecksum().getValue();</span></p>
  
  <p class=MsoBodyText style='margin-left:7.3pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.55pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <h2 style='margin-top:.05pt'><span style='font-size:13.0pt'>Encryption<span
  style='letter-spacing:-.2pt'> </span>algorithm</span></h2>
  
  <p class=MsoBodyText style='margin-left:5.05pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>public<span style='letter-spacing:-.15pt'> </span></span></b><span
  style='font-size:13.0pt'>String<span style='letter-spacing:-.2pt'> </span>encryptData(String<span
  style='letter-spacing:-.1pt'> </span><span style='color:#6A3D3D'>plaintext</span>,<span
  style='letter-spacing:-.15pt'> </span>String<span style='letter-spacing:-.15pt'>
  </span><span style='color:#6A3D3D'>encryptKey</span>)<span style='letter-spacing:
  -.25pt'> </span>{</span></p>
  
  <h4><span style='font-size:13.0pt;color:#7E0054'>try<span style='letter-spacing:
  -.1pt'> </span></span><span style='font-size:13.0pt;font-weight:normal'>{</span></h4>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:52.25pt;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>SecretKeySpec
  <span style='color:#6A3D3D'>secretKeySpec </span>= <b><span style='color:#7E0054'>new
  </span></b>SecretKeySpec(<span style='color:#6A3D3D'>encryptKey</span>.getBytes(<span
  style='color:#2A00FF'>&quot;UTF-8&quot;</span>), <span style='color:#2A00FF'>&quot;AES&quot;</span>);<span
  style='letter-spacing:-2.15pt'> </span>Cipher <span style='color:#6A3D3D'>cipher
  </span>=<span style='letter-spacing:.05pt'> </span>Cipher.<i>getInstance</i>(<span
  style='color:#2A00FF'>&quot;AES/CBC/PKCS5Padding&quot;</span>);<span
  style='letter-spacing:.05pt'> </span><span style='color:#6A3D3D'>cipher</span>.init(Cipher.<b><i><span
  style='color:#0000C0'>ENCRYPT_MODE</span></i></b>, <span style='color:#6A3D3D'>secretKeySpec</span>);</span></p>
  
  <p class=MsoBodyText style='margin-left:77.05pt;line-height:12.2pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>byte</span></b><span style='font-size:
  13.0pt'>[]<span style='letter-spacing:-.4pt'> </span><span style='color:#6A3D3D'>ivBytes<span
  style='letter-spacing:-.2pt'> </span></span>=<span style='letter-spacing:-.35pt'>
  </span><span style='color:#6A3D3D'>cipher</span>.getParameters().getParameterSpec(IvParameterSpec.<b><span
  style='color:#7E0054'>class</span></b>).getIV();</span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt;line-height:12.15pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>byte</span></b><span style='font-size:
  13.0pt'>[]<span style='letter-spacing:-.4pt'> </span><span style='color:#6A3D3D'>encryptedData<span
  style='letter-spacing:-.3pt'> </span></span>=<span style='letter-spacing:-.4pt'>
  </span><span style='color:#6A3D3D'>cipher</span>.doFinal(<span
  style='color:#6A3D3D'>plaintext</span>.getBytes(<span style='color:#2A00FF'>&quot;UTF-8&quot;</span>));</span></p>
  
  <p class=MsoNormal style='margin-left:41.05pt;text-indent:36.0pt;line-height:
  10.95pt'><b><span style='font-size:13.0pt;color:#7E0054'>byte</span></b><span
  style='font-size:13.0pt'>[]<span style='letter-spacing:-.3pt'> </span><span
  style='color:#6A3D3D'>combinedBytes<span style='letter-spacing:-.05pt'> </span></span>=<span
  style='letter-spacing:-.3pt'> </span><b><span style='color:#7E0054'>new<span
  style='letter-spacing:-.05pt'> </span>byte</span></b>[<span style='color:#6A3D3D'>ivBytes</span>.<span
  style='color:#0000C0'>length<span style='letter-spacing:-.15pt'> </span></span>+<span
  style='letter-spacing:-.25pt'> </span><span style='color:#6A3D3D'>encryptedData</span>.<span
  style='color:#0000C0'>length</span>];</span></p>
  
  <p class=MsoNormal style='line-height:10.95pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:2.45pt;margin-right:45.5pt;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>System.<i>arraycopy</i>(<span
  style='color:#6A3D3D'>ivBytes</span>,<span style='letter-spacing:-.05pt'> </span>0,<span
  style='letter-spacing:.05pt'> </span><span style='color:#6A3D3D'>combinedBytes</span>,<span
  style='letter-spacing:-.05pt'> </span>0,<span style='letter-spacing:.1pt'> </span><span
  style='color:#6A3D3D'>ivBytes</span>.<span style='color:#0000C0'>length</span>);<span
  style='letter-spacing:.05pt'> </span>System.<i>arraycopy</i>(<span
  style='color:#6A3D3D'>encryptedData</span>, 0, <span style='color:#6A3D3D'>combinedBytes</span>,
  <span style='color:#6A3D3D'>ivBytes</span>.<span style='color:#0000C0'>length</span>,
  <span style='color:#6A3D3D'>encryptedData</span>.<span style='color:#0000C0'>length</span>);<span
  style='letter-spacing:-2.15pt'> </span><b><span style='color:#7E0054'>return<span
  style='letter-spacing:-.05pt'> </span></span></b>Base64.<i>getEncoder</i>().encodeToString(<span
  style='color:#6A3D3D'>combinedBytes</span>);</span></p>
  
  <p class=MsoNormal style='margin-top:.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}<span style='letter-spacing:-.15pt'> </span><b><span
  style='color:#7E0054'>catch<span style='letter-spacing:-.05pt'> </span></span></b>(Exception
  <span style='color:#6A3D3D'>e</span>)<span style='letter-spacing:-.15pt'> </span>{</span></p>
  
  <p class=MsoBodyText style='margin-left:77.05pt;line-height:12.15pt'><b><i><span
  style='font-size:13.0pt;color:#0000C0'>LOGGER</span></i></b><span
  style='font-size:13.0pt'>.error(<span style='color:#2A00FF'>&quot;Exception<span
  style='letter-spacing:-.1pt'> </span>in encrypt()<span style='letter-spacing:
  -.15pt'> </span>:<span style='letter-spacing:-.1pt'> </span>encryptMData-<span
  style='letter-spacing:-.15pt'> </span>&quot;<span style='letter-spacing:-.1pt'>
  </span></span>+<span style='letter-spacing:-.05pt'> </span><span
  style='color:#6A3D3D'>encryptKey<span style='letter-spacing:.05pt'> </span></span>+<span
  style='letter-spacing:-.15pt'> </span><span style='color:#2A00FF'>&quot;<span
  style='letter-spacing:-.1pt'> </span>:<span style='letter-spacing:-.15pt'> </span>Message-<span
  style='letter-spacing:-.1pt'> </span>&quot;<span style='letter-spacing:-.15pt'>
  </span></span>+</span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:auto'>
  </span>
  
  <div class=Section2>
  
  <p class=MsoBodyText style='margin-left:5.05pt'><span style='font-size:13.0pt;
  letter-spacing:-.05pt'>e.getMessage());</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText align=center style='margin-left:12.15pt;text-align:center'><span
  style='font-size:13.0pt'>}</span></p>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all>
  </span>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-left:1.95pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <h4 style='margin-left:1.95pt;line-height:12.15pt'><span style='font-size:13.0pt;
  color:#7E0054'>return<span style='letter-spacing:-.1pt'> </span>null</span><span
  style='font-size:13.0pt;font-weight:normal'>;</span></h4>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:auto'>
  </span>
  
  <div class=Section3>
  
  <p class=MsoBodyText style='margin-top:.15pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:2.95pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.05pt;margin-bottom:.0001pt;line-height:12.15pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>public<span style='letter-spacing:-.2pt'>
  </span></span></b><span style='font-size:13.0pt'>String<span style='letter-spacing:
  -.25pt'> </span>decryptData(String<span style='letter-spacing:-.1pt'> </span><span
  style='color:#6A3D3D'>encryptedString</span>,<span style='letter-spacing:-.25pt'>
  </span>String<span style='letter-spacing:-.15pt'> </span><span
  style='color:#6A3D3D'>encryptKey</span>)<span style='letter-spacing:-.25pt'> </span>{</span></p>
  
  <h4 style='margin-left:77.05pt;line-height:12.15pt'><span style='font-size:
  13.0pt;color:#7E0054'>try<span style='letter-spacing:-.1pt'> </span></span><span
  style='font-size:13.0pt;font-weight:normal'>{</span></h4>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:113.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>byte</span></b><span style='font-size:13.0pt'>[]<span
  style='letter-spacing:-.4pt'> </span><span style='color:#6A3D3D'>encryptedData<span
  style='letter-spacing:-.2pt'> </span></span>=<span style='letter-spacing:-.4pt'>
  </span>Base64.<i>getDecoder</i>().decode(<span style='color:#6A3D3D'>encryptedString</span>);</span></p>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:159.85pt;margin-bottom:
  0cm;margin-left:113.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>byte</span></b><span style='font-size:13.0pt'>[] <span
  style='color:#6A3D3D'>ivBytes </span>= <b><span style='color:#7E0054'>new byte</span></b>[16];<span
  style='letter-spacing:.05pt'> </span>System.<i>arraycopy</i>(<span
  style='color:#6A3D3D'>encryptedData</span>,<span style='letter-spacing:-.2pt'> </span>0,<span
  style='letter-spacing:-.15pt'> </span><span style='color:#6A3D3D'>ivBytes</span>,<span
  style='letter-spacing:-.2pt'> </span>0,<span style='letter-spacing:-.2pt'> </span>16);</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:16.25pt;margin-bottom:
  0cm;margin-left:113.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt;
  color:#7E0054'>byte</span></b><span style='font-size:13.0pt'>[]<span
  style='letter-spacing:-.1pt'> </span><span style='color:#6A3D3D'>encryptedBytes<span
  style='letter-spacing:.15pt'> </span></span>=<span style='letter-spacing:-.1pt'>
  </span><b><span style='color:#7E0054'>new<span style='letter-spacing:.05pt'> </span>byte</span></b>[<span
  style='color:#6A3D3D'>encryptedData</span>.<span style='color:#0000C0'>length<span
  style='letter-spacing:.15pt'> </span></span>-<span style='letter-spacing:-.05pt'>
  </span>16];<span style='letter-spacing:.05pt'> </span>System.<i>arraycopy</i>(<span
  style='color:#6A3D3D'>encryptedData</span>, 16, <span style='color:#6A3D3D'>encryptedBytes</span>,
  0, <span style='color:#6A3D3D'>encryptedData</span>.<span style='color:#0000C0'>length
  </span>- 16);<span style='letter-spacing:.05pt'> </span>SecretKeySpec <span
  style='color:#6A3D3D'>secretKeySpec </span>= <b><span style='color:#7E0054'>new
  </span></b>SecretKeySpec(<span style='color:#6A3D3D'>encryptKey</span>.getBytes(<span
  style='color:#2A00FF'>&quot;UTF-8&quot;</span>), <span style='color:#2A00FF'>&quot;AES&quot;</span>);<span
  style='letter-spacing:-2.15pt'> </span>IvParameterSpec <span style='color:#6A3D3D'>ivParameterSpec<span
  style='letter-spacing:.05pt'> </span></span>=<span style='letter-spacing:-.1pt'>
  </span><b><span style='color:#7E0054'>new<span style='letter-spacing:.05pt'> </span></span></b>IvParameterSpec(<span
  style='color:#6A3D3D'>ivBytes</span>);</span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:auto'>
  </span>
  
  <div class=Section4>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:13.0pt'>&nbsp;</span></p>
  
  <p class=MsoBodyText style='margin-left:5.05pt'><span style='font-size:13.0pt;
  color:#6A3D3D;letter-spacing:-.05pt'>e</span><span style='font-size:13.0pt;
  letter-spacing:-.05pt'>.getMessage());</span></p>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all>
  </span>
  
  <p class=MsoBodyText style='margin-top:2.95pt;margin-right:98.75pt;margin-bottom:
  0cm;margin-left:37.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Cipher
  <span style='color:#6A3D3D'>cipher </span>= Cipher.<i>getInstance</i>(<span
  style='color:#2A00FF'>&quot;AES/CBC/PKCS5Padding&quot;</span>);<span
  style='letter-spacing:.05pt'> </span><span style='color:#6A3D3D'>cipher</span>.init(Cipher.<b><i><span
  style='color:#0000C0'>DECRYPT_MODE</span></i></b>, <span style='color:#6A3D3D'>secretKeySpec</span>,
  <span style='color:#6A3D3D'>ivParameterSpec</span>);<span style='letter-spacing:
  -2.15pt'> </span><b><span style='color:#7E0054'>byte</span></b>[]<span
  style='letter-spacing:-.1pt'> </span><span style='color:#6A3D3D'>decryptedData<span
  style='letter-spacing:.05pt'> </span></span>=<span style='letter-spacing:-.1pt'>
  </span><span style='color:#6A3D3D'>cipher</span>.doFinal(<span
  style='color:#6A3D3D'>encryptedBytes</span>);</span></p>
  
  <p class=MsoNormal style='margin-left:37.95pt;line-height:12.2pt'><b><span
  style='font-size:13.0pt;color:#7E0054'>return<span style='letter-spacing:-.3pt'>
  </span>new<span style='letter-spacing:-.2pt'> </span></span></b><span
  style='font-size:13.0pt'>String(<span style='color:#6A3D3D'>decryptedData</span>);</span></p>
  
  <p class=MsoNormal style='margin-left:1.95pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}<span style='letter-spacing:-.15pt'> </span><b><span
  style='color:#7E0054'>catch<span style='letter-spacing:-.05pt'> </span></span></b>(Exception
  <span style='color:#6A3D3D'>e</span>)<span style='letter-spacing:-.15pt'> </span>{</span></p>
  
  <p class=MsoBodyText style='margin-left:37.95pt;line-height:12.15pt'><b><i><span
  style='font-size:13.0pt;color:#0000C0'>LOGGER</span></i></b><span
  style='font-size:13.0pt'>.error(<span style='color:#2A00FF'>&quot;Exception<span
  style='letter-spacing:-.1pt'> </span>in<span style='letter-spacing:-.05pt'> </span>decryptMData()<span
  style='letter-spacing:-.2pt'> </span>:<span style='letter-spacing:-.15pt'> </span>encryptKey-<span
  style='letter-spacing:-.15pt'> </span>&quot; </span>+<span style='letter-spacing:
  -.15pt'> </span><span style='color:#6A3D3D'>encryptKey </span>+<span
  style='letter-spacing:-.15pt'> </span><span style='color:#2A00FF'>&quot;<span
  style='letter-spacing:-.15pt'> </span>:<span style='letter-spacing:-.15pt'> </span>Message-<span
  style='letter-spacing:-.05pt'> </span>&quot; </span>+</span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:always'>
  </span>
  
  <div class=Section5>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt;line-height:12.15pt'><span
  style='font-size:13.0pt'>}</span></p>
  
  <h4 style='margin-left:77.05pt;line-height:12.15pt'><span style='font-size:
  13.0pt;color:#7E0054'>return<span style='letter-spacing:-.1pt'> </span>null</span><span
  style='font-size:13.0pt;font-weight:normal'>;</span></h4>
  
  <p class=MsoNormal style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:41.05pt;margin-bottom:.0001pt'><b><span style='font-size:13.0pt'>}</span></b></p>
  
  <p class=MsoBodyText style='margin-top:.15pt'><b><span style='font-size:13.0pt'>&nbsp;</span></b></p>
  
  <h1 style='margin-top:2.25pt'><span style='color:#1F467A'>Appendix:</span></h1>
  
  <h2 style='margin-top:9.4pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  43.9pt;margin-bottom:.0001pt;text-indent:-10.95pt'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;
  </span>Response <span style='letter-spacing:-.4pt'> </span> Codes : </h2>
  
  <p class=MsoBodyText style='margin-top:.35pt'><b><span style='font-size:7.0pt'>&nbsp;</span></b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:33.5pt;border-collapse:collapse;border:none'>
   <tr style='height:26.35pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:26.35pt'>
    <p class=TableParagraph style='line-height:13.4pt'><b>Response <span
    style='letter-spacing:-.2pt'> </span>Code</b></p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border:solid black 1.0pt;
    border-left:none;padding:0cm 0cm 0cm 0cm;height:26.35pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'><b>Response <span
    style='letter-spacing:-.2pt'> </span> Message</b></p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'>200</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'>Success</p>
    </td>
   </tr>
   <tr style='height:22.05pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.05pt'>
    <p class=TableParagraph style='line-height:13.4pt'>417</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.05pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'>Fail/Exception</p>
    </td>
   </tr>
   <tr style='height:22.15pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.15pt'>
    <p class=TableParagraph style='margin-top:.3pt'>198</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.15pt'>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.2pt;margin-bottom:.0001pt'><span style='letter-spacing:
    -.05pt'>Transaction</span><span style='letter-spacing:-.45pt'> </span>processing</p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'>197</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'>Rejected<span
    style='letter-spacing:-.25pt'> </span>by<span style='letter-spacing:-.35pt'> </span>acquirer</p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'>99</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'>failed</p>
    </td>
   </tr>
   <tr style='height:22.5pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='line-height:13.4pt'>194</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:22.5pt'>
    <p class=TableParagraph style='margin-left:6.2pt;line-height:13.4pt'>Declined</p>
    </td>
   </tr>
   <tr style='height:36.8pt'>
    <td width=288 valign=top style='width:216.15pt;border:solid black 1.0pt;
    border-top:none;padding:0cm 0cm 0cm 0cm;height:36.8pt'>
    <p class=TableParagraph style='line-height:13.4pt'>193</p>
    </td>
    <td width=289 valign=top style='width:216.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    padding:0cm 0cm 0cm 0cm;height:36.8pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:20.65pt;
    margin-bottom:0cm;margin-left:6.2pt;margin-bottom:.0001pt;line-height:105%'><span
    style='letter-spacing:-.05pt'>Authorization</span><span style='letter-spacing:
    -.5pt'> </span>success<span style='letter-spacing:-.4pt'> </span>but<span
    style='letter-spacing:-.5pt'> </span>error<span style='letter-spacing:-.15pt'>
    </span>processing<span style='letter-spacing:-2.3pt'> </span>recurring<span
    style='letter-spacing:-.1pt'> </span>payment</p>
    </td>
   </tr>
  </table>
  
  <p class=MsoNormal>&nbsp;</p>
  
  </div>
  
  <span style='font-size:9.0pt;font-family:"Calibri","sans-serif"'><br clear=all
  style='page-break-before:always'>
  </span>
  
  <div class=Section6></div>
  
  <span style='font-size:9.0pt;font-family:"Calibri","sans-serif"'><br clear=all
  style='page-break-before:always'>
  </span>
  
  <div class=Section7>
  
  <p class=MsoBodyText style='margin-left:77.05pt;line-height:12.15pt'>&nbsp;</p>
  
  </div>
  
  <span style='font-size:11.0pt;font-family:"Calibri","sans-serif"'><br
  clear=all style='page-break-before:always'>
  </span>
  
  <div class=Section8>
  
  <p class=MsoBodyText style='margin-top:2.45pt;margin-right:45.5pt;margin-bottom:
  0cm;margin-left:77.05pt;margin-bottom:.0001pt'>&nbsp;</p>
  
  </div>
  
  </body>
  
  </html>
  `;

  return (
    <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
      <Container>
        <div className="ds-header-sec">
          <Row>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success mb-3" onClick={goBack}>
                {" "}
                &#8592; Back
              </button>
            </div>

            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4 ds-header-card">
                <Card.Body>
                  <div
                    className="merchant-tab-btn  p-3  "
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  ></div>
                </Card.Body>
              </Card>
              {/* ./card */}
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-3" onClick={goBack}>
              {" "}
              &#8592; Back
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PaymentIntegrationKit;
