import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Api = () => {
  return (
    <>
      <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                      <Link to="/instantWebCallback">
                        <h6 className="fw-bold">Instant Web Callback </h6>
                      </Link>
                    </div>

                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                        <Link to="/payment_integration_kit">
                            <h6 className="fw-bold">Intend UPI Icepe IPG Payment integration kit</h6>
                        </Link>
                    </div>

                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                        <Link to="/intent_callback">
                            <h6 className="fw-bold">Intent Callback </h6>
                        </Link>
                    </div>

                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                        <Link to="/seamless_payment_integration">
                            <h6 className="fw-bold">Seamless Payment Integration </h6>
                        </Link>
                    </div>

                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                        <Link to="/swipelinc_S2S_Rellser">
                            <h6 className="fw-bold">Icepe S2S Rellser API DOC </h6>
                        </Link>
                    </div>

                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                        <Link to="/payout_docs">
                            <h6 className="fw-bold">Payout </h6>
                        </Link>
                    </div>

                    <div className="merchant-tab-btn border border-1 p-3 text-primary mt-3">
                        <Link to="/block-vpa-api">
                            <h6 className="fw-bold">Block VPA Api </h6>
                        </Link>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Api;
