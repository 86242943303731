import React, { useEffect, useState } from 'react'
import InactiveServices from '../../../common/InactiveServices';
import { Card, Col, Container, Row } from 'react-bootstrap';
import MyLoader from '../../../common/MyLoader';
import CreateUser from './CreateUser';
import SubmittedUser from './SubmittedUser';

const UserSubTab = () => {
    const [activePhase, setActivePhase] = useState("Phase1");
    const [showLoader, setShowLoader] = useState(false);
  
    useEffect(() => {
      setActivePhase("Phase1");
      //   setActiveSubtab("MerchantManagement");
    }, []);
  
    const handlePhaseClick = (phase) => {
      setActivePhase(phase);
    };
  
    return (
  
  
      <>
        {showLoader ? <MyLoader /> : ""}
        <section className="dashboard-header-sec">
          <Container>
            <div className="ds-header-sec">
                
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <Card className="shadow border-0 mb-4 ds-header-card">
                    <Card.Body>
                      {/* <Card.Title className="h3">Reports</Card.Title>  */}
                      <div className="merchant-tab-btn">
                        <ul className="report-link nav-report-btn">
                          <li
                            className={activePhase === "Phase1" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase1")}
                          >
                            Create User
                          </li>
                          <li
                            className={activePhase === "Phase2" ? "active" : ""}
                            onClick={() => handlePhaseClick("Phase2")}
                          >
                            Submitted
                          </li>
  
                          {/* <li
                      className={activePhase === "Phase5" ? "active" : ""}
                      onClick={() => handlePhaseClick("Phase5")}
                    >
                      INSTRUMENT MANAGEMENT
                    </li> */}
                      </ul>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <Container> 
        {activePhase === "" ? null : (
          <Card className='p-4 border-none'> 
          {/* <div> */}
            {activePhase === "Phase1" ? (
              <CreateUser setShowLoader={setShowLoader}/>
  
            ) : activePhase === "Phase2" ? (
               <SubmittedUser setShowLoader={setShowLoader} />
            ) : (
              <InactiveServices location={"SubTabTools"} />
            )}
          {/* </div> */}
        </Card>
        )}
        </Container>
      </>
    );
  };

export default UserSubTab
