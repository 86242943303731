import React, { useEffect, useState } from "react";
import styles from "./FirstStage.module.css";
import Logo from "../../assets/images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import ResponsiveAppBar from "../onbord/ResponsiveAppBar";
import jwtDecode from "jwt-decode";
import {
  accountNumberIfscCodeVerifier,
  checkIsFileIsPDF,
  ifscCodeVerifier,
  updateMerchantBankDetails,
  uploadMerchantDocs,
} from "../../services/apiService";
import swal from "sweetalert";
import { Card, Col, Container, Row } from "react-bootstrap";
import { MdCancel } from "react-icons/md";
import { BsCheck2All } from "react-icons/bs";
import MyLoader from "../../common/MyLoader";
import { getKeyPressed, isIfscRegexIsValid, stopNumberCharInput, stopSpecialCharacterInput } from "../../utils/validations";

const FirstStage = () => {

  const navigate = useNavigate();

  const initialValues = {
    accountNumber: "",
    ifsc: "",
    bankName: "",
    branchName: "",
    bankCity: "",
    callbackUrl: "",
    payoutCallBackUrl:"",
    websiteUrl: "",
    docs: "",
  };

  

  const [companyRegFile, setCompanyRegFile] = useState(null);
  const [passwordPopUp, setPasswordPopUp] = useState(true);
  const [accountName, setAccountName] = useState("");

  const [formData, setFormData] = useState(initialValues);

  const [supportingFileList, setSupportingFileList] = useState([]);
  const [docsFile, setDocsFile] = useState([]);
  const [docsName, setDocsName] = useState([]);

  const [pressedKeyName, setPressedKeyName] = useState("");

  const [isIfscVerified, setIsIfscVerified] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [companyType, setCompanyType] = useState("");

  // console.log("token", localStorage.getItem("token"));

  // if(localStorage.getItem("token")?.trim() === "") {
  //   navigate("/")
  // }
 
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  const stageData = localStorage.getItem("data");
  const { isPasswordUpdate, CompanyType } = JSON.parse(stageData);

  const handelFormData = (e) => {
    // console.log("filesssssss", e.target.files);
    const { name, value } = e.target;

    if((stopSpecialCharacterInput(pressedKeyName) || stopNumberCharInput(pressedKeyName)) && ( name === "bankCity" || name === "bankName" || name === "branchName" )) {
      return
    }

    if(isIfscVerified && (name === "ifsc" || name === "accountNumber")) {
      setAccountName("")
      setIsIfscVerified(false)
    }

    if (
      isIfscVerified &&
      (name === "bankName" || name === "branchName" || name === "bankCity")
    ) {
      return;
    }
    
    if ( isIfscVerified === "NotVerified") {
      setIsIfscVerified(false);
    }

    if(name==="payoutCallBackUrl" || name==="callbackUrl"){

      setFormData({ ...formData, [name]: value  });
    }else{

      setFormData({ ...formData, [name]: value?.toUpperCase() });
    }
  
  };

  const handelValidations = (e) => {
    if (e.target.name === "ifsc" && e.target.value !== "") {
      if (!isIfscRegexIsValid(formData.ifsc)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Number !",
          icon: "error",
        }).then(() => {
          setFormData({ ...formData, ifsc: "" });
        });
        return;
      }
    }
  }
  
  const setFilesToState = () => {
    let nameArr = [];
    let docsArr = [];
    // extracting documents data from state
    supportingFileList.forEach((element, indx) => {
      let name = element.name;
      let file = element.file;
      nameArr.push(name);
      docsArr.push(file);
    });

    setDocsName(nameArr);
    setDocsFile(docsArr);
  };


  const saveDataToServer = async(payload) => {

    try {
      setShowLoader(true)
      let response = await updateMerchantBankDetails(payload);
      setShowLoader(false)

      if(response.statusCode === 200) {
        setShowLoader(true)
        let merchantDocsRes = await uploadMerchantDocs(mid, [companyType], [formData.docs]);        
        setShowLoader(false)
        if (merchantDocsRes === 200) {
          document.getElementById("docs").value = "";
        } else {
          swal({
            title: "Alert!",
            text: "Error in Uploading Documents",
            icon: "error",
          })
        }
      }

      if (response.statusCode === 200 && supportingFileList.length > 0) {
        setShowLoader(true)
        let merchantSupportingDocs = await uploadMerchantDocs(mid, docsName, docsFile);
        setShowLoader(false)
        if(merchantSupportingDocs === 200){
          setSupportingFileList([]);
        } else {
          swal({
            title: "Alert!",
            text: "Error in Uploading Documents",
            icon: "error",
          })
        }
      }

      if (response.statusCode === 200) {
        setFormData(initialValues);
        swal({
          title: "Success!",
          text: "Data Saved Successfully !",
          icon: "success",
        }).then(function () {
          window.location = "https://swipelinc.com/";
        });
        setCompanyRegFile(null);
        // localStorage.removeItem("token")
      } else if (response.statusCode === 304) {
        swal({
          title: "Alert!",
          text: "Beneficiary Account Name and Company Name Must be Similiar !",
          icon: "error",
        });
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong !",
          icon: "error",
        });
      }
    } catch (error) {
    
      console.error("An error occurred while submitting the form:", error);
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();

    if(companyType !==""){
      if(document.getElementById("docs").value === "" ) {
        swal({
          title: "ALERT!",
          text: "Please Select your document*",
          icon: "error",
        });
        return
      }
    }

    if(formData.accountNumber === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Account number*",
        icon: "error",
      });
      return
    }

    if(formData.ifsc === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter IFSC Code*",
        icon: "error",
      });
      return
    }

    if(formData.bankName === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Bank Name*",
        icon: "error",
      });
      return
    }

    if(formData.branchName === "") {
      swal({
        title: "ALERT!",
        text: " Please Enter Branch Name*",
        icon: "error",
      });
      return
    }

    if(formData.bankCity === "") {
      swal({
        title: "ALERT!",
        text: "Please Enter Bank City*",
        icon: "error",
      });
      return
    }

    if(accountName === "") {
      swal({
        title: "ALERT!",
        text: "Please Verify your Bank*",
        icon: "error",
      });
      return
    }

    supportingFileList.forEach( docsRow => {
      if(docsRow.name === "") {
        swal({
          title: "Alert!",
          text: "Please Enter Supporting Documents Labels",
          icon: "error",
        });
        return 
      }
    })

    supportingFileList.forEach( docsRow => {
      if(docsRow.file === "") {
        swal({
          title: "Alert!",
          text: "Please Select Supporting Documents",
          icon: "error",
        });
        return 
      }
    })

    const payload = {
      accoutBeneficiaryName: accountName,
      bankBranchName: formData.branchName,
      callBackUrl: formData.callbackUrl,
      merchantAccountNumber: formData.accountNumber,
      merchantBankName: formData.bankName,
      merchantIfscCode: formData.ifsc,
      mid: mid,
      websiteUrl: formData.websiteUrl,
      payoutCallBackUrl : formData.payoutCallBackUrl

    };

    saveDataToServer(payload)
    
    // console.log(docsFile);

    // // Iterate over FormData entries
    // formData.forEach((value, key) => {
    //   console.log("Key:", key, "Value:", value);
    // });
    // formData.append("accountNumber", values.accountNumber);
    // formData.append("ifsc", values.ifsc);
    // formData.append("bankName", values.bankName);
    // formData.append("branchName", values.branchName);
    // formData.append("callbackUrl", values.callbackUrl);
    // formData.append("websiteUrl", values.websiteUrl);
    // const data = {
    //   docs: docsFile,
    //   companyRegistration: companyRegFile,
    //   supportingDocuments: supportingFileList,
    //   mid: mid,
    // };
    // console.log("Api call");
    // const fileResponse = await axios.post(
    //   "https://merchant.lincpay.in/MerchantPanel/merchant/onboard/savemerchantdocuments",
    //   formData,
    //   {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   }
    // );

    // console.log(fileResponse.data);

    // Append supportingDocuments files
    // values.supportingDocuments.forEach((document, index) => {
    //   formData.append(`supportingDocuments[${index}].name`, document.name);
    //   formData.append(
    //     `supportingDocuments[${index}].document`,
    //     document.document
    //   );
    // });
  };

  const handleViewFile = (file) => {
    if (!file) {
      return; // If no file is selected, do nothing
    }
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL, "_blank");
  };

  const verifyIfsc = async () => {
   
    if (formData.accountNumber === "") {
        swal({
          title: "ALERT!",
          text: "*Please Enter Account number",
          icon: "error",
        });
      return;
    }
    if (formData.ifsc === "") {
        swal({
        title: "ALERT!",
        text: "*Please Enter IFSC Code",
        icon: "error",
      });
      return;
    }
    setShowLoader(true);
    const isAccVerified = await accountNumberIfscCodeVerifier(
      formData.ifsc,
      formData.accountNumber,
      token
    );

    setAccountName(isAccVerified.data.AccoutBeneficiaryName);

    const res = await ifscCodeVerifier(formData.ifsc);
    setShowLoader(false);

    if (res.status === 200 && isAccVerified.statusCode === 200) {
      setFormData({
        ...formData,
        branchName: res.data.branch,
        bankName: res.data.bank,
        bankCity: res.data.city,
      });
      setIsIfscVerified(true);
    } else {
      setIsIfscVerified("NotVerified");
      swal({
        title: "Alert!",
        text: "Invalid Bank Details !",
        icon: "error",
      });
    }
  };

  const addSupportingDocumentField = () => {
    let newEntry = {
      id: new Date().getTime().toLocaleString(),
      name: "",
      file: "",
    };
    setSupportingFileList([...supportingFileList, newEntry]);
  };

  const removeSupportingDocumentField = (id) => {
    let newData = supportingFileList.filter((elem) => {
      console.log(elem);
      return elem.id !== id;
    });

    let nameArray =newData.map((elem) => {
      return elem.name;
    })

    let fileArray =newData.map((elem) => {
      return elem.file;
    })
  
    setDocsName(nameArray)
    setDocsFile(fileArray)

    setSupportingFileList(newData);


  };

  const handelDocumentsFileName = (event, id) => {
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.name = event.target.value?.toUpperCase();
        return elem;
      }
      return elem;
    });

    setSupportingFileList(newArr);
    setFilesToState();
  };

  const handelDocumentsFileData = async (file, id) => {
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.file = file;
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);
    setFilesToState();
  };

  const isPdfFile = async (event, id) => {
    // clear state of pdf seleted
    let newArr = supportingFileList.map((elem) => {
      if (elem.id === id) {
        elem.file = "";
        return elem;
      }
      return elem;
    });
    setSupportingFileList(newArr);

    let file = event.target.files[0];
    setShowLoader(true)
    let res = await checkIsFileIsPDF(file);
    setShowLoader(false)
    if (res === 200) {
      handelDocumentsFileData(file, id);
      return;
    } else {
      swal({
        title: "Alert!",
        text: "Kindly Upload PDF FIles Only",
        icon: "error",
      });
      setCompanyRegFile(null);
      document.getElementById(id).value = "";
      return false;
    }
  };

  const getCompanyType = () => {
    const { companyType } = CompanyType;
    if (  companyType?.trim() === "PRIVATE LIMITED" ||
          companyType?.trim() === "PUBLIC LIMITED" ||
          companyType?.trim() === "OPC") {
            setCompanyType("MOA")
          } else if(companyType?.trim() === "LLP" || companyType?.trim() === "PARTNERSHIP") {
            setCompanyType("Partnership Deal")
          } else if(companyType?.trim()=== "PROPERITARY") {
            setCompanyType("Gumasta")
          } else if(companyType?.trim() === "NGO/TRUST") {
            setCompanyType("Bye-laws")
          }
  };

  

  useEffect(() => {
    
    getCompanyType();
  }, [])

  return (
    <>
      {showLoader? <MyLoader /> :""}
      <ResponsiveAppBar />

      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          <div className="cart-view-sec mt-4">
            <Row>
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <Card.Title className="h3">Account Details</Card.Title>
                    <div className="merchant-form-info">
                      <form
                        onSubmit={handleSubmit}
                        className="form-section-form-group"
                      >
                        <Row>
                          {companyType!="" ? (
                            <Col xs={12} lg={4} md={6}>
                              <div className="form-group">
                                <label htmlFor="accNum" className="form-label">
                                  {companyType}
                                  <sup className="text-danger">*</sup>
                                </label>
                                <input
                                  type="file"
                                  accept=".pdf"
                                  id="docs"
                                  name="docs"
                                  className="form-control"
                                  // value={formData.docs}
                                  // onChange={handelFormData}
                                  onChange={(event) => {
                                    // Handle file upload here
                                    setCompanyRegFile(event.target.files[0]);
                                    if (isPdfFile(event, "docs")) {
                                      setFormData({
                                        ...formData,
                                        docs: event.target.files[0],
                                      });
                                    }
                                  }}
                                />
                               
                                {companyRegFile && (
                                  <button
                                    type="button"
                                    className={styles.viewButton_first_stage}
                                    onClick={() =>
                                      handleViewFile(companyRegFile)
                                    }
                                  >
                                    View
                                  </button>
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="accNum" className="form-label">
                                Account Number
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="number"
                                // id="firstName"
                                id="accountNumber"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="accountNumber"
                                value={formData.accountNumber}
                                onChange={handelFormData}
                                className="form-control"
                              />
                              
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="ifsc" className="form-label">
                                IFSC Code
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="ifscCode"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                onBlur={ handelValidations }
                                name="ifsc"
                                value={formData.ifsc}
                                onChange={handelFormData}
                                className="form-control"
                              />
                              
                              <div className="d-flex justify-content-end">
                                {!isIfscVerified ? (
                                  <span
                                    onClick={verifyIfsc}
                                    role="button"
                                    className="text-primary cursor-pointer align-right mt-1"
                                  >
                                    Verify IFSC
                                  </span>
                                ) : (
                                  ""
                                )}
                                {isIfscVerified === true ? (
                                  <div className="d-flex justify-content-end">
                                    <span
                                      role="button"
                                      className="text-success cursor-pointer align-right mt-1"
                                    >
                                      <b>
                                        <BsCheck2All />
                                        Verified
                                      </b>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {isIfscVerified === "NotVerified" ? (
                                  <div className="d-flex justify-content-end">
                                    <span
                                      role="button"
                                      className="text-danger cursor-pointer align-right mt-1"
                                    >
                                      <b>
                                        <MdCancel />
                                        Not Verified
                                      </b>
                                    </span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="bankName" className="form-label">
                                Bank Name
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="bankName"
                                onKeyDown={(e) => {
                                  // if (e.key === " ") {
                                  //   e.preventDefault();
                                  // }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="bankName"
                                value={formData.bankName}
                                onChange={handelFormData}
                                className="form-control"
                              />
                               
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="branchName"
                                className="form-label"
                              >
                                Branch Name
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="branchName"
                                onKeyDown={(e) => setPressedKeyName(getKeyPressed(e))}
                                name="branchName"
                                value={formData.branchName}
                                
                                onChange={handelFormData}
                                className="form-control"
                              />
                               
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="city" className="form-label">
                                City
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="city"
                                onKeyDown={(e) => {
                                  // if (e.key === " ") {
                                  //   e.preventDefault();
                                  // }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="bankCity"
                                value={formData.bankCity}
                                onChange={handelFormData}
                                className="form-control"
                              />
                               
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="callBackUrl"
                                className="form-label"
                              >
                               Payin Callback URL
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="callBackUrl"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  // setPressedKeyName(getKeyPressed(e));
                                }}
                                name="callbackUrl"
                                value={formData.callbackUrl}
                                onChange={handelFormData}
                                className="form-control"
                              />
                              <span
                                id="callBackUrlErr"
                                className=" text-danger d-none"
                              >
                                Please Enter CallBack Url*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label
                                htmlFor="payoutCallBackUrl"
                                className="form-label"
                              >
                               Payout Callback URL
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="payoutCallBackUrl"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  // setPressedKeyName(getKeyPressed(e));
                                }}
                                name="payoutCallBackUrl"
                                value={formData.payoutCallBackUrl}
                                onChange={handelFormData}
                                className="form-control"
                              />
                              <span
                                id="payoutCallBackUrlErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Payout CallBack Url*
                              </span>
                            </div>
                            {/* ./form-group */}
                          </Col>

                          {/* <Col xs={12} lg={4} md={6}>
                            <div className="form-group">
                              <label htmlFor="webUrl" className="form-label">
                                Website URL
                              </label>
                              <input
                                type="text"
                                // id="firstName"
                                id="webUrl"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  // setPressedKeyName(getKeyPressed(e));
                                }}
                                name="websiteUrl"
                                value={formData.websiteUrl}
                                onChange={handelFormData}
                                className="form-control"
                              />
                              <span
                                id="webUrlErr"
                                className=" text-danger d-none"
                              >
                                Please Enter Website URL*
                              </span>
                            </div> 
                          </Col> */}
                          
                          <div className="form-group mt-3">
                            <label htmlFor="callbackUrl">
                              Other Supporting Documents{" "}
                              <span
                                onClick={addSupportingDocumentField}
                                style={{ cursor: "pointer" }}
                                className="text-primary fs-5"
                              >
                                <i className ="bi bi-plus-circle-fill"></i>
                              </span>{" "}
                            </label>
                            {supportingFileList.map((elem) => {
                              return (
                                <div className="mt-3" key={elem.id}>
                                  <div className="d-flex">
                                    <div className=" w-100">
                                      <div className="form-group">
                                        <label
                                          htmlFor="callBackUrl"
                                          className="form-label"
                                        >
                                          Label Name
                                          <sup className="text-danger">*</sup>
                                        </label>
                                        <input
                                          type="text"
                                          id="callBackUrl"
                                          name="name"
                                          value={elem.name}
                                          required="true"
                                          onChange={(event) =>
                                            handelDocumentsFileName(
                                              event,
                                              elem.id
                                            )
                                          }
                                          className="form-control"
                                        />

                                        {/* <span
                                          id="callBackUrlErr"
                                          className=" text-danger d-none"
                                        >
                                          Please Enter CallBack Url*
                                        </span> */}
                                      </div>
                                    </div>

                                    <input
                                      type="file"
                                      accept=".pdf"
                                      required="true"
                                      onChange={(event) =>
                                        isPdfFile(event, elem.id)
                                      }
                                      id={elem.id}
                                      className="form-control mx-3"
                                      style={{ marginTop: "29px" }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-end mt-1 mx-2">
                                    <span
                                      onClick={() =>
                                        removeSupportingDocumentField(elem.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                      className="text-danger fs-5"
                                    >
                                      <i className ="bi bi-trash-fill"></i>
                                    </span>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Row>
                      </form>
                    </div>

                    <div className="merchant-form-info"></div>
                    <Col xs={12} lg={12} md={12}>
                      <div className="d-flex justify-content-end">
                        <div className="form-group  tool-bar-btn mb-0 mt-3">
                          <button
                            className="submit-button submit_link_info btn btn-success"
                            onClick={handleSubmit}
                            id="submitDetailsBtn"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </Row>
          </div>
        </Container>
      </section>

      {isPasswordUpdate !== true && passwordPopUp === true ? (
        <div className={styles.popup_container_frist_stage}>
          <div className={styles.popup_first_stage}>
            <h3>Please change Your Password</h3>
            <p>Click Here to Change Your Password</p>
            <Link to="/PasswordChangeForm" className="close_button">
              Change Password
            </Link>
            <div className={styles.checkbox_first_stage}>
              <input type="checkbox" onChange={() => setPasswordPopUp(false)} />
              <label>Do Not show this again</label>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default FirstStage;
