import React, { useEffect, useState } from "react";
import InactiveService2 from "../../common/inactiveService2";
import { Col, Container } from "react-bootstrap";
import swal from "sweetalert";
import jwtDecode from "jwt-decode";
import {
  GetStaticQr,
  getAllFunctionForApiCalls,
} from "../../services/apiService";
import { GET_STATIC_QR } from "../../utils/constant";

const StaticQR = ({ setShowLoader }) => {
  const Type = localStorage.getItem("typeOfServices");
  const paymentType =Type ? JSON.parse(Type) : null;

  const [hexCode, setHexCode] = useState("");
  const [qrCodeImage, setQRCodeImage] = useState(null);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const generateQRCode = async () => {
    try {
      

      // Convert base64 string to Blob
      const byteCharacters = atob(hexCode);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const qrCodeBlob = new Blob([byteArray], { type: "image/png" });

      setQRCodeImage(URL.createObjectURL(qrCodeBlob));
    } catch (error) {
      console.error("Error:", error);
      swal("Error", "An error occurred while generating QR code", "error");
    }
  };

  const getStaticQr = async (token) => {
    try {
      const response = await GetStaticQr(token); // Add the await keyword here

      if (response.statusCode === "OK") {
        setHexCode(response.responseData);
        console.log(hexCode)
      } else {
        setHexCode([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStaticQr(token);
  }, []);

  useEffect(() => {
    generateQRCode();
  }, [hexCode]);

  return (
    <div>
      {paymentType.includes("QR") ? (
        <div className="d-flex justify-content-center align-items-center">
          {/* <label>
            Hex Code:
            <input
              type="text"
              value={hexCode}
              onChange={(e) => setHexCode(e.target.value)}
            />
          </label>
          <button onClick={generateQRCode}>Generate QR Code</button> */}

          {qrCodeImage && <img src={qrCodeImage} alt="QR Code" />}
        </div>
      ) : (
        <InactiveService2 />
      )}
    </div>
  );
};

export default StaticQR;
