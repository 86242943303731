import React from "react";
import { MDBDataTable } from "mdbreact";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import {
  AcceptOrRejectPayin,
  fetchLiveTrackingReport,
  findByMidAndOrderNo,
  useReportDataFetcher as reportData,
} from "../../services/apiService";
import "../../assets/css/style.css";
import { FIND_BY_ORDER_NO, column } from "../../utils/constant";
import jwtDecode from "jwt-decode";
import { FiDownload } from "react-icons/fi";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import { Button } from "bootstrap";
import Modal from "react-bootstrap/Modal";
import swal from "sweetalert";
import { Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import DailyTransactionBox from "./DailyTransactionBox";

const MBDReportSorting = ({ data, boxApiResponse , setShowLoader, }) => {
  // console.log(Data);
  const [records, setRecords] = useState([]);
  const [rows, setrows] = useState();
  const [filterRecords, setFilterRecords] = useState([]);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : "";
  const decodedToken = jwtDecode(token);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const { mid } = decodedToken;
  const startDate = useSelector((store) => store.report.startDate);

  const [viewResponseModal, setViewResponseModal] = useState(false);
  const [viewResponseRecords, setViewResponseRecords] = useState([]);
  const [recordsData, setRecordsData] = useState([]);

  const [viewTxnStatusModal, setViewTxnStatusModal] = useState(false);
  const [data1, setData1] = useState([]);
  const [liveTrackingReport, setLiveTrackingReport] = useState([]);

  const endDate = useSelector((store) => store.report.endDate);
  const mobileValue = useSelector((store) => store.report.mobile);
  const emailValue = useSelector((store) => store.report.email);
  const orderNumberValue = useSelector((store) => store.report.orderNumber);
  const transactionIdValue = useSelector((store) => store.report.transactionId);

  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await reportData(mid, token, setShowLoader);
      console.log("Response from payin", response);
      setRecords(response);

      setFilterRecords(response);
    } catch (error) {
      setError("No Data Found");
    }
  };

  const AcceptOrReject = async (orderNumber, type) =>{


    swal({
      title: "Are you sure?",
      text: "Do you Really want to Perform this?",
      icon: "info",
      buttons: true,
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        // User confirmed, submit the request
        try {
          const payload = {
            orderNo: orderNumber,
            status: type,
          };
          console.log(payload);
    
          const response = await AcceptOrRejectPayin(payload, token);
          if (response.statusCode === 200) {
            swal({
              title: "Success!",
              text:  type === "ACCEPT" ? "Accepted Successfully." : "Rejected Successfully.",
              icon: "success",
            }) 
            fetchData();
            getLiveTrackingReport();
          } else if (response.statusCode === 304) {
            
            swal({
              title: "Alert!",
              text: type === "ACCEPT" ? "Failed To Accept." : "Failed To Reject.",
              icon: "error",
            }) 
            // navigate('/Reports')
          } else {
            swal({
              title: "Alert!",
              text: "Something Went Wrong.",
              icon: "error",
            }) 
            // navigate('/Reports')
          }
    
          console.log(response?.data);
    
          //   setFilterRecords(response);
        } catch (error) {
          console.log(error)
        } finally {
          setIsSubmitting(false);
        }
      } else {
        // User canceled the submission
        swal("Submission canceled!");
      }
    });
   
  }

  useEffect(() => {
    fetchData();
    getLiveTrackingReport();
  }, []);

  const getLiveTrackingReport = async () => {
    let res = await fetchLiveTrackingReport(mid, token);

    if (res.statusCode === 200) setLiveTrackingReport(res.data);
    else setLiveTrackingReport([]);
  };

  const value = Array.isArray(data) ? data : [];
  console.log("-----", value.length);

  function handleViewButtonClick(orderNo) {
    toggleViewResponseModal();

    //   //  Navigate( )
    //  navigate(/ViewResponse/${orderNo});

    fetchViewResponseData(orderNo);
  }

  const fetchViewResponseData = async (orderNumber) => {
    try {
      const payload = {
        mid: mid,
        orderNo: orderNumber,
      };
      console.log(payload);

      const response = await findByMidAndOrderNo(payload, token);
      if (response.statusCode === 200) {
        setViewResponseRecords(response?.data);
        console.log(viewResponseRecords);
        setRecordsData(response?.data?.data[0]);
      } else if (response.statusCode === 304) {
        setViewResponseRecords([]);
        setRecordsData([]);
        swal({
          title: "Alert!",
          text: "No Data Found",
          icon: "error",
        }).then(() => {
          setViewResponseModal(false);
        });
        // navigate('/Reports')
      } else {
        swal({
          title: "Alert!",
          text: "Something Went Wrong.",
          icon: "error",
        }).then(function () {
          setViewResponseModal(false);
        });
        // navigate('/Reports')
      }

      console.log(response?.data);

      //   setFilterRecords(response);
    } catch (error) {}
  };

   

  function handelViewTxnBtn(orderNo) {
    //  Navigate( )
    // navigate(/ViewTxnStatus/${orderNo});

    toggleViewTxnStatusModal();
    handleSearch(orderNo);
  }

 
  function HandleAcceptOrReject(orderNo, type) {
    //  Navigate( )
    // navigate(/ViewTxnStatus/${orderNo});
    console.log(orderNo);
    console.log("TYPE==================", type);
    AcceptOrReject(orderNo,type)

  }

  const mappedData = data
    ? []
    : filterRecords.map((item) => {
        if (item.txnStatus === "failed") {
          const mappedItem = {
            ...item, // Include all existing data properties
            txnStatus:
              item.txnStatus === null || item.txnStatus === ""
                ? ""
                : item.txnStatus.toUpperCase(),
            sentToBankDate: item?.sentToBankDate?.split(" ")[1],
            ackFromBankDate: item?.ackFromBankDate?.split(" ")[1],
            qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1],
            callbackSentToMerchantDate:
              item?.callbackSentToMerchantDate?.split(" ")[1],
            ackReceivedFromMerchantDate:
              item?.ackReceivedFromMerchantDate?.split(" ")[1],
            responseDate: item?.responseDate?.split(" ")[1],

            // Add a "View" button property
            view: (
              <button
                className="p-2 border bg-primary rounded-2"
                onClick={() => handleViewButtonClick(item.orderNo)}
              >
                View Response
              </button>
            ),
            // Add a "View" button property
            txnStatusBtn: (
              <button
                className="p-2 border bg-primary rounded-2"
                onClick={() => handelViewTxnBtn(item.orderNo)}
              >
                View TXN Status
              </button>
            ),
            
          };

          return mappedItem;
        } else {
          const mappedItem = {
            ...item,
            evokTxnCode: item.txnStatus === "success" ? "0" : item.evokeTxnCode,
            txnStatus:
              item.txnStatus === null || item.txnStatus === ""
                ? ""
                : item.txnStatus.toUpperCase(),
            sentToBankDate: item?.sentToBankDate?.split(" ")[1],
            ackFromBankDate: item?.ackFromBankDate?.split(" ")[1],
            qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1],
            callbackSentToMerchantDate:
              item?.callbackSentToMerchantDate?.split(" ")[1],
            ackReceivedFromMerchantDate:
              item?.ackReceivedFromMerchantDate?.split(" ")[1],
            responseDate: item?.responseDate?.split(" ")[1],
            "AcceptOrReject": item.txnStatus.toUpperCase() === "WARNING" || item.txnStatus.toUpperCase() === "BLACKLIST"  ? (
              <div>
                <button
                  className="p-2 border bg-primary rounded-2 text-light"
                  onClick={() => HandleAcceptOrReject(item.orderNo, "ACCEPT")}
                >
                  Accept
                </button>
                <button
                  className="p-2 border bg-danger rounded-2 text-light"
                  onClick={() => HandleAcceptOrReject(item.orderNo, "REJECT")}
                >
                  Reject
                </button>
              </div>
            ) : null,
            // Add a "View" button property
            // txnStatusBtn: (
            //   <button
            //     className="p-2 border   bg-primary rounded-2"
            //     onClick={() => handelViewTxnBtn(item.orderNo)}
            //   >
            //     View TXN Status
            //   </button>
            // ),
          };

          return mappedItem;
        }
      });
  // console.log("-----",value.length);

  const mappedData2 = value.map((item) => {
    if (item.txnStatus === "failed") {
      const mappedItem = {
        ...item, // Include all existing data properties
        txnStatus:
          item.txnStatus === null || item.txnStatus === ""
            ? ""
            : item.txnStatus.toUpperCase(),
        sentToBankDate: item?.sentToBankDate?.split(" ")[1],
        ackFromBankDate: item?.ackFromBankDate?.split(" ")[1],
        qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1],
        callbackSentToMerchantDate:
          item?.callbackSentToMerchantDate?.split(" ")[1],
        ackReceivedFromMerchantDate:
          item?.ackReceivedFromMerchantDate?.split(" ")[1],
        responseDate: item?.responseDate?.split(" ")[1],

        // Add a "View" button property
        view: (
          <button
            className="p-2 border   bg-primary rounded-2"
            onClick={() => handleViewButtonClick(item.orderNo)}
          >
            View Response
          </button>
        ),
        // Add a "View" button property
        txnStatusBtn: (
          <button
            className="p-2 border   bg-primary rounded-2"
            onClick={() => handelViewTxnBtn(item.orderNo)}
          >
            View TXN Status
          </button>
        ),
         
      };

      return mappedItem;
    } else {
      const mappedItem = {
        ...item,
        evokTxnCode: item.txnStatus === "success" ? "0" : item.evokeTxnCode,
        txnStatus:
          item.txnStatus === null || item.txnStatus === ""
            ? ""
            : item.txnStatus.toUpperCase(),
        sentToBankDate: item?.sentToBankDate?.split(" ")[1],
        ackFromBankDate: item?.ackFromBankDate?.split(" ")[1],
        qrSentToMerchantDate: item?.qrSentToMerchantDate?.split(" ")[1],
        callbackSentToMerchantDate:
          item?.callbackSentToMerchantDate?.split(" ")[1],
        ackReceivedFromMerchantDate:
          item?.ackReceivedFromMerchantDate?.split(" ")[1],
        responseDate: item?.responseDate?.split(" ")[1],
        "AcceptOrReject": item.txnStatus.toUpperCase() === "WARNING" || item.txnStatus.toUpperCase() === "BLACKLIST"  ? (
          <div>
            <button
              className="p-2 border bg-primary rounded-2 text-light"
              onClick={() => HandleAcceptOrReject(item.orderNo, "ACCEPT")}
            >
              Accept
            </button>
            <button
              className="p-2 border bg-danger rounded-2 text-light"
              onClick={() => HandleAcceptOrReject(item.orderNo, "REJECT")}
            >
              Reject
            </button>
          </div>
        ) : null,
        // Add a "View" button property
        // txnStatusBtn: (
        //   <button
        //     className="p-2 border   bg-primary rounded-2"
        //     onClick={() => handelViewTxnBtn(item.orderNo)}
        //   >
        //     View TXN Status
        //   </button>
        // ),
      };

      return mappedItem;
    }
  });

  const newData = value.length === 0 ? mappedData : mappedData2;

  const data2 = {
    columns: column,
    rows: newData,
  };

  function handleDownloadExcel() {
    try {
      // Assuming you have access to the originalData
      const apiData = data ? data : records;

      // Extract the header labels from the data (adjust this based on your data structure)
      const headerLabels = Object.keys(apiData[0]);

      // Extract the data from the originalData
      const apiRows = apiData.map((item) => Object.values(item));

      // Combine header with data
      const dataWithHeader = [headerLabels, ...apiRows];

      // Convert the data with header to CSV format using PapaParse
      const csv = Papa.unparse(dataWithHeader);

      // Create a Blob object from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Report.csv"; // Change the file name as needed
      link.style.display = "none";

      document.body.appendChild(link);
      link.click();

      // Clean up by removing the temporary element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const calculateTotalAmountForSuccess = (data) => {
    // Filter the data to include only "success" transactions
    const successTransactions = data.filter(
      (item) => item.txnStatus === "success"
    );
    return successTransactions.reduce(
      (total, item) => total + parseFloat(item.amount),
      0
    );
    // Calculate the total sum of amounts for success transactions
  };

  console.log(calculateTotalAmountForSuccess(filterRecords));
  const totalAmountForSuccess = calculateTotalAmountForSuccess(
    !value.length ? filterRecords : value
  );
  const toggleViewResponseModal = () =>
    setViewResponseModal(!viewResponseModal);
  const toggleViewTxnStatusModal = () =>
    setViewTxnStatusModal(!viewTxnStatusModal);

  const columnForViewTxnStatus = [
    {
      label: "Customer Name",
      field: "customerName",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Transaction ID",
      field: "extTransactionId",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Status",
      field: "primaryStatus",
      sort: "asc",
      width: "850px",
    },
    {
      label: "Actual Status",
      field: "actualStatus",
      sort: "asc",
      width: "850px",
    },

    {
      label: "Transaction Time",
      field: "txnTime",
      sort: "asc",
      width: "auto",
    },
    {
      label: "Amount",
      field: "amount",
      sort: "asc",
      width: "auto",
    },
    {
      label: "UPI ID",
      field: "upiId",
      sort: "asc",
      width: "auto",
    },
    {
      label: " UTR No",
      field: "custRefNo",
      sort: "asc",
      width: "auto",
    },

    {
      label: "Remark",
      field: "remark",
      sort: "asc",
      width: "auto",
    },
  ];
  const datas = {
    columns: columnForViewTxnStatus,
    rows: data1.responseData,
    //  .map( (entry)=>{
    //   return {
    //     customerName: entry?.customerName || "NA",
    //   actualStatus: entry?.actualStatus || "NA",
    //   primaryStatus: entry?.primaryStatus || "NA",
    //   txnTime: entry?.txnTime || "NA",
    //   amount: entry?.amount || "NA",
    //   upiId: entry?.upiId || "NA",
    //   custRefNo: entry?.custRefNo || "NA",
    //   remark: entry?.remark || "NA",
    //   extTransactionId:entry?.extTransactionId || "NA",
    //   }
    // })
  };

  const getData1 = async (ordNo) => {
    const tokenValue = localStorage.getItem("token");
    const token = tokenValue ? tokenValue.slice(1, -1) : null;
    const decodedToken = jwtDecode(token);
    const { mid } = decodedToken;
    console.log(mid);
    // console.log(searchValue + " vaus is");
    const Payload = {
      mid: mid,
      orderNumber: ordNo,
      rrn: "",
    };

    console.log(Payload);
    try {
      setShowLoader(true);
      const response = await axios.post(FIND_BY_ORDER_NO, Payload, {
        headers: {
          Authentication: "Bearer " + token,
        },
      });
      setShowLoader(false);
      setData1(response?.data);
      console.log(" data is", response?.data);
    } catch (error) {
      // setError1(true);
      console.error(error);
    }
  };

  var data3 = [];

  const handleSearch = (ordNo) => {
    getData1(ordNo);
    data3 = [data1];
  };
console.log(boxApiResponse)
  return (
    <div id="mdb-datatable-container">
      <div className="dasboard-view-count-boxes">
        <Card.Body>
          <Row>
            { boxApiResponse?.length ? boxApiResponse.map((entry, index) => (
              <Col className="mt-2" xs={10} lg={1} md={1} key={index}>
                <DailyTransactionBox data={entry} />
              </Col>
            )) : liveTrackingReport.map((entry, index) => (
              <Col className="mt-2" xs={10} lg={1} md={1} key={index}>
                <DailyTransactionBox data={entry} />
              </Col>
            ))}
          </Row>
        </Card.Body>
      </div>

      <div className="mt-5 text-danger d-flex justify-content-evenly">
        <div>
          <span>U03 - Above Allowed Limit</span>
          <br />
          <span>U16 - Daily Limit Of Exceeded</span>
          <br />
          <span>U28 - Unable To Connect Sender Application </span>
          <br />
        </div>
        <div>
          <span>U30 - Unable To Debit</span>
          <br />
          <span>U31 - Denied By Reciever</span>
          <br />
          <span>U67 - Debit Timeout</span>
          <br />
        </div>
        <div>
          <span>U90 - Txn Deemed</span>
          <br />
          <span>U91 - Sender Bank Rejected</span>
          <br />
          <span>E05 - User Left Txn Incomplete</span>
          <br />
        </div>
      </div>

      <div className="mt-2">
        <MDBDataTable
          className=" dataTable overflow-x-scroll"
          data={data2}
          footer="none"
          striped
          bordered
          entries={50}
          exportToCSV
          responsive
          theadColor="#333"
          // small
        />

        <div className="d-flex align-items-center justify-content-between mt-3">
          <button
            className="advanced-btn btn btn-primary text-capitlize mt-2"
            onClick={handleDownloadExcel}
          >
            <FiDownload className="d-none" />
            <i class="bi bi-arrow-down-circle"></i> Download CSV
          </button>

          <div className="d-flex align-items-center justify-content-between w-25 gap-4 border boeder-1 p-2 ">
            <lable className="fs-4 w-50">Total Amount</lable>
            <span className="fs-4 fw-bold w-50 border boeder-2 rounded-2 p-1">
              {totalAmountForSuccess}
            </span>
          </div>
        </div>
      </div>

      {/* <MDBDataTable className=' dataTable overflow-x-scroll'
        footer='none'
        data={data2}
        striped
        bordered
        entries={50}
        exportToCSV
        responsive
        theadColor="#333" */}
      {/* // small
      /> */}
      {/* <button
        className="advanced-btn btn btn-primary text-capitlize mt-2"
        onClick={handleDownloadExcel}
      >
        <FiDownload className="d-none" />
        <i class="bi bi-arrow-down-circle"></i> Download CSV
      </button> */}

      <Modal
        size="lg"
        show={viewResponseModal}
        onHide={toggleViewResponseModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Response:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="admin-view-details">
            <Card className="shadow border-0 mb-4 ds-header-card">
              <Card.Body>
                <Row>
                  {/* <Col xs={12} xl={12} lg={12} sm={12}>
                    <Card.Title className="h3 mb-4">
                      Submitted Applications Details
                    </Card.Title>
                  </Col> */}
                  <Col xs={12} xl={12} lg={12} sm={12}>
                    <div className="two-column-layout">
                      <div className="vertical-table">
                        {/* <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">Amount</div>
                      <div className="vertical-table-cell-value text-left">{records.amount}</div>
                  </div>
                  <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">Amount</div>
                      <div className="vertical-table-cell-value text-left">{records.amount}</div>
                  </div> */}
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell">
                            Customer Name
                          </div>
                          <div className="vertical-table-cell-value">
                            {viewResponseRecords.customerName ||
                              recordsData?.customerName}
                          </div>
                        </div>
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell">Error code</div>
                          <div className="vertical-table-cell-value">
                            {viewResponseRecords.errCode}
                          </div>
                        </div>
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Response time
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.responseTime ||
                              recordsData?.requestTime}
                          </div>
                        </div>
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Amount
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.amount || recordsData?.amount}
                          </div>
                        </div>
                        {/* <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">checksum</div>
                      <div className="vertical-table-cell-value text-left">{records.checksum || "NA"}</div>
                  </div> */}
                        {/* <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">custRefNo</div>
                      <div className="vertical-table-cell-value text-left">{records.custRefNo || "NA"}</div>
                  </div> */}
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Transaction ID
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.txnId}
                          </div>
                        </div>
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Customer VPA
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.customer_vpa}
                          </div>
                        </div>

                        {/* <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">extTransactionId</div>
                      <div className="vertical-table-cell-value text-left">{records.extTransactionId || "NA"}</div>
                  </div> */}
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            RRN Number
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.rrn || recordsData?.custRefNo}
                          </div>
                        </div>
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Payee VPA
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.payeeVpa ||
                              recordsData?.payeeVpa}
                          </div>
                        </div>
                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Status
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.status ||
                              recordsData.respMessge}
                          </div>
                        </div>

                        {/* <div className="vertical-table-row">
                      <div className="vertical-table-cell text-left">Transaction Id</div>
                      <div className="vertical-table-cell-value text-left">{records.txnId }</div>
                  </div> */}

                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Request time
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {recordsData?.requestTime}
                          </div>
                        </div>

                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Terminal ID
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.terminalId ||
                              recordsData?.terminalId}
                          </div>
                        </div>

                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            UPI ID{" "}
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {recordsData?.upiId}
                          </div>
                        </div>

                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            {" "}
                            UPI Transaction ID{" "}
                          </div>
                          <div className="vertical-table-cell-value text-left w-auto">
                            {recordsData?.upiTxnId}
                          </div>
                        </div>

                        <div className="vertical-table-row">
                          <div className="vertical-table-cell text-left">
                            Response Code{" "}
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {viewResponseRecords.respCode ||
                              recordsData?.respCode}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </section>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={viewTxnStatusModal}
        onHide={toggleViewTxnStatusModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Transaction Status:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
            <div className="cart-view-sec mt-4">
              <Col xs={12} lg={12} md={12}>
                {/* Tab content start here */}
                <Card className="shadow-cst border-0 mb-3 chart-boxes merchant-info-form-sec">
                  <Card.Body>
                    <Col>
                      {data1?.statusCode === 200 ? (
                        <MDBDataTable
                          className=""
                          data={datas}
                          footer="none"
                          striped
                          bordered
                          exportToCSV
                          responsive
                          theadColor="#333"
                          small
                        />
                      ) : (
                        <div className="no-data-message fs-5 w-100 bg-secondary fw-semibold rounded-3 px-5 pl-5 p-2 d-flex align-items-center justify-content-center">
                          {" "}
                          No data available.{" "}
                        </div>
                      )}
                    </Col>
                  </Card.Body>
                </Card>
                {/* Tab content end here */}
              </Col>
              {/* ./col */}
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MBDReportSorting;
