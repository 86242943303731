import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const InstantWebCallback = () => {

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    }

  const htmlContent = `<p class="MsoTitle text-primary">Instant Web Callback API<span style="letter-spacing:-.1pt"> Document</span></p>

    <p class="MsoBodyText" style="margin-top:19.5pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt"><b style="mso-bidi-font-weight:normal"><span style="font-size:18.0pt;mso-bidi-font-size:11.0pt"><o:p>&nbsp;</o:p></span></b></p>
    
    <p class="MsoNormal" style="margin-top:0cm;margin-right:2.5pt;margin-bottom:0cm;
    margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%"><b style="mso-bidi-font-weight:
    normal"><span style="font-size:12.0pt;mso-bidi-font-size:11.0pt;line-height:
    107%">Target URL/API:</span></b><span style="font-size:12.0pt;mso-bidi-font-size:
    11.0pt;line-height:107%"> The return URL should be shared by the merchant, and on this URL,the
    merchant will receive the callback response. Once we receive the response from
    the payment server, we will send it to the merchant.<o:p></o:p></span></p>
    
    <p class="MsoNormal" style="margin-top:14.35pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.0pt;margin-bottom:.0001pt"><b style="mso-bidi-font-weight:
    normal"><span style="font-size:12.0pt;mso-bidi-font-size:11.0pt">Response <span style="letter-spacing:-.1pt"> Json:</span><o:p></o:p></span></b></p>
    
    <p class="MsoNormal" style="margin-top:1.15pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.0pt;margin-bottom:.0001pt"><span style="letter-spacing:-.5pt">[</span></p>
    
    <p class="MsoBodyText" style="margin-top:1.1pt;margin-right:5.45pt;margin-bottom:
    0cm;margin-left:5.0pt;margin-bottom:.0001pt;text-align:justify;line-height:
    107%"><span style="letter-spacing:-.1pt">data=Bon6NhrN0qHKCtiBf1G4Vhd3agrDW8VTbXYDoe9mNbiZ+6wTv9B13aiU90N3GWW39A/bJH5VuBsw70HQ0mSkA6Iar88rEqUjrWpXNl0uiKKSlYkpTYpOi08RZ82z847BDIscNFMmULvWyPuiPI+qhk3uifSf59fu1Jr9lWCsT1WtfI3KKDs9R2m/sWvULcv8n9NB1DO94kk5P2ZbIF5ONXE+1Qce7Y+A2LRun9qUNnsrhw8c5kueP3D0a0rk2Lu5JEB2X+y/ARE01xkDhYyxzSPmi9kFgrhdELiQ4xaCqn8OIu0YwfvOV4Q7vLXdPDLV3GSyJ1ts/BRvzP/L/uBjWQ==,</span></p>
    
    <p class="MsoBodyText" style="margin-top:0cm"><span style="letter-spacing:-.1pt">mid=SWIPEPROD100007,</span></p>
    
    <p class="MsoBodyText"><span style="letter-spacing:-.1pt">signature=782923988</span></p>
    
    <p class="MsoNormal" style="margin-top:.95pt;margin-right:0cm;margin-bottom:0cm;
    margin-left:5.0pt;margin-bottom:.0001pt"><span style="letter-spacing:-.5pt">]</span></p>
    
    <p class="MsoBodyText" style="margin-top:2.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt"><o:p>&nbsp;</o:p></p>
    
    <p class="MsoBodyText" style="margin-top:0cm;margin-right:2.5pt;margin-bottom:
    0cm;margin-left:5.0pt;margin-bottom:.0001pt;line-height:107%"><b style="mso-bidi-font-weight:normal">Data:</b> It’s encrypted value, for check the response please decrypt it by using your encryption key and
    same algorithm which you using during sent request to payment server.</p>
    
    <p class="MsoBodyText" style="margin-top:1.0pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt"><o:p>&nbsp;</o:p></p>
    
    <p class="MsoNormal" style="margin-left:5.0pt"><b style="mso-bidi-font-weight:
    normal">After decryption:</b> After decryption response data will<span style="letter-spacing:-.25pt"> be-</span></p>
    
    <p class="MsoNormal" style="margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
    margin-left:5.0pt;margin-bottom:.0001pt"><span style="letter-spacing:-.5pt">{</span></p>
    
    <p class="MsoBodyText">"amount":<span style="letter-spacing:-.1pt">"100",</span></p>
    
    <p class="MsoBodyText" style="margin-top:1.0pt">"orderNo":<span style="letter-spacing:-.1pt">"ORD23072214392200916",</span></p>
    
    <p class="MsoBodyText" style="margin-right:319.4pt;line-height:107%">"txnStatus":
    "success", "txnRespCode":
    "200","mid":"SWIPEPROD100007",</p>
    
    <p class="MsoBodyText" style="margin-top:0cm;line-height:13.35pt;mso-line-height-rule:
    exactly">"currency":<span style="letter-spacing:-.1pt">"INR",</span></p>
    
    <p class="MsoBodyText">"txnDate":"2023-07-22<span style="letter-spacing:-.1pt">14:39:20.796",</span></p>
    
    <p class="MsoBodyText" style="margin-top:1.05pt">"customerIfsc":<span style="letter-spacing:-.1pt">"none",</span></p>
    
     
  <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
  class=SpellE><span class=GramE>userVpa</span></span>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;"XXXXXXXXXX@ybl&quot;,</span><o:p></o:p></span></p>

  <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
  class=SpellE><span class=GramE>custRefNo</span></span>&quot;:<span
  style='letter-spacing:-.1pt'>&quot;"XXXXXXXXXX&quot;,</span><o:p></o:p></span></p>

    <p class="MsoBodyText">"txnId":<span style="letter-spacing:-.1pt">"47209036589869018322",</span></p>
    
    <p class="MsoBodyText" style="margin-top:1.0pt;margin-right:319.4pt;margin-bottom:
    0cm;margin-left:10.05pt;margin-bottom:.0001pt;line-height:107%">"paymentType":"UPI"&quot,

    <span style="letter-spacing:-.1pt">"udf3":"",</span></p>
    
    <p class="MsoBodyText" style="margin-top:0cm"><span style="letter-spacing:-.1pt">"udf4":"",</span></p>
    
    <p class="MsoBodyText"><span style="letter-spacing:-.1pt">"udf1":"",</span></p>
    
    <p class="MsoBodyText" style="margin-top:1.0pt"><span style="letter-spacing:-.1pt">"udf2":""</span></p>
    
    <p class="MsoNormal" style="margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
    margin-left:5.0pt;margin-bottom:.0001pt"><span style="letter-spacing:-.5pt">}</span></p>
    
    <p class="MsoBodyText" style="margin-top:2.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt"><o:p>&nbsp;</o:p></p>
 

 
 <br />
 <p class=MsoNormal style='margin-left:5.0pt'><span class=SpellE><b
 style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>In case of WARNING <span
 class=GramE>:<span style='font-weight:normal'><span
 style='letter-spacing:-.25pt'> </span></span></span></span></b></span><span
 style='font-size:13.0pt;letter-spacing:-.25pt'></span><span style='font-size:
 13.0pt'><o:p></o:p></span></p>
 
 
 <p class=MsoNormal style='margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
 margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
 letter-spacing:-.5pt'>{</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=GramE>amount</span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;100&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.0pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>orderNo</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;ORD23072214392200916&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-right:319.4pt;line-height:107%'><span
 style='font-size:13.0pt;line-height:107%'>&quot;<span class=SpellE><span
 class=GramE>txnStatus</span></span>&quot;: &quot;warning&quot;, &quot;<span
 class=SpellE>txnRespCode</span>&quot;:
 &quot;1003&quot;,&quot;mid&quot;:&quot;SWIPEPROD100007&quot;,<o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:0cm;line-height:13.35pt;mso-line-height-rule:
 exactly'><span style='font-size:13.0pt'>&quot;<span class=GramE>currency</span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;INR&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=SpellE><span
 class=GramE>txnDate</span></span>&quot;:&quot;2023-07-22<span style='letter-spacing:
 -.1pt'>14:39:20.796&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>customerIfsc</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;none&quot;,</span><o:p></o:p></span></p>

 <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>userVpa</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;"XXXXXXXXXX@ybl&quot;,</span><o:p></o:p></span></p>

 <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>custRefNo</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;"XXXXXXXXXX&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=SpellE><span
 class=GramE>txnId</span></span>&quot;:<span style='letter-spacing:-.1pt'>&quot;47209036589869018322&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.0pt;margin-right:319.4pt;margin-bottom:
 0cm;margin-left:10.05pt;margin-bottom:.0001pt;line-height:107%'><span
 style='font-size:13.0pt;line-height:107%'>&quot;<span class=SpellE>paymentType</span>&quot;:&quot;UPI&quot;,
 <span style='letter-spacing:-.1pt'>&quot;udf3&quot;:&quot;&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:0cm'><span style='font-size:13.0pt;
 letter-spacing:-.1pt'>&quot;udf4&quot;:&quot;&quot;,</span><span
 style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt;letter-spacing:-.1pt'>&quot;udf1&quot;:&quot;&quot;,</span><span
 style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.0pt'><span style='font-size:13.0pt;
 letter-spacing:-.1pt'>&quot;udf2&quot;:&quot;&quot;</span><span
 style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoNormal style='margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
 margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
 letter-spacing:-.5pt'>}</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
 

 <br />
 <p class=MsoNormal style='margin-left:5.0pt'><span class=SpellE><b
 style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>In case of BLACKLIST <span
 class=GramE>:<span style='font-weight:normal'><span
 style='letter-spacing:-.25pt'> </span></span></span></span></b></span><span
 style='font-size:13.0pt;letter-spacing:-.25pt'></span><span style='font-size:
 13.0pt'><o:p></o:p></span></p>
 
 
 <p class=MsoNormal style='margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
 margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
 letter-spacing:-.5pt'>{</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=GramE>amount</span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;100&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.0pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>orderNo</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;ORD23072214392200916&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-right:319.4pt;line-height:107%'><span
 style='font-size:13.0pt;line-height:107%'>&quot;<span class=SpellE><span
 class=GramE>txnStatus</span></span>&quot;: &quot;blacklist&quot;, &quot;<span
 class=SpellE>txnRespCode</span>&quot;:
 &quot;1002&quot;,&quot;mid&quot;:&quot;SWIPEPROD100007&quot;,<o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:0cm;line-height:13.35pt;mso-line-height-rule:
 exactly'><span style='font-size:13.0pt'>&quot;<span class=GramE>currency</span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;INR&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=SpellE><span
 class=GramE>txnDate</span></span>&quot;:&quot;2023-07-22<span style='letter-spacing:
 -.1pt'>14:39:20.796&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>customerIfsc</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;none&quot;,</span><o:p></o:p></span></p>

 <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>userVpa</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;"XXXXXXXXXX@ybl&quot;,</span><o:p></o:p></span></p>

 <p class=MsoBodyText style='margin-top:1.05pt'><span style='font-size:13.0pt'>&quot;<span
 class=SpellE><span class=GramE>custRefNo</span></span>&quot;:<span
 style='letter-spacing:-.1pt'>&quot;"XXXXXXXXXX&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt'>&quot;<span class=SpellE><span
 class=GramE>txnId</span></span>&quot;:<span style='letter-spacing:-.1pt'>&quot;47209036589869018322&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.0pt;margin-right:319.4pt;margin-bottom:
 0cm;margin-left:10.05pt;margin-bottom:.0001pt;line-height:107%'><span
 style='font-size:13.0pt;line-height:107%'>&quot;<span class=SpellE>paymentType</span>&quot;:&quot;UPI&quot;,
 <span style='letter-spacing:-.1pt'>&quot;udf3&quot;:&quot;&quot;,</span><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:0cm'><span style='font-size:13.0pt;
 letter-spacing:-.1pt'>&quot;udf4&quot;:&quot;&quot;,</span><span
 style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoBodyText><span style='font-size:13.0pt;letter-spacing:-.1pt'>&quot;udf1&quot;:&quot;&quot;,</span><span
 style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoBodyText style='margin-top:1.0pt'><span style='font-size:13.0pt;
 letter-spacing:-.1pt'>&quot;udf2&quot;:&quot;&quot;</span><span
 style='font-size:13.0pt'><o:p></o:p></span></p>
 
 <p class=MsoNormal style='margin-top:1.1pt;margin-right:0cm;margin-bottom:0cm;
 margin-left:5.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
 letter-spacing:-.5pt'>}</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
 
<br />
    
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>Expected Response Json from merchant (Without Any Warning Message)<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>“status&quot;:&quot;205&quot;,<o:p></o:p></span></p>
  

  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>

  <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    

  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>Expected Fraud Acknowledgement Response Json from merchant If Payment Accepted By Merchant<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>“status&quot;:&quot;206&quot;,<o:p></o:p></span></p>
  
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>

  <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    

  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>Expected Fraud Acknowledgement Response Json from merchant If Payment Rejected By Merchant<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>“status&quot;:&quot;207&quot;,<o:p></o:p></span></p>
  
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>

  <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    

  

    <p class="MsoBodyText" style="margin-top:0cm;margin-right:2.5pt;margin-bottom:
    0cm;margin-left:5.0pt;margin-bottom:.0001pt;line-height:106%"><b style="mso-bidi-font-weight:normal"> Signature/Checksum:</b>Signature is check sum value that is generate to check the data integrity. For
    generate checksum/signature please use response field at given sequence-</p>
    
    <p class="MsoBodyText" style="margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.0pt;margin-bottom:.0001pt">Mid+txnId+orderNo+<span style="letter-spacing:-.1pt"> txnStatus</span></p>`;

  return (
    <>
      <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <div className="d-flex justify-content-end">
                <button className="btn btn-success mb-3" onClick={goBack}>&#8592; Back</button>
              </div>

              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    <div
                      className="merchant-tab-btn  p-3  "
                      dangerouslySetInnerHTML={{ __html: htmlContent }}
                    ></div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success mb-3" onClick={goBack}>&#8592; Back</button>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default InstantWebCallback;
