import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { SWIPELINC_API } from "../../utils/constant";
import jwtDecode from "jwt-decode";
import swal from "sweetalert";
import { getKeyPressed, isEmailIdRegexIsValid } from "../../utils/validations";
import InactiveService2 from "../../common/inactiveService2";

const LinkBasedPayment = () => {
  // const [activeButton, setactiveButton] = useState(1);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [transferType, setTransferType] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  // const [virtualAccountType, setVirtualAccountType] = useState("");
  // const [mobileNum, setMobileNum] = useState(false);
  // const [email, setEmail] = useState(false);
  const [showInputs, setShowInputs] = useState({ mobile: false, email: false });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [formDataInputs, setFormDataInputs] = useState({
    mobileNum: "",
    email: "",
  });

  // const handleToolsButtonClick = (buttonNumber) => {
  //     setactiveButton(buttonNumber);
  // };

  // useEffect(() => {
  //     if (activeButton === 1) {
  //         setVirtualAccountType("UPI");
  //         setAmount("");
  //     } else if (activeButton === 2) {
  //         setVirtualAccountType("IMPS");
  //         setAmount("");
  //     } else {
  //         setVirtualAccountType("");
  //     }
  // }, [activeButton]);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const Type= localStorage.getItem("typeOfServices")
  const paymentType= JSON.parse(Type)

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setFormDataInputs({ ...formDataInputs, [name]: value });
  };
  const Payload = {
    amount: amount,
    email: formDataInputs.email,
    mid: mid,
    mobile: parseInt(formDataInputs.mobileNum),
  };

  const handleAmount = (e) => {
    const value = e.target.value;
    const numberPattern = /^[1-9]\d*$/;

    if (numberPattern.test(value)) {
      setAmount(value);
      setError("");
    } else {
      setAmount("");
      setError("Withdraw Amount must be a positive number.");
    }
  };

  const handleSubmit = async () => {
    if(transferType.includes("email")){

        if (!isEmailIdRegexIsValid(formDataInputs.email)) {
          swal({
            title: "Alert!",
            text: "Please Enter Valid Merchant E-Mail ID !",
            icon: "error",
          }).then(() => {
            setFormDataInputs({ ...formDataInputs });
          });
          return;
        }
    }

    if (amount.length === 0) {
      swal({
        title: "Alert",
        text: "Please Enter Amount",
        icon: "warning",
      });
      return;
    }

    swal({
      title: "Are you sure?",
      text: "Do you want to submit this  request?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willSubmit) => {
      if (willSubmit) {
        try {
          const response = await axios.post(
            `${SWIPELINC_API}MerchantPanel/merchant/link/save`,
            Payload,
            {
              headers: {
                Authentication: "Bearer " + token,
              },
            }
          );

          if (response.data.statusCode === 309) {
            swal({
              title: "Alert",
              text: response.data.message,
              icon: "error",
            });
            setAmount("");
            setFormDataInputs({
              mobileNum: "",
              email: "",
            });
            setTransferType("");
            return;
          }

          if (response.data.statusCode === 200) {
            swal({
              title: "Success",
              text: "Your  request has been submitted successfully.",
              icon: "success",
            });
            setGeneratedLink(response?.data?.data);
            setAmount("");
            setFormDataInputs({
              mobileNum: "",
              email: "",
            });
            setTransferType("");
          } else {
            swal({
              title: "Failed",
              text: "Your  request has been declined.",
              icon: "warning",
            });
          }
        } catch (error) {
          setError(true);
          swal({
            title: "Failed",
            text: "Something went wrong",
            icon: "error",
          });
        } finally {
          setIsSubmitting(false);
        }
      } else {
        swal("Submission canceled!");
      }
    });
  };

  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.email)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
  };
  return (
    <>
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          {paymentType.includes("DPC") ? ( 
            <div className="ds-header-sec">
              <Row>
                <Col xs={12} lg={12} md={12}>
                  <Card.Body>
                    <div className="filter-field-area">
                      <div className="merchant-form-info d-flex justify-content-center">
                        <Col xs={12} lg={4} md={6}>
                          <div className="form-group">
                            <label
                              htmlFor="companyPanNumber"
                              className="form-label"
                            >
                              Enter Amount
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="input-field-validate">
                              <input
                                type="number"
                                value={amount}
                                placeholder="Enter Amount"
                                onChange={handleAmount}
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <label
                              htmlFor="wayOfCommunication"
                              className="form-label"
                            >
                              Way Of Communication
                              <sup className="text-danger">*</sup>
                            </label>
                            <div className="input-field-validate my-4">
                              <div className="checkbox-group">
                                <input
                                  type="checkbox"
                                  id="whatsappCheckbox"
                                  checked={transferType.includes("whatsapp")}
                                  onChange={() =>
                                    setTransferType((prevTypes) =>
                                      prevTypes.includes("whatsapp")
                                        ? prevTypes.filter(
                                            (type) => type !== "whatsapp"
                                          )
                                        : [...prevTypes, "whatsapp"]
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <label
                                  htmlFor="whatsappCheckbox"
                                  style={{
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  WhatsApp
                                </label>
                              </div>
                              <div className="checkbox-group">
                                <input
                                  type="checkbox"
                                  id="emailCheckbox"
                                  checked={transferType.includes("email")}
                                  onChange={() =>
                                    setTransferType((prevTypes) =>
                                      prevTypes.includes("email")
                                        ? prevTypes.filter(
                                            (type) => type !== "email"
                                          )
                                        : [...prevTypes, "email"]
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <label
                                  htmlFor="emailCheckbox"
                                  style={{
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  Email
                                </label>
                              </div>
                              <div className="checkbox-group">
                                <input
                                  type="checkbox"
                                  id="smsCheckbox"
                                  checked={transferType.includes("sms")}
                                  onChange={() =>
                                    setTransferType((prevTypes) =>
                                      prevTypes.includes("sms")
                                        ? prevTypes.filter(
                                            (type) => type !== "sms"
                                          )
                                        : [...prevTypes, "sms"]
                                    )
                                  }
                                  style={{ width: "20px", height: "20px" }}
                                />
                                <label
                                  htmlFor="smsCheckbox"
                                  style={{
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  SMS
                                </label>
                              </div>
                            </div>
                          </div>

                          {transferType.includes("whatsapp") ||
                          transferType.includes("sms") ? (
                            <div className="form-group">
                              <label
                                htmlFor="mobileNumber"
                                className="form-label"
                              >
                                Enter Mobile Number
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                maxLength={10}
                                minLength={10}
                                name="mobileNum"
                                className="form-control"
                                placeholder="Enter Mobile Number"
                                value={formDataInputs.mobileNum}
                                onChange={handleformDataInputs}
                                pattern="[0-9]{10}"
                                title="Please enter a 10-digit mobile number"
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : null}

                          {transferType.includes("email") ? (
                            <div className="form-group">
                              <label
                                htmlFor="emailAddress"
                                className="form-label"
                              >
                                Enter Email Address
                                <sup className="text-danger">*</sup>
                              </label>
                              <input
                                type="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter Email Address"
                                value={formDataInputs.email}
                                onChange={handleformDataInputs}
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onBlur={handleValidations}
                                style={{ width: "100%" }}
                              />
                            </div>
                          ) : null}

                          <div className="login-btn-sec d-flex justify-content-end">
                            <button
                              id="companyPanVerifyBtn"
                              type="button"
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              Proceed <i className="bi bi-arrow-right"></i>
                            </button>
                          </div>
                          {generatedLink && (
                            <div className="form-group mt-3">
                              <h5>Generated Link</h5>
                              <a
                                href={generatedLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {generatedLink}
                              </a>
                            </div>
                          )}
                        </Col>
                      </div>
                    </div>
                  </Card.Body>
                </Col>
              </Row>
            </div>
          ) : (
            <InactiveService2 />
          )}
        </Container>
      </section>
    </>
  );
};
export default LinkBasedPayment;
