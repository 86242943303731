import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { columnForFRMSubmittedUser } from "../../../utils/constant";
import jwtDecode from "jwt-decode";
import { fetchAllFRMUsers } from "../../../services/apiService";

const SubmittedUser = () => {
  const [userData, setUserData] = useState([]);
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const data = {
    columns: columnForFRMSubmittedUser,
    rows: userData,
  };

  const getAllFRMUsers = async () => {
    let res = await fetchAllFRMUsers(mid, token)
    console.log(res);
    if(res.statusCode === 200)
    {
        setUserData(res?.data)
    }
  }


  useEffect(() => {
    getAllFRMUsers()
  }, [])

  return (
    <section className="w-100 lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3">
      {/* {showLoader ? <MyLoader /> : ""} */}
      <div className="containerflag">
        <div className="row mt-4 mx-2">
          <MDBDataTable
            className="dataTable"
            striped
            bordered
            small
            data={data}
            searching={true}
            responsive
            sorting={true}
            hover
            footer="none"
          />
        </div>
      </div>
    </section>
  );
};

export default SubmittedUser;
