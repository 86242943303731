import React, { useEffect, useState } from "react";
import { Card, Container, Modal, Button, Row, Col } from "react-bootstrap";
import jwtDecode from "jwt-decode";
import Filter from "./Filter";

import axios from "axios";

import { MDBDataTable } from "mdbreact";
import CheckExportConst from "./UserManagement/CheckexportConst";
function Payout({setReportType, setPayoutClose, setOpenPopUp, setApiResponse, apiResponse }) {
  // console.log(setApiResponse ,'hgjgj')
  const [show, setShow] = useState(true);
  const [active, setActive] = useState("");
  const [activeButton, setActiveButton] = useState();
  // const [apiResponse, setApiResponse] = useState([])

  //   const [searchOption, setSearchOption] = useState("");
  //   const [searchValue, setSearchValue] = useState("");

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : "";
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;
  //   const [rowsPerPage, setRowsPerPage] = useState(50);

  //  console.log(apiResponse)

  const handleToolsButtonClick = (type, buttonNumber) => {
    setActive(type);
    setActiveButton(buttonNumber);
    setReportType(type)
  };

  const {
    PayoutReqPrivilages , hasPrivilagesPTVendor,hasPrivilagesPTCustomer, hasPrivilagesViewPayoutReq , hasPrivilagesPayoutTxnStatus,
  } = CheckExportConst();

  return (
    <>
      <div>
        <div >
          <Col>
            <Card.Body>
              <div className="merchant-tab-btn">
                <ul className="report-link nav-report-btn">
                {hasPrivilagesPTCustomer?  <li
                    className={activeButton === 1 ? "active" : ""}
                    onClick={() => handleToolsButtonClick("Customer", 1)}
                  >
                    Customer
                  </li> : null}
                  
                  { hasPrivilagesPTVendor?   <li
                    className={activeButton === 2 ? "active" : ""}
                    onClick={() => handleToolsButtonClick("Vendor", 2)}
                  >
                    Vendor
                  </li> : null}
                 
                </ul>
              </div>
              <hr />
            </Card.Body>
          </Col>
          <Col xs={12} lg={12} md={12}>
            <Card className="shadow border-0 mb-4">
              <Filter
                active={active}
                setShow={setShow}
                setPayoutClose={setPayoutClose}
                setOpenPopUp={setOpenPopUp}
                setApiResponse={setApiResponse}
                apiResponse={apiResponse}
              />
            </Card>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Payout;