import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getAllFunctionForApiCalls } from "../../../services/apiService";
import {
  GET_ALL_PRIVILAGES,
  GET_ALL_PRIVILAGES_BY_ID,
  GET_ALL_USER,
  GET_USER_DATA,
} from "../../../utils/constant";

const ViewSubmittedUser = ({ handleClose, data, userId }) => {
  console.log("hjkl-------------------", data, userId);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [privilagesData, setPrivilagesData] = useState();
  const [privilages, setPrivilages] = useState([]);
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const tokenproxy = localStorage.getItem("token");
  const token = tokenproxy.slice(1, -1);

  const [formDataInputs, setFormDataInputs] = useState({
    Name: "",
    //   masterMerchantName: "",
    password: "",
    MobileNumber: "",
    //   Prifix: "",
    emailId: "",
  });

  useEffect(() => {
    setFormDataInputs({
      Name: data.fullName,
      // masterMerchantName: data.masterMerchantId,
      password: data.password,
      MobileNumber: data.mobile,
      // Prifix: data.midprifix,
      emailId: data.email,
    });
  }, [data]);

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCalls(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        if (response.data) {
          checkHeadPri(response.data);
        }
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  const getUserData = async () => {
    try {
      const response = await getAllFunctionForApiCalls(
        GET_ALL_USER + "/" + userId,
        token
      );
      if (response.statusCode === 200) {
        setUserData(response?.data?.subpriveledgeprivilege);
        if(response?.data?.subpriveledgeprivilege) {
            const data = response?.data?.subpriveledgeprivilege?.map(
              (item) => item.privilegeSubTypeId
            );
            setPrivilages(data);
            console.log(data);
          }
      } else {
        setUserData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        await getUserData();
        
        await getAllPrivilages();

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
 

  const checkHeadPri = (privilagesData) => {
    const updatedHeadPrivilages = [];

    privilagesData?.forEach((item1, index) => {
      const data = item1.subTypes.map((item) =>
        privilages.includes(item.privilegeSubTypeId)
      );

      if (!data.includes(false)) {
        setHeadPrivilages((prev) => {
          return [...prev, item1.privilegeId];
        });
      }
    });
  };

  useEffect(() => {
    checkHeadPri(privilagesData);
  }, [privilagesData]);

  useEffect(() => {
    console.log(HeadPrivilages);
  }, [HeadPrivilages]);

  return (
    <>
      {loading ? null : (
        <>
          <Card.Header className="d-flex justify-content-between">
            <Card.Title>User Details</Card.Title>
            <Button onClick={handleClose}>Close</Button>
          </Card.Header>
          <Card className="shadow border-0 mb-4">
            <Card.Body>
              <div className="merchant-report-view">
                <div className="report-table-view">
                  <div>
                    <Row className="px-4">
                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            User Name
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="Name"
                              readOnly
                              value={formDataInputs.Name}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            e-Mail Id
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              type="email"
                              className="form-control"
                              name="emailId"
                              readOnly
                              value={formDataInputs.emailId}
                              // onChange={handleformDataInputs}
                              // onKeyDown={(e) =>
                              //   setPressedKeyName(getKeyPressed(e))
                              // }
                              // onBlur={handleValidations}
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label htmlFor="MobileNumber" className="form-label">
                            Merchant Mobile Number
                            <sup className="text-danger">*</sup>{" "}
                          </label>
                          <input
                            maxLength={10}
                            minLength={10}
                            name="MobileNumber"
                            value={formDataInputs.MobileNumber}
                            readOnly
                            required
                            pattern="[0-9]{10}"
                            title="Please enter a 10-digit mobile number"
                            className="form-control"
                          />
                        </div>
                      </Col>

                      <Col xs={12} lg={4} md={6} className="mt-5">
                        <div className="form-group">
                          <label className="form-label">
                            Password
                            <sup className="text-danger">*</sup>
                          </label>
                          <div className="d-flex gap-2">
                            <input
                              className="form-control"
                              name="password"
                              value={formDataInputs.password}
                              readOnly
                            />
                          </div>
                        </div>
                        {/* ./form-group */}
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">
                        Privileges
                      </lable>
                      <div className="d-flex flex-column gap-4">
                        {/* ... */}
                        {privilagesData &&
                          privilagesData.map((itemMain, i) => (
                            <div key={i} className="d-flex flex-column">
                              <Card.Header className="form-label mt-4 mb-3 fs-4 mx-3 gap-3">
                                <div className="s-lg form-check">
                                  {itemMain.privilegeName}
                                  <input
                                    id="flexSwitchCheckDefault"
                                    className="form-check-input fs-4"
                                    type="checkbox"
                                    readOnly
                                    name={itemMain.adminPrivilegeId}
                                    checked={HeadPrivilages.includes(
                                      itemMain.privilegeId
                                    )}
                                  />
                                </div>
                              </Card.Header>

                              <div className="d-flex flex-wrap">
                                {itemMain.subTypes.map((item, i) => (
                                  <div
                                    key={i}
                                    className="bg-light d-flex justify-content-center align-items-center gap-5 px-4 py-1 rounded rounded-5 mt-2 mx-2   form-group"
                                  >
                                    <label className="form-label mt-1 fs-4 mx-1">
                                      {item.privilegeSubTypeName}
                                    </label>
                                    <div className="s-lg form-check form-switch">
                                      <input
                                        id="flexSwitchCheckDefault"
                                        className="form-check-input fs-4"
                                        data-head={itemMain.privilegeId}
                                        type="checkbox"
                                        readOnly
                                        name={item.privilegeSubTypeId}
                                        checked={privilages.includes(
                                          item.privilegeSubTypeId  
                                        )}
                                      />
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          ))}
                        {/* ... */}
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default ViewSubmittedUser;
