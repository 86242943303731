import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const PayoutDocs = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const htmlContent = `<html xmlns:v="urn:schemas-microsoft-com:vml"
  xmlns:o="urn:schemas-microsoft-com:office:office"
  xmlns:w="urn:schemas-microsoft-com:office:word"
  xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882"
  xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
  xmlns="http://www.w3.org/TR/REC-html40">
  
  <head>
  <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
  <meta name=ProgId content=Word.Document>
  <meta name=Generator content="Microsoft Word 12">
  <meta name=Originator content="Microsoft Word 12">
  <link rel=File-List href="llll_files/filelist.xml">
  <link rel=Edit-Time-Data href="llll_files/editdata.mso">
  <!--[if !mso]>
  <style>
  v\:* {behavior:url(#default#VML);}
  o\:* {behavior:url(#default#VML);}
  w\:* {behavior:url(#default#VML);}
  .shape {behavior:url(#default#VML);}
  </style>
  <![endif]--><!--[if gte mso 9]><xml>
   <o:DocumentProperties>
    <o:Author>Admin</o:Author>
    <o:LastAuthor>lincpay</o:LastAuthor>
    <o:Revision>3</o:Revision>
    <o:TotalTime>12</o:TotalTime>
    <o:Created>2023-12-27T11:10:00Z</o:Created>
    <o:LastSaved>2023-12-27T11:12:00Z</o:LastSaved>
    <o:Pages>5</o:Pages>
    <o:Words>1186</o:Words>
    <o:Characters>6762</o:Characters>
    <o:Lines>56</o:Lines>
    <o:Paragraphs>15</o:Paragraphs>
    <o:CharactersWithSpaces>7933</o:CharactersWithSpaces>
    <o:Version>12.00</o:Version>
   </o:DocumentProperties>
   <o:CustomDocumentProperties>
    <o:Created dt:dt="date">2023-12-14</o:Created>
    <o:Creator dt:dt="string">Microsoft® Word 2016</o:Creator>
    <o:LastSaved dt:dt="date">2023-12-26</o:LastSaved>
   </o:CustomDocumentProperties>
  </xml><![endif]-->
  <link rel=themeData href="llll_files/themedata.thmx">
  <link rel=colorSchemeMapping href="llll_files/colorschememapping.xml">
  <!--[if gte mso 9]><xml>
   <w:WordDocument>
    <w:TrackMoves>false</w:TrackMoves>
    <w:TrackFormatting/>
    <w:PunctuationKerning/>
    <w:DrawingGridHorizontalSpacing>5.5 pt</w:DrawingGridHorizontalSpacing>
    <w:DisplayHorizontalDrawingGridEvery>2</w:DisplayHorizontalDrawingGridEvery>
    <w:ValidateAgainstSchemas/>
    <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
    <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
    <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
    <w:DoNotPromoteQF/>
    <w:LidThemeOther>EN-US</w:LidThemeOther>
    <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
    <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
    <w:Compatibility>
     <w:ULTrailSpace/>
     <w:ShapeLayoutLikeWW8/>
     <w:BreakWrappedTables/>
     <w:SnapToGridInCell/>
     <w:WrapTextWithPunct/>
     <w:UseAsianBreakRules/>
     <w:DontGrowAutofit/>
     <w:SplitPgBreakAndParaMark/>
     <w:DontVertAlignCellWithSp/>
     <w:DontBreakConstrainedForcedTables/>
     <w:DontVertAlignInTxbx/>
     <w:Word11KerningPairs/>
     <w:CachedColBalance/>
    </w:Compatibility>
    <w:DoNotOptimizeForBrowser/>
    <m:mathPr>
     <m:mathFont m:val="Cambria Math"/>
     <m:brkBin m:val="before"/>
     <m:brkBinSub m:val="--"/>
     <m:smallFrac m:val="off"/>
     <m:dispDef/>
     <m:lMargin m:val="0"/>
     <m:rMargin m:val="0"/>
     <m:defJc m:val="centerGroup"/>
     <m:wrapIndent m:val="1440"/>
     <m:intLim m:val="subSup"/>
     <m:naryLim m:val="undOvr"/>
    </m:mathPr></w:WordDocument>
  </xml><![endif]--><!--[if gte mso 9]><xml>
   <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="true"
    DefSemiHidden="true" DefQFormat="false" DefPriority="99"
    LatentStyleCount="267">
    <w:LsdException Locked="false" Priority="0" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Normal"/>
    <w:LsdException Locked="false" Priority="9" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="heading 1"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 2"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 3"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 4"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 5"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 6"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 7"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 8"/>
    <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 9"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 1"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 2"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 3"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 4"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 5"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 6"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 7"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 8"/>
    <w:LsdException Locked="false" Priority="39" Name="toc 9"/>
    <w:LsdException Locked="false" Priority="35" QFormat="true" Name="caption"/>
    <w:LsdException Locked="false" Priority="10" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Title"/>
    <w:LsdException Locked="false" Priority="1" Name="Default Paragraph Font"/>
    <w:LsdException Locked="false" Priority="1" QFormat="true" Name="Body Text"/>
    <w:LsdException Locked="false" Priority="11" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Subtitle"/>
    <w:LsdException Locked="false" Priority="22" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Strong"/>
    <w:LsdException Locked="false" Priority="20" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Emphasis"/>
    <w:LsdException Locked="false" Priority="59" SemiHidden="false"
     UnhideWhenUsed="false" Name="Table Grid"/>
    <w:LsdException Locked="false" UnhideWhenUsed="false" Name="Placeholder Text"/>
    <w:LsdException Locked="false" Priority="1" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="No Spacing"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 1"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 1"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 1"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 1"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 1"/>
    <w:LsdException Locked="false" UnhideWhenUsed="false" Name="Revision"/>
    <w:LsdException Locked="false" Priority="34" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="List Paragraph"/>
    <w:LsdException Locked="false" Priority="29" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Quote"/>
    <w:LsdException Locked="false" Priority="30" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Intense Quote"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 1"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 1"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 1"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 1"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 1"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 1"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 1"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 2"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 2"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 2"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 2"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 2"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 2"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 2"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 2"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 2"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 2"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 3"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 3"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 3"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 3"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 3"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 3"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 3"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 3"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 3"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 3"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 4"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 4"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 4"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 4"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 4"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 4"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 4"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 4"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 4"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 4"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 5"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 5"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 5"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 5"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 5"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 5"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 5"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 5"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 5"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 5"/>
    <w:LsdException Locked="false" Priority="60" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Shading Accent 6"/>
    <w:LsdException Locked="false" Priority="61" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light List Accent 6"/>
    <w:LsdException Locked="false" Priority="62" SemiHidden="false"
     UnhideWhenUsed="false" Name="Light Grid Accent 6"/>
    <w:LsdException Locked="false" Priority="63" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="64" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Shading 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="65" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="66" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium List 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="67" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 1 Accent 6"/>
    <w:LsdException Locked="false" Priority="68" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 2 Accent 6"/>
    <w:LsdException Locked="false" Priority="69" SemiHidden="false"
     UnhideWhenUsed="false" Name="Medium Grid 3 Accent 6"/>
    <w:LsdException Locked="false" Priority="70" SemiHidden="false"
     UnhideWhenUsed="false" Name="Dark List Accent 6"/>
    <w:LsdException Locked="false" Priority="71" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Shading Accent 6"/>
    <w:LsdException Locked="false" Priority="72" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful List Accent 6"/>
    <w:LsdException Locked="false" Priority="73" SemiHidden="false"
     UnhideWhenUsed="false" Name="Colorful Grid Accent 6"/>
    <w:LsdException Locked="false" Priority="19" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Subtle Emphasis"/>
    <w:LsdException Locked="false" Priority="21" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Intense Emphasis"/>
    <w:LsdException Locked="false" Priority="31" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Subtle Reference"/>
    <w:LsdException Locked="false" Priority="32" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Intense Reference"/>
    <w:LsdException Locked="false" Priority="33" SemiHidden="false"
     UnhideWhenUsed="false" QFormat="true" Name="Book Title"/>
    <w:LsdException Locked="false" Priority="37" Name="Bibliography"/>
    <w:LsdException Locked="false" Priority="39" QFormat="true" Name="TOC Heading"/>
   </w:LatentStyles>
  </xml><![endif]-->
  <style>
  <!--
   /* Font Definitions */
   @font-face
      {font-family:"Cambria Math";
      panose-1:2 4 5 3 5 4 6 3 2 4;
      mso-font-charset:0;
      mso-generic-font-family:roman;
      mso-font-pitch:variable;
      mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
  @font-face
      {font-family:Calibri;
      panose-1:2 15 5 2 2 2 4 3 2 4;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-469750017 -1073732485 9 0 511 0;}
  @font-face
      {font-family:Tahoma;
      panose-1:2 11 6 4 3 5 4 4 2 4;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-520081665 -1073717157 41 0 66047 0;}
  @font-face
      {font-family:"Lucida Sans Unicode";
      panose-1:2 11 6 2 3 5 4 2 2 4;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-2147480833 14699 0 0 191 0;}
  @font-face
      {font-family:"Segoe UI";
      panose-1:2 11 5 2 4 2 4 2 2 3;
      mso-font-charset:0;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:-469750017 -1073683329 9 0 511 0;}
  @font-face
      {font-family:"Arial MT";
      mso-font-alt:Arial;
      mso-font-charset:1;
      mso-generic-font-family:swiss;
      mso-font-pitch:variable;
      mso-font-signature:0 0 0 0 0 0;}
   /* Style Definitions */
   p.MsoNormal, li.MsoNormal, div.MsoNormal
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-parent:"";
      margin:0cm;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  h1
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:17.95pt;
      margin-bottom:.0001pt;
      mso-pagination:none;
      mso-outline-level:1;
      text-autospace:none;
      font-size:12.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;
      mso-font-kerning:0pt;}
  h2
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.95pt;
      margin-bottom:.0001pt;
      mso-pagination:none;
      mso-outline-level:2;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      mso-style-link:"Body Text Char";
      margin:0cm;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
      {mso-style-noshow:yes;
      mso-style-priority:99;
      mso-style-link:"Balloon Text Char";
      margin:0cm;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:8.0pt;
      font-family:"Tahoma","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
      {mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:47.0pt;
      margin-bottom:.0001pt;
      text-indent:-18.05pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  p.TableParagraph, li.TableParagraph, div.TableParagraph
      {mso-style-name:"Table Paragraph";
      mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-qformat:yes;
      margin-top:0cm;
      margin-right:0cm;
      margin-bottom:0cm;
      margin-left:5.85pt;
      margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;}
  span.BalloonTextChar
      {mso-style-name:"Balloon Text Char";
      mso-style-noshow:yes;
      mso-style-priority:99;
      mso-style-unhide:no;
      mso-style-locked:yes;
      mso-style-link:"Balloon Text";
      mso-ansi-font-size:8.0pt;
      mso-bidi-font-size:8.0pt;
      font-family:"Tahoma","sans-serif";
      mso-ascii-font-family:Tahoma;
      mso-fareast-font-family:Calibri;
      mso-hansi-font-family:Tahoma;
      mso-bidi-font-family:Tahoma;}
  span.BodyTextChar
      {mso-style-name:"Body Text Char";
      mso-style-priority:1;
      mso-style-unhide:no;
      mso-style-locked:yes;
      mso-style-link:"Body Text";
      font-family:"Calibri","sans-serif";
      mso-ascii-font-family:Calibri;
      mso-fareast-font-family:Calibri;
      mso-hansi-font-family:Calibri;
      mso-bidi-font-family:Calibri;}
  .MsoChpDefault
      {mso-style-type:export-only;
      mso-default-props:yes;
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-fareast-font-family:Calibri;
      mso-fareast-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"Times New Roman";
      mso-bidi-theme-font:minor-bidi;}
  .MsoPapDefault
      {mso-style-type:export-only;
      mso-pagination:none;
      text-autospace:none;}
   /* Page Definitions */
   @page
      {mso-footnote-separator:url("llll_files/header.htm") fs;
      mso-footnote-continuation-separator:url("llll_files/header.htm") fcs;
      mso-endnote-separator:url("llll_files/header.htm") es;
      mso-endnote-continuation-separator:url("llll_files/header.htm") ecs;}
  @page Section1
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-page-numbers:1;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section1
      {page:Section1;}
  @page Section2
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section2
      {page:Section2;}
  @page Section3
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section3
      {page:Section3;}
  @page Section4
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:36.0pt;
      mso-footer-margin:36.0pt;
      mso-columns:2 not-even 82.85pt 2.0pt 411.15pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section4
      {page:Section4;}
  @page Section5
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:36.0pt;
      mso-footer-margin:36.0pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section5
      {page:Section5;}
  @page Section6
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section6
      {page:Section6;}
  @page Section7
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section7
      {page:Section7;}
  @page Section8
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section8
      {page:Section8;}
  @page Section9
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section9
      {page:Section9;}
  @page Section10
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section10
      {page:Section10;}
  @page Section11
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:36.0pt;
      mso-footer-margin:36.0pt;
      mso-columns:2 not-even 76.05pt 2.0pt 417.95pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section11
      {page:Section11;}
  @page Section12
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:36.0pt;
      mso-footer-margin:36.0pt;
      mso-header:url("llll_files/header.htm") h1;
      mso-footer:url("llll_files/header.htm") f1;
      mso-paper-source:0;}
  div.Section12
      {page:Section12;}
  @page Section13
      {size:612.0pt 792.0pt;
      margin:98.0pt 55.0pt 58.0pt 61.0pt;
      mso-header-margin:23.25pt;
      mso-footer-margin:48.6pt;
      mso-header:url("llll_files/header.htm") h13;
      mso-footer:url("llll_files/header.htm") f13;
      mso-paper-source:0;}
  div.Section13
      {page:Section13;}
   /* List Definitions */
   @list l0
      {mso-list-id:519903101;
      mso-list-type:hybrid;
      mso-list-template-ids:-1601538598 -1189048890 1345066962 921998168 187882440 930782938 -1150122056 1035398638 1278916140 1061222606;}
  @list l0:level1
      {mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:64.9pt;
      text-indent:-17.9pt;
      mso-ansi-font-size:11.0pt;
      mso-bidi-font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-fareast-font-family:Calibri;
      letter-spacing:-.1pt;
      mso-font-width:100%;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;
      mso-ansi-font-weight:bold;
      mso-bidi-font-weight:bold;}
  @list l0:level2
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:108.1pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level3
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:151.2pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level4
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:194.3pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level5
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:237.4pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level6
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:280.5pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level7
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:323.6pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level8
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:366.7pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l0:level9
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:409.8pt;
      text-indent:-17.9pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1
      {mso-list-id:1255557121;
      mso-list-type:hybrid;
      mso-list-template-ids:1240385004 -1718711698 774001064 807144326 -2114416834 -702778734 -1324569426 254327514 -979980308 1902948356;}
  @list l1:level1
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:\F0B7;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:59.95pt;
      text-indent:-18.0pt;
      mso-ansi-font-size:11.0pt;
      mso-bidi-font-size:11.0pt;
      font-family:Symbol;
      mso-fareast-font-family:Symbol;
      mso-bidi-font-family:Symbol;
      mso-font-width:100%;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level2
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:103.6pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level3
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:147.2pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level4
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:190.8pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level5
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:234.4pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level6
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:278.0pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level7
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:321.6pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level8
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:365.2pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l1:level9
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:408.8pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2
      {mso-list-id:1591743636;
      mso-list-type:hybrid;
      mso-list-template-ids:1025152952 190730794 -1606796108 1271286328 -619677064 -902818886 636922726 -545210744 -130617444 -969121172;}
  @list l2:level1
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:\F0B7;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:11.0pt;
      text-indent:-18.0pt;
      mso-ansi-font-size:11.0pt;
      mso-bidi-font-size:11.0pt;
      font-family:Symbol;
      mso-fareast-font-family:Symbol;
      mso-bidi-font-family:Symbol;
      mso-font-width:100%;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level2
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:\F0B7;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:65.0pt;
      text-indent:-18.0pt;
      mso-ansi-font-size:11.0pt;
      mso-bidi-font-size:11.0pt;
      font-family:Symbol;
      mso-fareast-font-family:Symbol;
      mso-bidi-font-family:Symbol;
      mso-font-width:100%;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level3
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:112.85pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level4
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:160.75pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level5
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:208.65pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level6
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:256.55pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level7
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:304.4pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level8
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:352.3pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l2:level9
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:400.2pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3
      {mso-list-id:1776441165;
      mso-list-type:hybrid;
      mso-list-template-ids:-517054592 543096674 58385194 1284789094 -259741530 -1910200670 442909836 949915262 601634892 92692848;}
  @list l3:level1
      {mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:59.95pt;
      text-indent:-18.0pt;
      letter-spacing:-.1pt;
      mso-font-width:100%;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level2
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:103.6pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level3
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:147.2pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level4
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:190.8pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level5
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:234.4pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level6
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:278.0pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level7
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:321.6pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level8
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:365.2pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  @list l3:level9
      {mso-level-start-at:0;
      mso-level-number-format:bullet;
      mso-level-text:•;
      mso-level-tab-stop:none;
      mso-level-number-position:left;
      margin-left:408.8pt;
      text-indent:-18.0pt;
      mso-ansi-language:EN-US;
      mso-fareast-language:EN-US;
      mso-bidi-language:AR-SA;}
  ol
      {margin-bottom:0cm;}
  ul
      {margin-bottom:0cm;}
  -->
  </style>
  <!--[if gte mso 10]>
  <style>
   /* Style Definitions */
   table.MsoNormalTable
      {mso-style-name:"Table Normal";
      mso-tstyle-rowband-size:0;
      mso-tstyle-colband-size:0;
      mso-style-noshow:yes;
      mso-style-priority:99;
      mso-style-qformat:yes;
      mso-style-parent:"";
      mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
      mso-para-margin:0cm;
      mso-para-margin-bottom:.0001pt;
      mso-pagination:none;
      text-autospace:none;
      font-size:11.0pt;
      font-family:"Calibri","sans-serif";
      mso-ascii-font-family:Calibri;
      mso-ascii-theme-font:minor-latin;
      mso-hansi-font-family:Calibri;
      mso-hansi-theme-font:minor-latin;
      mso-bidi-font-family:"Times New Roman";
      mso-bidi-theme-font:minor-bidi;}
  </style>
  <![endif]--><!--[if gte mso 9]><xml>
   <o:shapedefaults v:ext="edit" spidmax="5122"/>
  </xml><![endif]--><!--[if gte mso 9]><xml>
   <o:shapelayout v:ext="edit">
    <o:idmap v:ext="edit" data="2"/>
   </o:shapelayout></xml><![endif]-->
  </head>
  
  <body lang=EN-US style='tab-interval:36.0pt'>
  
  <div class=Section1>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt;
  font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
  Calibri'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:11.0pt'><span style='font-size:13.0pt;
  font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
  Calibri'><o:p>&nbsp;</o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Times New Roman","serif";mso-fareast-font-family:
  Calibri;mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;mso-ansi-language:
  EN-US;mso-fareast-language:EN-US;mso-bidi-language:AR-SA'><br clear=all
  style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section2>
  
  <h2 style='margin-top:2.8pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  0cm;margin-bottom:.0001pt;text-indent:11.0pt'><!--[if gte vml 1]><v:rect id="_x0000_s2051"
   style='position:absolute;left:0;text-align:left;margin-left:1in;margin-top:14.5pt;
   width:69.6pt;height:.7pt;z-index:1;mso-position-horizontal-relative:page'
   fillcolor="black" stroked="f">
   <w:wrap anchorx="page"/>
  </v:rect><![endif]--><![if !vml]><span style='mso-ignore:vglayout;position:
  absolute;z-index:1;left:0px;margin-left:96px;margin-top:19px;width:93px;
  height:1px'><img width=93 height=1 src="llll_files/image003.gif" v:shapes="_x0000_s2051"></span><![endif]><span
  style='font-size:13.0pt'><span style='letter-spacing:-.1pt'>Initiate</span><span
  style='letter-spacing:-.6pt'> </span><span style='letter-spacing:-.05pt'> Payout :</span><o:p></o:p></span></h2>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>This<span
  style='letter-spacing:-.35pt'> </span>API<span style='letter-spacing:-.4pt'> </span>allows<span
  style='letter-spacing:-.3pt'> </span>you<span style='letter-spacing:-.55pt'> </span>to<span
  style='letter-spacing:-.25pt'> </span>facilitate<span style='letter-spacing:
  -.45pt'> </span>money<span style='letter-spacing:-.4pt'> </span>transfers<span
  style='letter-spacing:-.3pt'> </span>to/from<span style='letter-spacing:-.35pt'>
  </span>the<span style='letter-spacing:-.3pt'> </span>linked<span
  style='letter-spacing:-.55pt'> </span>virtual<span style='letter-spacing:-.2pt'>
  </span>bank<span style='letter-spacing:-.45pt'> </span>accounts<span
  style='letter-spacing:-.6pt'> </span>of<span style='letter-spacing:-.6pt'> </span>your<span
  style='letter-spacing:-.05pt'> </span>customers <span style='letter-spacing:
  -2.35pt'> </span>from<span style='letter-spacing:-.15pt'> </span>within<span
  style='letter-spacing:-.15pt'> </span>your application.<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.35pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:11.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.05pt'>Icepe</span><span style='font-size:13.0pt;
  letter-spacing:-.55pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>provides</span><span style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>the</span><span
  style='font-size:13.0pt;letter-spacing:-.45pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>below</span><span style='font-size:13.0pt;
  letter-spacing:-.15pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>type</span><span style='font-size:13.0pt;letter-spacing:-.45pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>of</span><span style='font-size:
  13.0pt;letter-spacing:-.35pt'> </span><span style='font-size:13.0pt'>transfers.<o:p></o:p></span></p>
  
  <p class=MsoListParagraph style='margin-top:1.1pt;margin-right:267.45pt;
  margin-bottom:0cm;margin-left:11.0pt;margin-bottom:.0001pt;text-indent:18.0pt;
  line-height:26.5pt;mso-list:l2 level1 lfo4;tab-stops:47.0pt 47.05pt'><![if !supportLists]><span
  style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
  Symbol'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>Virtual account
  to actual bank account<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.05pt'>Icepe</span><span style='letter-spacing:-.55pt'>
  </span>supports<span style='letter-spacing:-.7pt'> </span>the<span
  style='letter-spacing:-.55pt'> </span>below<span style='letter-spacing:-.55pt'>
  </span>modes<span style='letter-spacing:-.65pt'> </span>of<span
  style='letter-spacing:-.6pt'> </span>transfers.<o:p></o:p></span></p>
  
  <p class=MsoListParagraph style='margin-top:.65pt;line-height:13.95pt;
  mso-line-height-rule:exactly;mso-list:l2 level1 lfo4;tab-stops:47.0pt 47.05pt'><![if !supportLists]><span
  style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
  Symbol'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>NEFT<o:p></o:p></span></p>
  
  <p class=MsoListParagraph style='line-height:13.95pt;mso-line-height-rule:exactly;
  mso-list:l2 level1 lfo4;tab-stops:47.0pt 47.05pt'><![if !supportLists]><span
  style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
  Symbol'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>RTGS<o:p></o:p></span></p>
  
  <p class=MsoListParagraph style='margin-top:.05pt;line-height:13.9pt;
  mso-line-height-rule:exactly;mso-list:l2 level1 lfo4;tab-stops:47.0pt 47.05pt'><![if !supportLists]><span
  style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
  Symbol'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>IMPS<o:p></o:p></span></p>
  
  <p class=MsoListParagraph style='line-height:13.9pt;mso-line-height-rule:exactly;
  mso-list:l2 level1 lfo4;tab-stops:47.0pt 47.05pt'><![if !supportLists]><span
  style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
  Symbol'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>UPI<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.25pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal style='margin-left:29.0pt;line-height:17.85pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt;font-family:"Lucida Sans Unicode","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:red'>Note:<span
  style='letter-spacing:-.5pt'> </span>API<span style='letter-spacing:-.25pt'> </span>Key<span
  style='letter-spacing:-.5pt'> </span>&amp;<span style='letter-spacing:-.15pt'> </span>Secrete<span
  style='letter-spacing:-.35pt'> </span>Key<span style='letter-spacing:-.5pt'> </span>will<span
  style='letter-spacing:-.35pt'> </span>be<span style='letter-spacing:-.15pt'> </span>share<span
  style='letter-spacing:-.3pt'> </span>by<span style='letter-spacing:-.35pt'> </span>Icepe</span><span
  style='font-size:13.0pt;font-family:"Lucida Sans Unicode","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'>.<o:p></o:p></span></p>
  
  <h1 style='margin-left:29.95pt;line-height:14.05pt;mso-line-height-rule:exactly'><span
  style='font-size:13.0pt'>Request<o:p></o:p></span></h1>
  
  <p class=MsoNormal style='margin-top:9.0pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:29.0pt;margin-bottom:.0001pt'><!--[if gte vml 1]><v:rect id="_x0000_s2050"
   style='position:absolute;left:0;text-align:left;margin-left:142.35pt;
   margin-top:20.4pt;width:287.15pt;height:.7pt;z-index:-1;
   mso-position-horizontal-relative:page' fillcolor="blue" stroked="f">
   <w:wrap anchorx="page"/>
  </v:rect><![endif]--><![if !vml]><span style='mso-ignore:vglayout;position:
  absolute;z-index:-1;left:0px;margin-left:190px;margin-top:27px;width:383px;
  height:1px'><img width=383 height=1 src="llll_files/image004.gif" v:shapes="_x0000_s2050"></span><![endif]><span
  style='font-size:13.0pt'><b style='mso-bidi-font-weight:normal'><span
  style='letter-spacing:-.1pt'>Endpoint</span></b><span style='letter-spacing:
  -.1pt'>:</span><span style='letter-spacing:2.1pt'> </span><a
  href="https://payout.icepe.in/swipelinc-payout/core-banking/initiate-payout"><span
  style='font-family:"Arial MT","sans-serif";mso-hansi-font-family:Calibri;
  color:blue;letter-spacing:-.1pt;text-decoration:none;text-underline:none'>https://payout.icepe.in/swipelinc-payout/core-banking/initiate-payout</span></a></span><span
  style='font-size:13.0pt;font-family:"Arial MT","sans-serif";mso-hansi-font-family:
  Calibri'><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt;font-family:"Arial MT","sans-serif";
  mso-hansi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal style='margin-top:8.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:29.0pt;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;font-family:"Arial","sans-serif";mso-hansi-font-family:
  Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;letter-spacing:-.05pt'>Method</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;font-family:
  "Arial","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
  color:#1F1F1F;letter-spacing:-.7pt'> </span></b><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;
  letter-spacing:-.05pt'>Type:</span></b><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;font-family:"Arial","sans-serif";mso-hansi-font-family:
  Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;letter-spacing:-.6pt'> </span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;font-family:
  "Arial","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
  color:#1F1F1F;letter-spacing:-.05pt'>POST</span></b><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.2pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></b></p>
  
  <h2 style='margin-left:29.0pt'><span style='font-size:13.0pt;letter-spacing:
  -.15pt'>Sample</span><span style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>Request:</span><span
  style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoListParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:64.9pt;margin-bottom:.0001pt;text-indent:-17.9pt;mso-list:l0 level1 lfo3;
  tab-stops:64.9pt'><![if !supportLists]><b><span style='letter-spacing:-.1pt'><span
  style='mso-list:Ignore'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span></b><![endif]><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'>IMPS:<o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.45pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:77.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.75pt;margin-right:249.65pt;margin-bottom:
  0cm;margin-left:92.85pt;margin-bottom:.0001pt;text-indent:-7.45pt;line-height:
  103%'><span style='font-size:13.0pt;line-height:103%'>&quot;beneficiary Details&quot;:{<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt'>&quot;emailAddress&quot;</span><a
  href="mailto:abc@gmail.com"><span style='color:windowtext;letter-spacing:-.1pt;
  text-decoration:none;text-underline:none'>:&quot;abc@g</span></a><span
  style='letter-spacing:-.1pt'>m</span><a href="mailto:abc@gmail.com"><span
  style='color:windowtext;letter-spacing:-.1pt;text-decoration:none;text-underline:
  none'>ail.com</span></a><span style='letter-spacing:-.1pt'>&quot;,</span><span
  style='letter-spacing:-2.35pt'> </span>&quot;mobileNumber&quot;:&quot;9827525252&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;ifscCode&quot;:&quot;SBIN0007131&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.2pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:92.85pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>&quot;payeeName&quot;:&quot;Ankit</span><span
  style='font-size:13.0pt;letter-spacing:-.35pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>Jain&quot;</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:85.4pt'><span style='font-size:13.0pt'>},<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.75pt;margin-right:294.35pt;margin-bottom:
  0cm;margin-left:85.4pt;margin-bottom:.0001pt;line-height:103%'><span
  style='font-size:13.0pt;line-height:103%;background:yellow'>&quot;referenceId&quot;:&quot;Test101&quot;,</span><span
  style='font-size:13.0pt;line-height:103%;letter-spacing:.05pt'> </span><span
  style='font-size:13.0pt;line-height:103%;letter-spacing:-.1pt'>&quot;purposeMessage&quot;:&quot;Test&quot;,</span><span
  style='font-size:13.0pt;line-height:103%;letter-spacing:-2.35pt'> </span><span
  style='font-size:13.0pt;line-height:103%'>&quot;toAccount&quot;:&quot;87654321&quot;,<span
  style='letter-spacing:-2.35pt'> </span>&quot;toUpi&quot;:&quot;&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;transferType&quot;:&quot;IMPS&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;transferAmount&quot;:&quot;10&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;apikey&quot;:&quot;Test-key&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;secrete&quot;:&quot;secrete&quot;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:77.95pt;line-height:13.2pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section3>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:auto;mso-break-type:section-break'>
  </span>
  
  <div class=Section4>
  
  <p class=MsoBodyText style='margin-top:.25pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-left:64.9pt;text-indent:-17.9pt;mso-list:l0 level1 lfo3;
  tab-stops:64.9pt'><![if !supportLists]><span style='letter-spacing:-.1pt'><span
  style='mso-list:Ignore'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt;letter-spacing:
  -.4pt'>UPI:</span><span style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText align=right style='margin-top:.6pt;margin-right:.4pt;
  margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:right'><span
  style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='mso-special-character:line-break;mso-column-break-before:
  always'>
  </span>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.1pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:252.6pt;margin-bottom:
  0cm;margin-left:8.15pt;margin-bottom:.0001pt;text-indent:-7.45pt;line-height:
  103%'><span style='font-size:13.0pt;line-height:103%'>&quot;beneficiary Details&quot;:{<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;emailAddress&quot;</span><a
  href="mailto:abc@gmail.com"><span style='color:windowtext;letter-spacing:-.2pt;
  text-decoration:none;text-underline:none'>:&quot;abc@g</span></a><span
  style='letter-spacing:-.2pt'>m</span><a href="mailto:abc@gmail.com"><span
  style='color:windowtext;letter-spacing:-.2pt;text-decoration:none;text-underline:
  none'>ail.com</span></a><span style='letter-spacing:-.2pt'>&quot;,</span><span
  style='letter-spacing:-2.35pt'> </span>&quot;mobileNumber&quot;:&quot;9827525252&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;ifscCode&quot;:&quot;&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;payeeName&quot;:&quot;Ankit
  Jain&quot;<o:p></o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;line-height:103%;font-family:"Calibri","sans-serif";
  mso-fareast-font-family:Calibri;mso-ansi-language:EN-US;mso-fareast-language:
  EN-US;mso-bidi-language:AR-SA'><br clear=all style='page-break-before:auto;
  mso-break-type:section-break'>
  </span>
  
  <div class=Section5>
  
  <p class=MsoBodyText style='margin-left:85.4pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>},<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.5pt;margin-right:281.8pt;margin-bottom:
  0cm;margin-left:85.4pt;margin-bottom:.0001pt;line-height:103%'><span
  style='font-size:13.0pt;line-height:103%;background:yellow'>&quot;referenceId&quot;:&quot;Test102&quot;,</span><span
  style='font-size:13.0pt;line-height:103%;letter-spacing:.05pt'> </span><span
  style='font-size:13.0pt;line-height:103%;letter-spacing:-.2pt'>&quot;purposeMessage&quot;:&quot;TestUPI&quot;,</span><span
  style='font-size:13.0pt;line-height:103%;letter-spacing:-2.35pt'> </span><span
  style='font-size:13.0pt;line-height:103%'>&quot;toAccount&quot;:&quot;&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;toUpi&quot;:&quot;example@VPA&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;transferType&quot;:&quot;UPI&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;transferAmount&quot;:&quot;10&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;apikey&quot;:&quot;Test-key&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;secrete&quot;:&quot;secrete&quot;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:77.95pt;line-height:13.05pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section6>
  
  <p class=MsoBodyText style='margin-top:.4pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-top:2.8pt'><span style='font-size:13.0pt;letter-spacing:-.1pt'>Request</span><span
  style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.1pt'>Parameters:</span><span style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.45pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:30.0pt;border-collapse:collapse;mso-table-layout-alt:fixed;
   border:none;mso-border-alt:solid black .5pt;mso-yfti-tbllook:480;mso-padding-alt:
   0cm 0cm 0cm 0cm;mso-border-insideh:.5pt solid black;mso-border-insidev:.5pt solid black'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:13.4pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    mso-border-alt:solid black .5pt;background:#D9DFF3;padding:0cm 0cm 0cm 0cm;
    height:13.4pt'>
    <p class=TableParagraph style='margin-left:5.95pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Key<o:p></o:p></span></b></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='line-height:12.4pt;mso-line-height-rule:exactly'><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Type/Mandatory<o:p></o:p></span></b></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='line-height:12.4pt;mso-line-height-rule:exactly'><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Description<o:p></o:p></span></b></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph align=right style='margin-top:0cm;margin-right:32.5pt;
    margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:right;
    line-height:12.4pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
    normal'><span style='font-size:13.0pt;letter-spacing:-.1pt'>Possible</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
    -.55pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>values/Example</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;height:13.4pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:5.95pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>emailAddress<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='line-height:12.4pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='line-height:12.4pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>Valid<span style='letter-spacing:-.6pt'> </span>email<span
    style='letter-spacing:-.6pt'> </span>address<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph align=right style='margin-top:0cm;margin-right:34.95pt;
    margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:right;
    line-height:12.4pt;mso-line-height-rule:exactly'><span style='font-size:13.0pt'><a
    href="mailto:abcXXX@domain.com"><span style='color:windowtext;text-decoration:
    none;text-underline:none'>abcXXX@domain.com</span></a><o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;height:13.65pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:13.65pt'>
    <p class=TableParagraph style='margin-left:5.95pt;line-height:12.65pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>mobileNumber<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:13.65pt'>
    <p class=TableParagraph style='line-height:12.65pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:13.65pt'>
    <p class=TableParagraph style='line-height:12.65pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Valid</span><span
    style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>mobile</span><span
    style='font-size:13.0pt;letter-spacing:-.2pt'> </span><span style='font-size:
    13.0pt'>number<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:13.65pt'>
    <p class=TableParagraph style='margin-left:15.8pt;line-height:12.65pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>99XXXXXXXXX<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:3;height:49.75pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:49.75pt'>
    <p class=TableParagraph style='margin-left:5.95pt;line-height:13.4pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>ifscCode<b
    style='mso-bidi-font-weight:normal'><span style='color:red'>*</span><o:p></o:p></b></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:49.75pt'>
    <p class=TableParagraph style='line-height:13.4pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:49.75pt'>
    <p class=TableParagraph style='line-height:12.3pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Valid</span><span
    style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>IFSC</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    <p class=TableParagraph style='margin-right:6.15pt;line-height:12.5pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;color:red;letter-spacing:-.05pt'>*</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;color:red;
    letter-spacing:-.55pt'> </span></b><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>Only</span><span style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>required</span><span
    style='font-size:13.0pt;letter-spacing:-.75pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>for</span><span
    style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt'>non-<span style='letter-spacing:-2.35pt'> </span>UPI
    transactions<span style='letter-spacing:.05pt'> </span>(IMPS/NEFT/RTGS)<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:49.75pt'>
    <p class=TableParagraph style='margin-left:16.55pt;line-height:13.4pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>key-a89e0a1be44d<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:4;height:26.7pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:26.7pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>payeeName<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:26.7pt'>
    <p class=TableParagraph style='margin-top:.05pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:26.7pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt;
    letter-spacing:-.05pt'>Payee</span><span style='font-size:13.0pt;letter-spacing:
    -.55pt'> </span><span style='font-size:13.0pt'>Name<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:26.7pt'>
    <p class=TableParagraph style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:18.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>Sachin</span><span style='font-size:13.0pt;letter-spacing:
    -.55pt'> </span><span style='font-size:13.0pt;letter-spacing:-.05pt'>Kumar</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:5;height:12.65pt'>
    <td width=78 valign=top style='width:58.75pt;border-top:none;border-left:
    solid black 1.0pt;border-bottom:none;border-right:solid #F8F8F8 3.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-top-alt:solid black .5pt;
    mso-border-left-alt:solid black .5pt;mso-border-right-alt:solid #F8F8F8 3.0pt;
    padding:0cm 0cm 0cm 0cm;height:12.65pt'>
    <p class=TableParagraph style='margin-left:4.75pt;line-height:11.7pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>referenceId</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    </td>
    <td width=35 valign=top style='width:26.1pt;border:none;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid #F8F8F8 3.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid #F8F8F8 3.0pt;
    mso-border-right-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:12.65pt'>
    <p class=TableParagraph style='margin-left:0cm'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
    </td>
    <td width=119 rowspan=2 valign=top style='width:89.55pt;border-top:none;
    border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:12.65pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>String(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 rowspan=2 valign=top style='width:126.0pt;border-top:none;
    border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:12.65pt'>
    <p class=TableParagraph style='line-height:12.35pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>A<span style='letter-spacing:-.4pt'> </span>specific<span
    style='letter-spacing:-.55pt'> </span>unique<span style='letter-spacing:-.3pt'>
    </span>ID<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:.2pt;line-height:12.0pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt;letter-spacing:-.05pt'>assigned</span><span
    style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.05pt'>by</span><span style='font-size:13.0pt;
    letter-spacing:-.65pt'> </span><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>you</span><span style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>for</span><span
    style='font-size:13.0pt;letter-spacing:-.7pt'> </span><span style='font-size:
    13.0pt'>this<span style='letter-spacing:-2.35pt'> </span>transaction.<o:p></o:p></span></p>
    </td>
    <td width=199 rowspan=2 valign=top style='width:149.4pt;border-top:none;
    border-left:none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:12.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:18.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>SWIPEXXXXXXXXXXXX<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:6;height:24.45pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-left-alt:solid black .5pt;mso-border-bottom-alt:
    solid black .5pt;mso-border-right-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;
    height:24.45pt'>
    <p class=TableParagraph style='margin-left:0cm'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:7;height:75.05pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:75.05pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>purpose Message<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:75.05pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:75.05pt'>
    <p class=TableParagraph style='margin-top:.1pt;margin-right:6.15pt;
    margin-bottom:0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:93%'><span
    style='font-size:13.0pt;line-height:93%'>The narration that you<span
    style='letter-spacing:.05pt'> </span>want to show as a<span style='letter-spacing:
    .05pt'> </span>message for the transfer.<span style='letter-spacing:-2.35pt'>
    </span>(Note:<span style='letter-spacing:-.15pt'> </span>Purpose <span
    style='letter-spacing:-.15pt'> </span> message <o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:6.2pt;margin-bottom:
    0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:11.5pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt;letter-spacing:-.05pt'>must</span><span
    style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>be</span><span
    style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>at</span><span
    style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>least</span><span
    style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>5</span><span
    style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>and</span><span
    style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt'>not<span style='letter-spacing:-2.35pt'> </span>more
    than<span style='letter-spacing:-.5pt'> </span>50<span style='letter-spacing:
    -.2pt'> </span>characters)<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:75.05pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:18.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>Test</span><span style='font-size:13.0pt;letter-spacing:
    -.5pt'> </span><span style='font-size:13.0pt;letter-spacing:-.1pt'>Message</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:8;height:100.55pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:100.55pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>toAccount<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:100.55pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:100.55pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:5.25pt;
    margin-bottom:0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:92%'><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.1pt'>Account</span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.7pt'> </span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.1pt'>number</span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.8pt'> </span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.1pt'>to</span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.1pt'>where</span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-2.35pt'> </span><span
    style='font-size:13.0pt;line-height:92%'>transfer needs to be<span
    style='letter-spacing:.05pt'> </span>made. Mandatory, if the<span
    style='letter-spacing:.05pt'> </span>intended transfer type is<span
    style='letter-spacing:.05pt'> </span>IMPS/NEFT/RTGS.<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:.1pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:
    normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
    <p class=TableParagraph style='margin-right:24.2pt;line-height:12.5pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;color:red;
    letter-spacing:-.1pt'>*</span><span style='font-size:13.0pt;letter-spacing:
    -.1pt'>Either</span><span style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>value</span><span
    style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.1pt'>toUPI</span><span style='font-size:13.0pt;
    letter-spacing:-.8pt'> </span><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>or</span><span style='font-size:13.0pt;letter-spacing:-2.35pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>toAccount</span><span
    style='font-size:13.0pt;letter-spacing:-.3pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.1pt'>is</span><span style='font-size:13.0pt;
    letter-spacing:-.45pt'> </span><span style='font-size:13.0pt;letter-spacing:
    -.1pt'>required</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:100.55pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:18.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>123456789011<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:9;height:37.65pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>toUpi<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='line-height:12.5pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>Valid<span style='letter-spacing:-.5pt'> </span>UPI<span
    style='letter-spacing:-.35pt'> </span>or<span style='letter-spacing:-.2pt'> </span>VPA<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:.35pt;margin-right:22.6pt;
    margin-bottom:0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:11.9pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;color:red;
    letter-spacing:-.05pt'>*</span><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>Either</span><span style='font-size:13.0pt;letter-spacing:-.7pt'> </span><span
    style='font-size:13.0pt'>value<span style='letter-spacing:-.55pt'> </span>toUPI<span
    style='letter-spacing:-.65pt'> </span>or<span style='letter-spacing:-2.35pt'>
    </span><span style='letter-spacing:-.05pt'>toAccount</span><span
    style='letter-spacing:-.5pt'> </span><span style='letter-spacing:-.05pt'>is</span><span
    style='letter-spacing:-.55pt'> </span><span style='letter-spacing:-.05pt'>required</span><o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:21.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>vpaXXX@XXX<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:10;height:37.65pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>transferType<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='line-height:12.5pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>Can</span><span
    style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>be</span><span
    style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>NEFT/</span><span
    style='font-size:13.0pt;letter-spacing:-.25pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>IMPS/</span><span
    style='font-size:13.0pt;letter-spacing:-.4pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.05pt'>RTGS/</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:.35pt;margin-right:3.8pt;
    margin-bottom:0cm;margin-left:5.85pt;margin-bottom:.0001pt;line-height:11.9pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>UPI.</span><span style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Refer</span><span
    style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.05pt'>the</span><span style='font-size:13.0pt;
    letter-spacing:-.5pt'> </span><span style='font-size:13.0pt'>table<span
    style='letter-spacing:-.5pt'> </span>below<span style='letter-spacing:-2.35pt'>
    </span>for<span style='letter-spacing:-.05pt'> </span>transaction<span
    style='letter-spacing:-.05pt'> </span>limits<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:37.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:23.75pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.05pt'>IMPS</span><span style='font-size:13.0pt;letter-spacing:
    -.6pt'> </span><span style='font-size:13.0pt'>or<span style='letter-spacing:
    -.25pt'> </span>UPI<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:11;height:20.85pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:20.85pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>transfer Amount<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:20.85pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:20.85pt'>
    <p class=TableParagraph style='line-height:13.4pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>The</span><span
    style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.05pt'>transfer</span><span style='font-size:13.0pt;
    letter-spacing:-.3pt'> </span><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>amount</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:20.85pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:23.75pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>1000<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:12;height:25.65pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>apikey<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.65pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.65pt'>
    <p class=TableParagraph style='line-height:12.45pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Public</span><span
    style='font-size:13.0pt;letter-spacing:-.95pt'> </span><span
    style='font-size:13.0pt'>key<span style='letter-spacing:-.75pt'> </span>required<span
    style='letter-spacing:-.85pt'> </span>to<o:p></o:p></span></p>
    <p class=TableParagraph style='line-height:12.25pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>authorize API<span style='letter-spacing:-.05pt'> </span>access<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.65pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:23.25pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>key-a89e0a1be44d<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:13;mso-yfti-lastrow:yes;height:26.6pt'>
    <td width=113 colspan=2 valign=top style='width:84.85pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:26.6pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>secrete<o:p></o:p></span></p>
    </td>
    <td width=119 valign=top style='width:89.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:26.6pt'>
    <p class=TableParagraph style='margin-top:.2pt'><span style='font-size:13.0pt'>string(</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt'>)<o:p></o:p></span></p>
    </td>
    <td width=168 valign=top style='width:126.0pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:26.6pt'>
    <p class=TableParagraph style='margin-top:.05pt;line-height:13.3pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>API</span><span style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Secrete</span><span
    style='font-size:13.0pt;letter-spacing:-.7pt'> </span><span style='font-size:
    13.0pt'>key<span style='letter-spacing:-.7pt'> </span>required<o:p></o:p></span></p>
    <p class=TableParagraph style='line-height:12.25pt;mso-line-height-rule:exactly'><span
    style='font-size:13.0pt'>to authorize API<span style='letter-spacing:-.05pt'>
    </span>access<o:p></o:p></span></p>
    </td>
    <td width=199 valign=top style='width:149.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:26.6pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:23.75pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>test-5a8b0b1a<o:p></o:p></span></p>
    </td>
   </tr>
   <![if !supportMisalignedColumns]>
   <tr height=0>
    <td width=93 style='border:none'></td>
    <td width=35 style='border:none'></td>
    <td width=127 style='border:none'></td>
    <td width=168 style='border:none'></td>
    <td width=200 style='border:none'></td>
   </tr>
   <![endif]>
  </table>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section7>
  
  <p class=MsoNormal style='margin-top:6.95pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:29.0pt;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;letter-spacing:-.05pt'>Response </span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
  -.75pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>(For</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
  -.65pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>UPA/Account</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
  -.2pt'> </span></b><span style='font-size:13.0pt;letter-spacing:-.05pt'>transfer</span><span
  style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>type</span><span style='font-size:13.0pt;
  letter-spacing:-.45pt'> </span><span style='font-size:13.0pt'>with<span
  style='letter-spacing:-.55pt'> </span>success/pending<span style='letter-spacing:
  -.4pt'> </span>&amp;<span style='letter-spacing:-.7pt'> </span>failure<span
  style='letter-spacing:-.45pt'> </span>status<b style='mso-bidi-font-weight:
  normal'>):<o:p></o:p></b></span></p>
  
  <p class=MsoBodyText style='margin-top:.2pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoListParagraph style='margin-left:65.0pt;line-height:16.05pt;
  mso-line-height-rule:exactly;mso-list:l2 level2 lfo4;tab-stops:65.0pt 65.05pt'><![if !supportLists]><span
  style='font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:
  Symbol'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt;font-family:"Lucida Sans Unicode","sans-serif";
  mso-bidi-font-family:Calibri'>For<span style='letter-spacing:-.6pt'> </span>UPI<span
  style='letter-spacing:-.4pt'> </span>(</span><span style='font-size:13.0pt'>success</span><span
  style='font-size:13.0pt;font-family:"Lucida Sans Unicode","sans-serif";
  mso-bidi-font-family:Calibri'>):<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:65.0pt;line-height:12.55pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:196.5pt;margin-bottom:
  0cm;margin-left:72.45pt;margin-bottom:.0001pt;line-height:96%'><span
  style='font-size:13.0pt;line-height:96%'>&quot;status&quot;:&quot;success&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt;
  background:yellow'>&quot;txnId&quot;:&quot;9478D16CB35C4961B3D6ABCCA015097F&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:235.65pt;margin-bottom:
  0cm;margin-left:72.45pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;response Code&quot;:&quot;S00000&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;transferType&quot;:&quot;UPI&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;bank Reference Number&quot;:&quot;325518802382&quot; , </span><span
  style='letter-spacing:-2.35pt'> </span> &quot;newBalance&quot; : 994.0,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:72.45pt;line-height:101%'><span
  style='font-size:13.0pt;line-height:101%'>&quot;provider Response&quot;: &quot;Transaction
  Successfully Processed&quot;,<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.15pt'>&quot;requestStatusDescription&quot;: &quot;Transaction</span><span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.1pt'>Successfully</span><span
  style='letter-spacing:.15pt'> </span><span style='letter-spacing:-.1pt'>Processed&quot;</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:65.0pt;line-height:13.4pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.35pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoListParagraph style='margin-top:5.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt;mso-list:l1 level1 lfo2;
  tab-stops:59.95pt 60.0pt'><![if !supportLists]><span style='font-family:Symbol;
  mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol'><span
  style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>For</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
  -.5pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>IMPS</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'> <span
  style='letter-spacing:-.1pt'>(</span></span></b><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>success<b style='mso-bidi-font-weight:normal'>):</b></span><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.35pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:207.3pt;margin-bottom:
  0cm;margin-left:67.4pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;status&quot;:&quot;success&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt;
  background:yellow'>&quot;txnId&quot;:&quot;D3880FB77C5145959988E73A71580ADB&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:240.7pt;margin-bottom:
  0cm;margin-left:67.4pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;transferType&quot;:&quot;IMPS&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;bank Reference Number&quot; : &quot;325518912730&quot;,</span><span
  style='letter-spacing:-2.35pt'> </span>&quot;beneficiary Name&quot;:&quot;Mr<span
  style='letter-spacing:.05pt'> </span>Amit<span style='letter-spacing:.05pt'> </span>Jain&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot; response Code&quot;: &quot;S00000&quot;,<span
  style='letter-spacing:.05pt'> </span >&quot;new Balance&quot; : 819.0<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-top:5.05pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  59.95pt;margin-bottom:.0001pt;text-indent:-18.05pt;mso-list:l1 level1 lfo2;
  tab-stops:59.95pt 60.0pt'><![if !supportLists]><span style='font-family:Symbol;
  mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;font-weight:normal;
  mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt;letter-spacing:
  -.1pt'>For</span><span style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>IMPS</span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.1pt'>(pending):</span><span style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.25pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:67.4pt;margin-bottom:.0001pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;status&quot;:&quot;pending&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:67.4pt'><span style='font-size:13.0pt;
  letter-spacing:-.05pt'>&quot;message&quot; : &quot;The</span><span
  style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>transaction</span><span style='font-size:13.0pt;
  letter-spacing:-.6pt'> </span><span style='font-size:13.0pt'>is<span
  style='letter-spacing:-.55pt'> </span>currently<span style='letter-spacing:
  -.5pt'> </span>pending<span style='letter-spacing:-.55pt'> </span>for<span
  style='letter-spacing:-.65pt'> </span>settlement.&quot;,<span style='letter-spacing:
  -2.35pt'> </span>&quot;txnId&quot;:&quot;DCTRTXXXXXXXXXXXXXXXXX&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:331.15pt;margin-bottom:
  0cm;margin-left:67.4pt;margin-bottom:.0001pt;line-height:100%'><span
  style='font-size:13.0pt;line-height:100%'>&quot;newBalance&quot;:888,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;transferType&quot;:&quot;IMPS&quot;</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:59.95pt;line-height:12.95pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <h2 style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  59.95pt;margin-bottom:.0001pt;text-indent:-18.05pt;mso-list:l1 level1 lfo2;
  tab-stops:59.95pt 60.0pt'><![if !supportLists]><span style='font-family:Symbol;
  mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;font-weight:normal;
  mso-bidi-font-weight:bold'><span style='mso-list:Ignore'>·<span
  style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>For</span><span style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>any</span><span
  style='font-size:13.0pt;letter-spacing:-.4pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>transfer</span><span style='font-size:13.0pt;
  letter-spacing:-.4pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>type</span><span style='font-size:13.0pt;letter-spacing:-.25pt'> </span><span
  style='font-size:13.0pt'>(failure)</span><span style='font-size:13.0pt;
  font-weight:normal;mso-bidi-font-weight:bold'>:<o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.25pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:65.0pt'><span style='font-size:13.0pt'>&quot;error&quot;:<span
  style='letter-spacing:-.4pt'> </span>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt;text-indent:10.05pt'><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>&quot;message&quot;:</span><span
  style='font-size:13.0pt;letter-spacing:-.4pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>&quot;Transaction</span><span style='font-size:
  13.0pt;letter-spacing:-.35pt'> </span><span style='font-size:13.0pt'>amount<span
  style='letter-spacing:-.5pt'> </span>(plus<span style='letter-spacing:-.1pt'> </span>applied<span
  style='letter-spacing:-.5pt'> </span>taxes<span style='letter-spacing:-.4pt'> </span>and<span
  style='letter-spacing:-.5pt'> </span>commission)<span style='letter-spacing:
  -.35pt'> </span>is<span style='letter-spacing:-.55pt'> </span>more<span
  style='letter-spacing:-.4pt'> </span>than<span style='letter-spacing:-.45pt'> </span>the<span
  style='letter-spacing:-.1pt'> </span>balance <span style='letter-spacing:-2.35pt'>
  </span> available in<span style='letter-spacing:-.15pt'> </span>the source<span
  style='letter-spacing:-.1pt'> </span>account.&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:305.5pt;margin-bottom:
  0cm;margin-left:70.05pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.15pt'>&quot;response Code&quot;: </span><span style='font-size:
  13.0pt;letter-spacing:-.1pt'>&quot;E00033&quot;,</span><span style='font-size:
  13.0pt;letter-spacing:-2.35pt'> </span><span style='font-size:13.0pt'>&quot;status&quot;:<span
  style='letter-spacing:-.05pt'> </span>&quot;failure&quot;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:65.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>},<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:65.0pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt'>&quot;txnId&quot;:</span><span style='font-size:13.0pt;
  letter-spacing:-.25pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.1pt'>&quot;DCTRTXXXXXXXXXXXXXXX&quot;</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:59.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section8>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.25pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'>Transaction Limit<o:p></o:p></span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'>Please note the below
  transaction limits as per prescribed by RBI.<o:p></o:p></span></p>
  
  <p class=MsoNormal><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=543
   style='width:407.0pt;margin-left:5.65pt;border-collapse:collapse;mso-yfti-tbllook:
   1184;mso-padding-alt:0cm 5.4pt 0cm 5.4pt'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:36.0pt'>
    <td width=64 style='width:48.0pt;border:solid windowtext 1.0pt;mso-border-alt:
    solid windowtext .5pt;padding:0cm 5.4pt 0cm 5.4pt;height:36.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>Transfer Type<o:p></o:p></span></p>
    </td>
    <td width=479 style='width:359.0pt;border:solid windowtext 1.0pt;border-left:
    none;mso-border-top-alt:solid windowtext .5pt;mso-border-bottom-alt:solid windowtext .5pt;
    mso-border-right-alt:solid windowtext .5pt;padding:0cm 5.4pt 0cm 5.4pt;
    height:36.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>Transaction Limit<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;height:45.0pt'>
    <td width=64 nowrap style='width:48.0pt;border:solid windowtext 1.0pt;
    border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
    solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
    0cm 5.4pt 0cm 5.4pt;height:45.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>IMPS<o:p></o:p></span></p>
    </td>
    <td width=479 style='width:359.0pt;border-top:none;border-left:none;
    border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
    padding:0cm 5.4pt 0cm 5.4pt;height:45.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>Amount &lt;=INR 5,00,000 per transaction. This
    mode of transfer is immediate and is typically processed 24/7 and is settled
    to the beneficiary within 1 minute.<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;height:60.0pt'>
    <td width=64 nowrap style='width:48.0pt;border:solid windowtext 1.0pt;
    border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
    solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
    0cm 5.4pt 0cm 5.4pt;height:60.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>NEFT<o:p></o:p></span></p>
    </td>
    <td width=479 style='width:359.0pt;border-top:none;border-left:none;
    border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
    padding:0cm 5.4pt 0cm 5.4pt;height:60.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>Amount &lt;=INR 10,00,000 per transaction
    (Non-Banking Hours), Amount &lt;= INR 1 Cr per transaction (Banking Hours).
    This mode of transfer is immediate and is typically processed in batches and
    is settled within 2-3 working hours.<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:3;height:45.0pt'>
    <td width=64 nowrap style='width:48.0pt;border:solid windowtext 1.0pt;
    border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
    solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
    0cm 5.4pt 0cm 5.4pt;height:45.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>RTGS <o:p></o:p></span></p>
    </td>
    <td width=479 style='width:359.0pt;border-top:none;border-left:none;
    border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
    padding:0cm 5.4pt 0cm 5.4pt;height:45.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>Amount &gt;INR 2L per transaction, Amount &lt;=
    INR 5 Cr (Banking Hours). This mode of transfer is immediate and is typically
    processed in batches and is settled within 2-3 working hours.<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes;height:45.0pt'>
    <td width=64 nowrap style='width:48.0pt;border:solid windowtext 1.0pt;
    border-top:none;mso-border-left-alt:solid windowtext .5pt;mso-border-bottom-alt:
    solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;padding:
    0cm 5.4pt 0cm 5.4pt;height:45.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>UPI<o:p></o:p></span></p>
    </td>
    <td width=479 style='width:359.0pt;border-top:none;border-left:none;
    border-bottom:solid windowtext 1.0pt;border-right:solid windowtext 1.0pt;
    mso-border-bottom-alt:solid windowtext .5pt;mso-border-right-alt:solid windowtext .5pt;
    padding:0cm 5.4pt 0cm 5.4pt;height:45.0pt'>
    <p class=MsoNormal><span lang=EN-IN style='font-size:13.0pt;mso-fareast-font-family:
    "Times New Roman";color:black;mso-ansi-language:EN-IN;mso-fareast-language:
    EN-IN;mso-bidi-language:HI'>Amount &lt;= 1L per transaction subject to
    consumer's bank policies and applicable across transaction coming from UPI
    apps, and through Virtual Account.<o:p></o:p></span></p>
    </td>
   </tr>
  </table>
  
  <p class=MsoNormal align=right style='text-align:right'><span style='font-size:
  13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:8.75pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section9>
  
  <p class=MsoNormal style='margin-top:6.95pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.95pt;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:
  normal'><u><span style='font-size:13.0pt;letter-spacing:-.05pt'>Get</span></u></b><b
  style='mso-bidi-font-weight:normal'><u><span style='font-size:13.0pt;
  letter-spacing:-.7pt'> </span></u></b><b style='mso-bidi-font-weight:normal'><u><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>Transaction</span></u></b><b
  style='mso-bidi-font-weight:normal'><u><span style='font-size:13.0pt;
  letter-spacing:-.6pt'> </span></u></b><b style='mso-bidi-font-weight:normal'><u><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>Status:</span></u></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.5pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:2.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.05pt'>This</span><span style='font-size:13.0pt;letter-spacing:
  -.6pt'> </span><span style='font-size:13.0pt;letter-spacing:-.05pt'>API</span><span
  style='font-size:13.0pt;letter-spacing:-.35pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>can</span><span style='font-size:13.0pt;
  letter-spacing:-.45pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>be</span><span style='font-size:13.0pt;letter-spacing:-.2pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>used</span><span
  style='font-size:13.0pt;letter-spacing:-.2pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>by</span><span style='font-size:13.0pt;
  letter-spacing:-.55pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>you</span><span style='font-size:13.0pt;letter-spacing:-.6pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>to</span><span style='font-size:
  13.0pt;letter-spacing:-.2pt'> </span><span style='font-size:13.0pt;letter-spacing:
  -.05pt'>check</span><span style='font-size:13.0pt;letter-spacing:-.45pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'>the</span><span
  style='font-size:13.0pt;letter-spacing:-.4pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.05pt'>outgoing</span><span style='font-size:13.0pt;
  letter-spacing:-.4pt'> </span><span style='font-size:13.0pt'>transactions<span
  style='letter-spacing:-.5pt'> </span>that<span style='letter-spacing:-.6pt'> </span>were<span
  style='letter-spacing:-.2pt'> </span>initiated<span style='letter-spacing:-.45pt'>
  </span>by<span style='letter-spacing:-.2pt'> </span>your<span style='letter-spacing:
  -.15pt'> </span>customers.<o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.6pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h1><span style='font-size:13.0pt'>Request:<o:p></o:p></span></h1>
  
  <p class=MsoNormal style='margin-top:9.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:16.75pt;margin-bottom:.0001pt;tab-stops:69.8pt'><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Endpoint</span></b><span
  style='font-size:13.0pt'>:<span style='mso-tab-count:1'> </span><a
  href="https://payout.icepe.in/swipelinc-payout/core-banking/get-status"><span
  style='font-family:"Arial MT","sans-serif";mso-hansi-font-family:Calibri;
  color:blue'>https://payout.icepe.in/swipelinc-payout/core-banking</span><span
  style='color:blue'>/get-status</span></a><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal style='margin-top:4.7pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:16.75pt;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;font-family:"Arial","sans-serif";mso-hansi-font-family:
  Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;letter-spacing:-.05pt'>Method</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;font-family:
  "Arial","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
  color:#1F1F1F;letter-spacing:-.7pt'> </span></b><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;
  letter-spacing:-.05pt'>Type:</span></b><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;font-family:"Arial","sans-serif";mso-hansi-font-family:
  Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;letter-spacing:-.6pt'> </span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;font-family:
  "Arial","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
  color:#1F1F1F;letter-spacing:-.05pt'>POST</span></b><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.2pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></b></p>
  
  <h2 style='margin-left:16.75pt'><span style='font-size:13.0pt;letter-spacing:
  -.15pt'>Sample</span><span style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>Request:</span><span
  style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.3pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:2.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:49.4pt'><span style='font-size:13.0pt;
  letter-spacing:-.05pt;background:yellow'>&quot;referenceId&quot;:&quot;</span><span
  style='font-size:13.0pt;letter-spacing:-.6pt;background:yellow'> </span><span
  style='font-size:13.0pt;background:yellow'>Test101&quot;,</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:49.4pt'><span style='font-size:13.0pt;
  letter-spacing:-.1pt;background:yellow'>&quot;txnId&quot;:&quot;</span><span
  style='font-size:13.0pt;letter-spacing:-.35pt;background:yellow'> </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt;background:yellow'>DCTRTXXXXXXXXXXXXXXXXX&quot;,</span><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.5pt;margin-right:357.6pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt;line-height:105%'><span
  style='font-size:13.0pt;line-height:105%;letter-spacing:-.1pt'>&quot;apikey&quot;:&quot;Test-key&quot;,</span><span
  style='font-size:13.0pt;line-height:105%;letter-spacing:-2.35pt'> </span><span
  style='font-size:13.0pt;line-height:105%'>&quot;secrete&quot;:&quot;secrete&quot;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:41.95pt'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.5pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-top:2.85pt'><span style='font-size:13.0pt;letter-spacing:
  -.1pt'>Request</span><span style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>Parameters:</span><span
  style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.25pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:30.0pt;border-collapse:collapse;mso-table-layout-alt:fixed;
   border:none;mso-border-alt:solid black .5pt;mso-yfti-tbllook:480;mso-padding-alt:
   0cm 0cm 0cm 0cm;mso-border-insideh:.5pt solid black;mso-border-insidev:.5pt solid black'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:13.4pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    mso-border-alt:solid black .5pt;background:#D9DFF3;padding:0cm 0cm 0cm 0cm;
    height:13.4pt'>
    <p class=TableParagraph style='margin-left:5.95pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Key<o:p></o:p></span></b></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:6.1pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Type<o:p></o:p></span></b></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Description<o:p></o:p></span></b></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:13.95pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>Possible</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
    -.55pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>values/Example</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;height:50.1pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:50.1pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>referenceId<span
    style='letter-spacing:-.5pt'> </span><span style='color:red'>*</span><o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:50.1pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:50.1pt'>
    <p class=TableParagraph style='margin-top:.1pt;margin-right:4.35pt;
    margin-bottom:0cm;margin-left:6.15pt;margin-bottom:.0001pt;line-height:93%'><span
    style='font-size:13.0pt;line-height:93%'>The<span style='letter-spacing:-.2pt'>
    </span>request<span style='letter-spacing:-.25pt'> </span>ID<span
    style='letter-spacing:-.05pt'> </span>passed<span style='letter-spacing:-.2pt'>
    </span>by<span style='letter-spacing:-.25pt'> </span>you<span
    style='letter-spacing:-.25pt'> </span>for<span style='letter-spacing:-2.3pt'>
    </span>this<span style='letter-spacing:-.5pt'> </span>transaction<span
    style='letter-spacing:-.6pt'> </span>when<span style='letter-spacing:-.45pt'>
    </span>initiating<span style='letter-spacing:-.3pt'> </span>it<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:0cm;margin-right:7.05pt;margin-bottom:
    0cm;margin-left:6.15pt;margin-bottom:.0001pt;line-height:11.9pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>earlier<span style='letter-spacing:
    -.55pt'> </span>(Mandatory<span style='letter-spacing:-.35pt'> </span>if<span
    style='letter-spacing:-.55pt'> </span>txnId<span style='letter-spacing:-.6pt'>
    </span>is<span style='letter-spacing:-.4pt'> </span>not<span
    style='letter-spacing:-2.35pt'> </span>present</span><span style='font-size:
    13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri;color:#384246;background:#F8F8F8'>)</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri'><o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:50.1pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:43.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>SWIPEXXXXXXXXXXXX<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;height:62.7pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:62.7pt'>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>txnId<span
    style='letter-spacing:-.3pt'> </span><span style='color:red'>*</span><o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:62.7pt'>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:62.7pt'>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:9.65pt;
    margin-bottom:0cm;margin-left:6.15pt;margin-bottom:.0001pt;line-height:92%'><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.05pt'>(Mandatory</span><span
    style='font-size:13.0pt;line-height:92%;letter-spacing:-.6pt'> </span><span
    style='font-size:13.0pt;line-height:92%'>if<span style='letter-spacing:-.55pt'>
    </span>referenceId<span style='letter-spacing:-.55pt'> </span>is<span
    style='letter-spacing:-.55pt'> </span>not<span style='letter-spacing:-2.35pt'>
    </span>present)<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-left:8.75pt;line-height:12.2pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>transaction</span><span style='font-size:13.0pt;letter-spacing:-.55pt'>
    </span><span style='font-size:13.0pt'>id<span style='letter-spacing:-.65pt'> </span>that<span
    style='letter-spacing:-.6pt'> </span>we<span style='letter-spacing:-.6pt'> </span>responded<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-top:.45pt;margin-right:7.35pt;
    margin-bottom:0cm;margin-left:6.15pt;margin-bottom:.0001pt;line-height:11.9pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>with while
    providing the initiate<span style='letter-spacing:-2.35pt'> </span>payment<span
    style='letter-spacing:-.25pt'> </span>response <o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:62.7pt'>
    <p class=TableParagraph style='margin-top:.3pt;margin-right:7.7pt;margin-bottom:
    0cm;margin-left:43.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.2pt'>DCTRTXXXXXXXXXXXXXX</span><span style='font-size:13.0pt;
    letter-spacing:-2.35pt'> </span><span style='font-size:13.0pt'>XXX<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:3;height:25.9pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.05pt'>apikey</span><span style='font-size:13.0pt;color:#DD1E2C;
    letter-spacing:-.5pt;background:#F8F8F8'> </span><span style='font-size:13.0pt;
    font-family:"Segoe UI","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
    Calibri;color:#DD1E2C;letter-spacing:-.05pt;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri'><o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.45pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>Public</span><span style='font-size:13.0pt;letter-spacing:-.95pt'> </span><span
    style='font-size:13.0pt'>key<span style='letter-spacing:-.75pt'> </span>required<span
    style='letter-spacing:-.85pt'> </span>to<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.45pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>authorize API<span
    style='letter-spacing:-.05pt'> </span>access<o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:41.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>key-a89e0a1be44d<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes;height:25.8pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.8pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.05pt'>secrete</span><span style='font-size:13.0pt;
    color:#DD1E2C;letter-spacing:-.5pt;background:#F8F8F8'> </span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;letter-spacing:-.05pt;
    background:#F8F8F8'>required</span><span style='font-size:13.0pt;font-family:
    "Segoe UI","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
    Calibri'><o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.8pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.8pt'>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.6pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>API</span><span style='font-size:13.0pt;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Secrete</span><span
    style='font-size:13.0pt;letter-spacing:-.7pt'> </span><span style='font-size:
    13.0pt;letter-spacing:-.05pt'>key</span><span style='font-size:13.0pt;
    letter-spacing:-.6pt'> </span><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>required</span><span style='font-size:13.0pt;letter-spacing:-.8pt'> </span><span
    style='font-size:13.0pt'>to<span style='letter-spacing:-2.3pt'> </span>authorize
    API access<o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.8pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:43.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>test-5a8b0b1a<o:p></o:p></span></p>
    </td>
   </tr>
  </table>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section10>
  
  <p class=MsoBodyText style='margin-top:.15pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoNormal style='margin-top:2.8pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:18.45pt;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'>Response <span style='letter-spacing:-.6pt'> </span> JSON : <o:p></o:p></span></b></p>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <h2 style='margin-left:59.95pt;text-indent:-18.05pt;mso-list:l3 level1 lfo1;
  tab-stops:60.0pt'><![if !supportLists]><span style='font-size:13.0pt;
  letter-spacing:-.1pt;font-weight:normal;mso-bidi-font-weight:bold'><span
  style='mso-list:Ignore'>1.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>IMPS</span><span
  style='font-size:13.0pt;font-weight:normal;mso-bidi-font-weight:bold'>:<o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.2pt;margin-right:336.1pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt;line-height:97%'><span
  style='font-size:13.0pt;line-height:97%'>&quot;status&quot;:&quot;success&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;referenceId&quot;:&quot;1011010&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:138.5pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnId&quot;:&quot;
  DCTRTXXXXXXXXXXXXXXXXX&quot;,<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.2pt'>&quot;payoutStatusCheckTxnId&quot;:&quot;A4DE146CBDB14976926D141EA147D531&quot;,</span><span
  style='letter-spacing:-2.35pt'> </span>&quot;transferType&quot;:&quot;IMPS&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:258.7pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt;line-height:100%'><span
  style='font-size:13.0pt;line-height:100%;letter-spacing:-.2pt'>&quot;bankReferenceNumber&quot;:&quot;325415868977&quot;,</span><span
  style='font-size:13.0pt;line-height:100%;letter-spacing:-2.35pt'> </span><span
  style='font-size:13.0pt;line-height:100%'>&quot;beneficiary Name&quot;:&quot;Mr<span
  style='letter-spacing:1.9pt'> </span>Amit<span style='letter-spacing:1.9pt'> </span>Jain&quot;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:41.95pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.2pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-top:2.8pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  59.85pt;margin-bottom:.0001pt;text-indent:-17.95pt;mso-list:l3 level1 lfo1;
  tab-stops:59.9pt'><![if !supportLists]><span style='font-size:13.0pt;
  letter-spacing:-.1pt'><span style='mso-list:Ignore'>2.<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;
  </span></span></span><![endif]><span style='font-size:13.0pt'>UPI:<o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.3pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:2.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.2pt;margin-right:316.6pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt;line-height:97%'><span
  style='font-size:13.0pt;line-height:97%'>&quot;status&quot;:&quot;success&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;referenceId&quot;:&quot;1wipez112e3&quot;,</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:139.7pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt;line-height:100%'><span
  style='font-size:13.0pt;line-height:100%'>&quot;txnId&quot;:&quot;9478D16CB35C4961B3D6ABCCA015097F&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;payoutStatusCheckTxnId&quot;:&quot;F65BAC20A53A44D4A8A74026E5CDE41F&quot;,</span><span
  style='letter-spacing:-2.35pt'> </span>&quot;transferType&quot;:&quot;UPI&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:181.4pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
  letter-spacing:-.15pt'>&quot;providerResponse&quot;:&quot;Transaction </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>Successfully</span><span
  style='font-size:13.0pt;letter-spacing:-.05pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.1pt'>Processed&quot;,</span><span style='font-size:
  13.0pt;letter-spacing:-2.35pt'> </span><span style='font-size:13.0pt'>&quot;bankReferenceNumber&quot;:&quot;325518802382&quot;,<span
  style='letter-spacing:.05pt'> </span>&quot;beneficiary Name&quot;:&quot;Amit<span
  style='letter-spacing:-.05pt'> </span>Jain&quot;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:41.95pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h1 style='margin-top:2.6pt'><span style='font-size:13.0pt'>Request:<o:p></o:p></span></h1>
  
  <p class=MsoNormal style='margin-top:9.1pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:16.75pt;margin-bottom:.0001pt;tab-stops:70.05pt'><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Endpoint</span></b><span
  style='font-size:13.0pt'>:<span style='mso-tab-count:1'> </span></span><u
  style='text-underline:blue'><span style='font-size:13.0pt;font-family:"Arial MT","sans-serif";
  mso-hansi-font-family:Calibri;color:blue'>https://payout.icepe.in/swipelinc-payout/core-banking</span></u><u
  style='text-underline:blue'><span style='font-size:13.0pt;color:blue'>/check-balance</span></u><span
  style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoNormal style='margin-top:4.7pt;margin-right:0cm;margin-bottom:0cm;
  margin-left:16.75pt;margin-bottom:.0001pt'><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;font-family:"Arial","sans-serif";mso-hansi-font-family:
  Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;letter-spacing:-.05pt'>Method</span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;font-family:
  "Arial","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
  color:#1F1F1F;letter-spacing:-.7pt'> </span></b><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;
  letter-spacing:-.05pt'>Type:</span></b><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt;font-family:"Arial","sans-serif";mso-hansi-font-family:
  Calibri;mso-bidi-font-family:Calibri;color:#1F1F1F;letter-spacing:-.6pt'> </span></b><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;font-family:
  "Arial","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri;
  color:#1F1F1F;letter-spacing:-.05pt'>POST</span></b><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.2pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt;font-family:"Arial","sans-serif";
  mso-hansi-font-family:Calibri;mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></b></p>
  
  <h2 style='margin-left:16.75pt'><span style='font-size:13.0pt;letter-spacing:
  -.15pt'>Sample</span><span style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>Request:</span><span
  style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.25pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:2.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.5pt;margin-right:356.35pt;margin-bottom:
  0cm;margin-left:49.4pt;margin-bottom:.0001pt;line-height:105%'><span
  style='font-size:13.0pt;line-height:105%;letter-spacing:-.1pt'>&quot;apikey&quot;:&quot;Test-key&quot;,</span><span
  style='font-size:13.0pt;line-height:105%;letter-spacing:-2.35pt'> </span><span
  style='font-size:13.0pt;line-height:105%'>&quot;secrete&quot;:&quot;secrete&quot;<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.2pt'>&quot;transferType&quot;:&quot;UPI&quot;</span><o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.5pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:2.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:41.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:auto;mso-break-type:section-break'>
  </span>
  
  <div class=Section11>
  
  <p class=MsoBodyText style='margin-top:.15pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-top:2.85pt;margin-right:395.15pt;margin-bottom:0cm;
  margin-left:5.5pt;margin-bottom:.0001pt;text-indent:30.5pt'><span
  style='font-size:13.0pt;letter-spacing:-.1pt'>Request</span><span
  style='font-size:13.0pt;letter-spacing:-.5pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.1pt'>Parameters:</span><span style='font-size:13.0pt'><o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.25pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:30.0pt;border-collapse:collapse;mso-table-layout-alt:fixed;
   border:none;mso-border-alt:solid black .5pt;mso-yfti-tbllook:480;mso-padding-alt:
   0cm 0cm 0cm 0cm;mso-border-insideh:.5pt solid black;mso-border-insidev:.5pt solid black'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:13.4pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    mso-border-alt:solid black .5pt;background:#D9DFF3;padding:0cm 0cm 0cm 0cm;
    height:13.4pt'>
    <p class=TableParagraph style='margin-left:5.95pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Key<o:p></o:p></span></b></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:6.1pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Type<o:p></o:p></span></b></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Description<o:p></o:p></span></b></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph align=right style='margin-top:0cm;margin-right:32.65pt;
    margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:right;
    line-height:12.4pt;mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:
    normal'><span style='font-size:13.0pt;letter-spacing:-.1pt'>Possible</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
    -.55pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;letter-spacing:-.1pt'>values/Example</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;height:49.85pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:49.85pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.05pt'>apikey</span><span style='font-size:13.0pt;color:#DD1E2C;
    letter-spacing:-.5pt;background:#F8F8F8'> </span><span style='font-size:13.0pt;
    font-family:"Segoe UI","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
    Calibri;color:#DD1E2C;letter-spacing:-.05pt;background:#F8F8F8'>required</span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri'><o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:49.85pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:49.85pt'>
    <p class=TableParagraph style='margin-top:.1pt;margin-right:14.95pt;
    margin-bottom:0cm;margin-left:6.15pt;margin-bottom:.0001pt;line-height:95%'><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>Public</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.95pt'> </span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>key</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.75pt'> </span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>required</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.85pt'> </span><span
    style='font-size:13.0pt;line-height:95%'>to<span style='letter-spacing:-2.35pt'>
    </span>authorize API<span style='letter-spacing:-.05pt'> </span>access<o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:49.85pt'>
    <p class=TableParagraph align=right style='margin-top:.2pt;margin-right:32.15pt;
    margin-bottom:0cm;margin-left:0cm;margin-bottom:.0001pt;text-align:right'><span
    style='font-size:13.0pt'>key-a89e0a1be44d<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:2;height:62.85pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:62.85pt'>
    <p class=TableParagraph style='margin-top:.4pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.05pt'>secrete</span><span style='font-size:13.0pt;
    color:#DD1E2C;letter-spacing:-.5pt;background:#F8F8F8'> </span><span
    style='font-size:13.0pt;font-family:"Segoe UI","sans-serif";mso-hansi-font-family:
    Calibri;mso-bidi-font-family:Calibri;color:#DD1E2C;letter-spacing:-.05pt;
    background:#F8F8F8'>required</span><span style='font-size:13.0pt;font-family:
    "Segoe UI","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
    Calibri'><o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:62.85pt'>
    <p class=TableParagraph style='margin-top:.4pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:62.85pt'>
    <p class=TableParagraph style='margin-top:.35pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.15pt;margin-bottom:.0001pt;line-height:95%'><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>API</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.65pt'> </span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>Secrete</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.7pt'> </span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>key</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.6pt'> </span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.05pt'>required</span><span
    style='font-size:13.0pt;line-height:95%;letter-spacing:-.8pt'> </span><span
    style='font-size:13.0pt;line-height:95%'>to<span style='letter-spacing:-2.3pt'>
    </span>authorize API access<o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:62.85pt'>
    <p class=TableParagraph style='margin-top:.4pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:43.7pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>test-5a8b0b1a<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:3;height:25.9pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-top:.1pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:5.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    font-family:"Segoe UI","sans-serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
    Calibri'>transferType<o:p></o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.45pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt;letter-spacing:
    -.05pt'>Transfer</span><span style='font-size:13.0pt;letter-spacing:-.55pt'> </span><span
    style='font-size:13.0pt'>Type<span style='letter-spacing:-.55pt'> </span>is<span
    style='letter-spacing:-.45pt'> </span>required<span style='letter-spacing:
    -.65pt'> </span>for<o:p></o:p></span></p>
    <p class=TableParagraph style='margin-left:6.15pt;line-height:12.45pt;
    mso-line-height-rule:exactly'><span style='font-size:13.0pt'>amount<span
    style='letter-spacing:2.0pt'> </span>differentiation<o:p></o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:41.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>UPI/IMPS<o:p></o:p></span></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes;height:25.9pt'>
    <td width=113 valign=top style='width:84.7pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-left:0cm'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
    </td>
    <td width=66 valign=top style='width:49.2pt;border-top:none;border-left:none;
    border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;mso-border-top-alt:
    solid black .5pt;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-left:0cm'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
    </td>
    <td width=210 valign=top style='width:157.55pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-left:0cm'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
    </td>
    <td width=211 valign=top style='width:158.4pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:25.9pt'>
    <p class=TableParagraph style='margin-left:0cm'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;
    mso-bidi-font-family:Calibri'><o:p>&nbsp;</o:p></span></p>
    </td>
   </tr>
  </table>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText><b style='mso-bidi-font-weight:normal'><span
  style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.3pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoNormal style='margin-top:0cm;margin-right:391.8pt;margin-bottom:
  0cm;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:30.5pt'><b
  style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'>Response <span
  style='letter-spacing:-.6pt'> </span> JSON:<o:p></o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:35.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Success<span
  style='letter-spacing:-.5pt'> </span>:<span style='letter-spacing:-.25pt'> </span>-&gt;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:71.95pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:71.95pt'><span style='font-size:13.0pt'><span
  style='mso-tab-count:1'>            </span><span style='letter-spacing:-.1pt'>&quot;amount&quot;:&quot;21892.00&quot;,</span><span
  style='letter-spacing:-2.35pt'> </span>&quot;message&quot;:&quot;success&quot;<span
  style='letter-spacing:.05pt'> </span>&quot;statusCode&quot;:200<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:71.95pt'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='text-indent:36.0pt'><span style='font-size:13.0pt'>failed<span
  style='letter-spacing:-.35pt'> </span>:-&gt;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:36.0pt;text-indent:36.0pt'><span
  style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:286.0pt;margin-bottom:
  0cm;margin-left:13.6pt;margin-bottom:.0001pt;text-indent:-2.4pt'><span
  style='font-size:13.0pt'><span style='mso-tab-count:3'>                    </span><span
  style='mso-tab-count:1'>            </span>&quot;amount&quot;:&quot;0&quot;,<span
  style='letter-spacing:.05pt'> </span><span style='letter-spacing:-.05pt'>&quot;message&quot;:&quot;Bad
  </span>Request&quot;,<span style='letter-spacing:-2.35pt'> </span>&quot;statusCode&quot;:306<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:36.0pt;text-indent:36.0pt'><span
  style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:309.9pt;margin-bottom:
  0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:auto;mso-break-type:section-break'>
  </span>
  
  <div class=Section12>
  
  <p class=MsoBodyText style='margin-left:22.05pt'><span style='font-size:13.0pt'>IF<span
  style='letter-spacing:-.3pt'> </span>Apikey<span style='letter-spacing:-.15pt'>
  </span>and<span style='letter-spacing:1.9pt'> </span>secrete<span
  style='letter-spacing:-.1pt'> </span>key<span style='letter-spacing:-.05pt'> </span>is<span
  style='letter-spacing:-.15pt'> </span>mismatch<span style='letter-spacing:-.25pt'>
  </span>:-&gt;<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:71.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.15pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:94.3pt;margin-bottom:.0001pt;line-height:13.25pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;amount&quot;:&quot;0&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:0cm;margin-right:181.4pt;margin-bottom:
  0cm;margin-left:94.3pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;message&quot;:&quot;Please<span
  style='letter-spacing:-.4pt'> </span>check<span style='letter-spacing:-.45pt'> </span>API<span
  style='letter-spacing:-.45pt'> </span>key<span style='letter-spacing:-.45pt'> </span>and<span
  style='letter-spacing:-.5pt'> </span>Secret<span style='letter-spacing:-.5pt'> </span>key&quot;,<span
  style='letter-spacing:-2.35pt'> </span>&quot;statusCode&quot;:304<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:71.95pt'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  </div>
  
  <span style='font-size:13.0pt;font-family:"Calibri","sans-serif";mso-fareast-font-family:
  Calibri;mso-ansi-language:EN-US;mso-fareast-language:EN-US;mso-bidi-language:
  AR-SA'><br clear=all style='page-break-before:always;mso-break-type:section-break'>
  </span>
  
  <div class=Section13>
  
  <p class=MsoBodyText style='margin-top:.45pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:2.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:22.5pt;margin-bottom:.0001pt'><u><span style='font-size:13.0pt;
  letter-spacing:-.4pt'>For</span></u><u><span style='font-size:13.0pt;
  letter-spacing:-1.1pt'> </span></u><u><span style='font-size:13.0pt;letter-spacing:
  -.4pt'>Callback</span></u><u><span style='font-size:13.0pt;letter-spacing:-1.0pt'>
  </span></u><u><span style='font-size:13.0pt;letter-spacing:-.35pt'>URL</span></u><span
  style='font-size:13.0pt;letter-spacing:-1.05pt'> </span><span style='font-size:
  13.0pt;letter-spacing:-.35pt'>:-</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:2.85pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:22.5pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Merchant<span
  style='letter-spacing:-.15pt'> </span>needs<span style='letter-spacing:-.1pt'> </span>to<span
  style='letter-spacing:-.1pt'> </span>share<span style='letter-spacing:-.1pt'> </span>the
  Callback<span style='letter-spacing:-.1pt'> </span>URL<span style='letter-spacing:
  -.1pt'> </span>with<span style='letter-spacing:-.2pt'> </span>the Icepe.<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.3pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <table class=MsoNormalTable border=1 cellspacing=0 cellpadding=0
   style='margin-left:22.2pt;border-collapse:collapse;mso-table-layout-alt:fixed;
   border:none;mso-border-alt:solid black .5pt;mso-yfti-tbllook:480;mso-padding-alt:
   0cm 0cm 0cm 0cm;mso-border-insideh:.5pt solid black;mso-border-insidev:.5pt solid black'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:13.4pt'>
    <td width=76 valign=top style='width:56.9pt;border:solid black 1.0pt;
    mso-border-alt:solid black .5pt;background:#D9DFF3;padding:0cm 0cm 0cm 0cm;
    height:13.4pt'>
    <p class=TableParagraph style='margin-left:6.1pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Type<o:p></o:p></span></b></p>
    </td>
    <td width=219 valign=top style='width:164.45pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:6.05pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Description<o:p></o:p></span></b></p>
    </td>
    <td width=305 valign=top style='width:228.8pt;border:solid black 1.0pt;
    border-left:none;mso-border-left-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    background:#D9DFF3;padding:0cm 0cm 0cm 0cm;height:13.4pt'>
    <p class=TableParagraph style='margin-left:13.6pt;line-height:12.4pt;
    mso-line-height-rule:exactly'><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>Possible</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt;letter-spacing:
    -.6pt'> </span></b><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;letter-spacing:-.05pt'>values/Example</span></b><b
    style='mso-bidi-font-weight:normal'><span style='font-size:13.0pt'><o:p></o:p></span></b></p>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes;height:50.0pt'>
    <td width=76 valign=top style='width:56.9pt;border:solid black 1.0pt;
    border-top:none;mso-border-top-alt:solid black .5pt;mso-border-alt:solid black .5pt;
    padding:0cm 0cm 0cm 0cm;height:50.0pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:6.1pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>string<o:p></o:p></span></p>
    </td>
    <td width=219 valign=top style='width:164.45pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:50.0pt'>
    <p class=TableParagraph style='margin-top:.55pt;margin-right:3.0pt;
    margin-bottom:0cm;margin-left:6.05pt;margin-bottom:.0001pt;line-height:83%'><span
    style='font-size:13.0pt;line-height:83%'>With the Callback URL, merchant<span
    style='letter-spacing:.05pt'> </span>will get the Callback response.<span
    style='letter-spacing:.05pt'> </span>(Mandatory<span style='letter-spacing:
    -.2pt'> </span>for<span style='letter-spacing:-.3pt'> </span>Callback<span
    style='letter-spacing:-.2pt'> </span>response)<o:p></o:p></span></p>
    </td>
    <td width=305 valign=top style='width:228.8pt;border-top:none;border-left:
    none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;
    mso-border-top-alt:solid black .5pt;mso-border-left-alt:solid black .5pt;
    mso-border-alt:solid black .5pt;padding:0cm 0cm 0cm 0cm;height:50.0pt'>
    <p class=TableParagraph style='margin-top:.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:.3pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>https://api.xyzpay.in/callback/icepe/payout<o:p></o:p></span></p>
    </td>
   </tr>
  </table>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.25pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <h2 style='margin-top:.05pt;margin-right:0cm;margin-bottom:0cm;margin-left:
  20.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Response <span
  style='letter-spacing:-.45pt'> </span> JSON : <o:p></o:p></span></h2>
  
  <p class=MsoBodyText style='margin-top:.5pt'><b style='mso-bidi-font-weight:
  normal'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></b></p>
  
  <p class=MsoBodyText style='margin-top:2.8pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:22.5pt;margin-bottom:.0001pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:27.55pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;attempt&quot;:<span
  style='letter-spacing:-.1pt'> </span>1,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:196.5pt;margin-bottom:
  0cm;margin-left:27.55pt;margin-bottom:.0001pt;line-height:98%'><span
  style='font-size:13.0pt;line-height:98%'>&quot;timestamp&quot;:
  &quot;2023-12-06T11:27:19.292247&quot;,<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.05pt'>&quot;callbackTxnId&quot;:</span><span
  style='letter-spacing:-.55pt'> </span>&quot;AD65F2FA25CA4A538C7C78F55E70D24F&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:77.45pt;margin-bottom:
  0cm;margin-left:27.55pt;margin-bottom:.0001pt;line-height:98%'><span
  style='font-size:13.0pt;line-height:98%;letter-spacing:-.05pt'>&quot;originalCallbackTxnId&quot;:
  </span><span style='font-size:13.0pt;line-height:98%'>&quot;AD65F2FA25CA4A538C7C78F55E70D24F&quot;,<span
  style='letter-spacing:-2.35pt'> </span>&quot;transactionStatus&quot;:<span
  style='letter-spacing:-.05pt'> </span>&quot;success&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.1pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:27.55pt;margin-bottom:.0001pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;referenceId&quot;:<span
  style='letter-spacing:-.25pt'> </span>&quot;SWIPEXYZXM&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:27.55pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;decentroTxnId&quot;:<span
  style='letter-spacing:-.65pt'> </span>&quot;591279EC18FA4CA281C2CC87438F7687&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.05pt;margin-right:258.7pt;margin-bottom:
  0cm;margin-left:27.55pt;margin-bottom:.0001pt;line-height:98%'><span
  style='font-size:13.0pt;line-height:98%'>&quot;transactionMessage&quot;:
  &quot;For testing&quot;,<span style='letter-spacing:.05pt'> </span>&quot;transferType&quot;:
  &quot;IMPS&quot;,<span style='letter-spacing:.05pt'> </span><span
  style='letter-spacing:-.05pt'>&quot;bankReferenceNumber&quot;:</span><span
  style='letter-spacing:.1pt'> </span>&quot;334011887395&quot;,<span
  style='letter-spacing:-2.35pt'> </span>&quot;beneficiary Name&quot;: &quot;Mrs.
  XYZ CHAVAN&quot;,<span style='letter-spacing:.05pt'> </span>&quot;transactionAmount&quot;:
  8700,<span style='letter-spacing:.05pt'> </span>&quot;providerMessage&quot;:<span
  style='letter-spacing:-.15pt'> </span>null,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-top:.3pt;margin-right:0cm;margin-bottom:
  0cm;margin-left:27.55pt;margin-bottom:.0001pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;errorKey&quot;:<span
  style='letter-spacing:-.1pt'> </span>null<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>Excepected Response Json from merchant <o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>“status&quot;:&quot;CB_S00000&quot;,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>&quot;statusCode&quot;:200,<o:p></o:p></span></p>
  
  <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
  exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
  </div>
  
  </body>
  
  </html>
  `;
  return (
    <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
      <Container>
        <div className="ds-header-sec">
          <Row>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success mb-3" onClick={goBack}>
                {" "}
                &#8592; Back
              </button>
            </div>

            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4 ds-header-card">
                <Card.Body>
                  <div
                    className="merchant-tab-btn  p-3  "
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  ></div>
                </Card.Body>
              </Card>
              {/* ./card */}
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-3" onClick={goBack}>
              {" "}
              &#8592; Back
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default PayoutDocs;
