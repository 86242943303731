import React, { useEffect, useState } from "react";
import MyLoader from "../../common/MyLoader";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import {
  fetchMerchantProfileData,
  verifyMerchantEmailIsVerifiedOrNot,
  verifyMerchantMobileIsVerifiedOrNot,
} from "../../services/apiService";
import "../../assets/css/scrollbar.css";
import { Chip, ListItem, Paper } from "@mui/material";
import swal from "sweetalert";

import {
  getKeyPressed,
  isEmailIdRegexIsValid,
  isIfscRegexIsValid,
  isPanNumRegexIsValid,
  isWebsiteURLRegexIsValid,
  stopAlphabetInput,
  stopNumberCharInput,
  stopSpecialCharacterInput,
} from "../../utils/validations";
import { SWIPELINC_API } from "../../utils/constant";
import axios from "axios";
import "../../assets/css/scrollbar.css";

const MyProfile = () => {
  const [merchantData, setMerchantData] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [updatedDetails, setUpdatedDetails] = useState(null);
  const [pressedKeyName, setPressedKeyName] = useState("");
  // const [registrationType, setRegistrationTypes] = useState([]);
  // const [businessCategories, setBusinessCategories] = useState([]);
  // const [businessType, setBusinessTypes] = useState([]);
  // const [paymentTypes, setPaymentTypes] = useState([]);
  // const [previousServices, setPerviousServices] = useState([]);

  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const { mid } = jwtDecode(token);

  const navigate = useNavigate();

  const getProfileDetails = async (mid) => {
    let res = await fetchMerchantProfileData(mid, token);
    console.log("res", res.data);
    setMerchantData(res.data);
  };

  const ShowAlert = () => {
    swal({
      title: "Alert!",
      text: "Merchant not onboarded.",
      icon: "error",
    });
  };

  const toggleEditing = () => {
    if (!isEditing) {
      setUpdatedDetails(merchantData);
    
    }
    setIsEditing(!isEditing);
  };

 const handelSave = async () => {
    
   // if (
    //   updatedDetails.authorizedPersonFirstName === "" ||
    //   updatedDetails.authorizedPersonFirstName === null
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Authorized Person FirstName.",
    //     icon: "error",
    //   });
    //   return;
    // }

    // if (
    //   updatedDetails.authorizedPersonLastName === "" ||
    //   updatedDetails.authorizedPersonLastName === null
    // ) {
    //   swal({
    //     title: "Alert!",
    //     text: "Please Enter Authorized Person LastName.",
    //     icon: "error",
    //   });
    //   return;
    // }

  //  if (
  //     updatedDetails.merchantBankName === "" ||
  //     updatedDetails.merchantBankName === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Bank Name.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.merchantAccountNumber === "" ||
  //     updatedDetails.merchantAccountNumber === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Account Number.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.merchantIfscCode === "" ||
  //     updatedDetails.merchantIfscCode === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter IFSC Code.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.companyAddress === "" ||
  //     updatedDetails.companyAddress === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Company Registered Address.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.companyAddressPinCode === "" ||
  //     updatedDetails.companyAddressPinCode === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Pin Code.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.companyAddressCity === "" ||
  //     updatedDetails.companyAddressCity === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter City.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.companyAddressState === "" ||
  //     updatedDetails.companyAddressState === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter State.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.registeredOfficeAddress === "" ||
  //     updatedDetails.registeredOfficeAddress === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Authorized Signatory Address.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.registeredOfficeAddressPinCode === "" ||
  //     updatedDetails.registeredOfficeAddressPinCode === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Pin Code.",
  //       icon: "error",
  //     });
  //     return;
  //   }
  //   if (
  //     updatedDetails.registeredOfficeAddressCity === "" ||
  //     updatedDetails.registeredOfficeAddressCity === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter City.",
  //       icon: "error",
  //     });
  //     return;
  //   }
  //   if (
  //     updatedDetails.registeredOfficeAddressState === "" ||
  //     updatedDetails.registeredOfficeAddressState === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter State.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.communicationAddress === "" ||
  //     updatedDetails.communicationAddress === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Company Communication Address.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.communicationAddressPinCode === "" ||
  //     updatedDetails.communicationAddressPinCode === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter Pin Code.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.communicationAddressCity === "" ||
  //     updatedDetails.communicationAddressCity === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter City.",
  //       icon: "error",
  //     });
  //     return;
  //   }

  //   if (
  //     updatedDetails.communicationAddressState === "" ||
  //     updatedDetails.communicationAddressState === null
  //   ) {
  //     swal({
  //       title: "Alert!",
  //       text: "Please Enter State.",
  //       icon: "error",
  //     });
  //     return;
  //   }

    console.log("updatedDetails", updatedDetails);
   
    const payload = {
      // aadharNumber: updatedDetails.aadharNumber,
      aapUrl: updatedDetails.aapUrl,
      // authorizedPersonFirstName: updatedDetails.authorizedPersonFirstName,
      // authorizedPersonLastName: updatedDetails.authorizedPersonLastName,
      // authorizedPersonMiddleName: updatedDetails.authorizedPersonMiddleName,
      // authorizedPersonPan: updatedDetails.authorizedPersonPan,
      businessAs: updatedDetails.businessAs,
      callBackUrl: updatedDetails.callBackUrl,
      chargebackCallbackUrl:updatedDetails.chargebackCallbackUrl,
      communicationAddress: updatedDetails.communicationAddress,
      communicationAddressCity: updatedDetails.communicationAddressCity,
      communicationAddressPinCode: updatedDetails.communicationAddressPinCode,
      communicationAddressState: updatedDetails.communicationAddressState,
      companyAddress: updatedDetails.companyAddress,
      companyAddressCity: updatedDetails.companyAddressCity,
      companyAddressPinCode: updatedDetails.companyAddressPinCode,
      companyAddressState: updatedDetails.companyAddressState,
      // companyPanNumber: updatedDetails.companyPanNumber,
      // email: updatedDetails.email,
      //  gstn: updatedDetails.gstNumber,
      merchantAccountNumber: updatedDetails.merchantAccountNumber,
      merchantBankName: updatedDetails.merchantBankName,
      merchantIfscCode: updatedDetails.merchantIfscCode,
      mid: updatedDetails.mid,
      payoutCallbackUrl: updatedDetails.payoutCallbackUrl,
      registeredOfficeAddress: updatedDetails.registeredOfficeAddress,
      registeredOfficeAddressCity: updatedDetails.registeredOfficeAddressCity,
      registeredOfficeAddressPinCode: updatedDetails.registeredOfficeAddressPinCode,
      registeredOfficeAddressState: updatedDetails.registeredOfficeAddressState,
      websiteUrl: updatedDetails.websiteUrl,
      };

    try {
      const response = await axios.put(
        `${SWIPELINC_API}MerchantPanel/merchant/merchant-profile/updateProfile`,
        payload,
        {
          headers: {
            Authentication: "Bearer " + token,
          },
        }
      );
      console.log("response----", response);

      if (response.data.statusCode === 200) {
        swal({
          title: "Success!",
          text: "Your Profile Updated Successfully*",
          icon: "success",
        });
        setMerchantData(updatedDetails);
        setIsEditing(false);
      } else {
        swal({
          title: "Alert!",
          text: response.data.message,
          icon: "error",
        });
      }

      // const data = response.data;
      // setBusinessTypes(data.data);
    } catch (error) {
      console.log("Error fetching business types:", error);
    }

    console.log("PAYLOAD - ", payload);
  };

  const handelInputEvent = (e) => {
    const { name, value } = e.target;

    if (pressedKeyName === "Backspace") {
      setUpdatedDetails({ ...updatedDetails, [name]: value });
    }

    // stop Special Char Input
    if (
      stopSpecialCharacterInput(pressedKeyName) &&
      // name === "companyName" ||
      (name === "businessAs" ||
        // name === "mobile" ||
        name === "aadharNumber" ||
        name === "companyAddressPinCode" ||
        name === "companyAddressCity" ||
        name === "companyAddressState" ||
        name === "registeredOfficeAddressPinCode" ||
        name === "registeredOfficeAddressCity" ||
        name === "registeredOfficeAddressState" ||
        name === "communicationAddressPinCode" ||
        name === "communicationAddressCity" ||
        name === "communicationAddressState" ||
        name === "merchantBankName" ||
        name === "merchantAccountNumber" ||
        name === "merchantIfscCode" ||
        name === "authorizedPersonLastName" ||
        name === "authorizedPersonFirstName")
    )
      return;

    // stop number input
    if (
      stopNumberCharInput(pressedKeyName) &&
      // name === "companyName" ||
      (name === "businessAs" ||
        name === "companyAddressCity" ||
        name === "companyAddressState" ||
        name === "registeredOfficeAddressCity" ||
        name === "registeredOfficeAddressState" ||
        name === "communicationAddressCity" ||
        name === "communicationAddressState" ||
        name === "merchantBankName" ||
        name === "authorizedPersonLastName" ||
        name === "authorizedPersonFirstName")
    )
      return;

    if (
      stopAlphabetInput(pressedKeyName) &&
      // name === "mobile" ||
      (name === "aadharNumber" ||
        name === "companyAddressPinCode" ||
        name === "registeredOfficeAddressPinCode" ||
        name === "communicationAddressPinCode" ||
        name === "merchantAccountNumber")
    )
      return;

 if (updatedDetails.aadharNumber?.length >= 12 && name === "aadharNumber")
      return;

    if (
      updatedDetails.companyAddressPinCode?.length >= 6 &&
      name === "companyAddressPinCode"
    )
      return;

    if (
      updatedDetails.registeredOfficeAddressPinCode?.length >= 6 &&
      name === "registeredOfficeAddressPinCode"
    )
      return;

    if (
      updatedDetails.communicationAddressPinCode?.length >= 6 &&
      name === "communicationAddressPinCode"
    )
      return;

    const addressFieldLength = 200;
    const pincodeFieldLength = 6;
    const StateLength = 50;
    const cityLength = 50;

    if (
      (updatedDetails.companyAddress?.length >= addressFieldLength &&
        name === "companyAddress") ||
      (updatedDetails.registeredOfficeAddress?.length >= addressFieldLength &&
        name === "registeredOfficeAddress") ||
      (updatedDetails.communicationAddress?.length >= addressFieldLength &&
        name === "communicationAddress") ||
      (updatedDetails.companyAddressPinCode?.length >= pincodeFieldLength &&
        name === "companyAddressPinCode") ||
      (updatedDetails.registeredOfficeAddressPinCode?.length >=
        pincodeFieldLength &&
        name === "registeredOfficeAddressPinCode") ||
      (updatedDetails.communicationAddressPinCode?.length >=
        pincodeFieldLength &&
        name === "communicationAddressPinCode") ||
      (updatedDetails.companyAddressState?.length >= StateLength &&
        name === "companyAddressState") ||
      (updatedDetails.registeredOfficeAddressState?.length >= StateLength &&
        name === "registeredOfficeAddressState") ||
      (updatedDetails.communicationAddressState?.length >= StateLength &&
        name === "communicationAddressState") ||
      (updatedDetails.companyAddressCity?.length >= cityLength &&
        name === "companyAddressCity") ||
      (updatedDetails.registeredOfficeAddressCity?.length >= cityLength &&
        name === "registeredOfficeAddressCity") ||
      (updatedDetails.communicationAddressCity?.length >= cityLength &&
        name === "communicationAddressCity")
    )
      return;

    setUpdatedDetails({ ...updatedDetails, [name]: value });
  };

  // const verifyEmail = async (email) => {
  //   let emailRes = await verifyMerchantEmailIsVerifiedOrNot(email);
  //   // console.log("emailRes", emailRes.statusCode);
  //   if (emailRes.statusCode === 200)
  //     swal({
  //       title: "Alert!",
  //       text: "Email Already registered*",
  //       icon: "error",
  //     });
  //   setUpdatedDetails({ ...updatedDetails, email: "" });
  //   return;
  // };

  // const verifyMobile = async (mobile) => {
  //   let mobRes = await verifyMerchantMobileIsVerifiedOrNot(mobile);
  //   // console.log("mobRes", mobRes.statusCode);
  //   if (mobRes.statusCode === 200)
  //     swal({
  //       title: "Alert!",
  //       text: "Mobile Number Already Registered !",
  //       icon: "error",
  //     });
  //   setUpdatedDetails({ ...updatedDetails, mobile: "" });
  //   return;
  // };

  const handelValidations = (e) => {
    // if (e.target.name === "email" && e.target.value !== "") {
    //   if (!isEmailIdRegexIsValid(updatedDetails.email)) {
    //     swal({
    //       title: "Alert!",
    //       text: "Please Enter Valid Merchant E-Mail ID !",
    //       icon: "error",
    //     }).then(() => {
    //       setUpdatedDetails({ ...updatedDetails, email: "" });
    //     });
    //     return;
    //   } else {
    //     verifyEmail(e.target.value)
    //   }
    // }

    // if(e.target.name === "mobNum" && e.target.value !=="") {
    //   verifyMobile(e.target.value)
    // }

    if (e.target.name === "companyPanNumber" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(updatedDetails.companyPanNumber)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Company PAN Number !",
          icon: "error",
        }).then(() => {
          setUpdatedDetails({ ...updatedDetails, companyPanNumber: "" });
        });
        return;
      }
    }

    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(updatedDetails.email)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Email ID !",
          icon: "error",
        }).then(() => {
          setUpdatedDetails({ ...updatedDetails, email: "" });
        });
        return;
      }
    }

    if (e.target.name === "merchantIfscCode" && e.target.value !== "") {
      if (!isIfscRegexIsValid(updatedDetails.merchantIfscCode)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid IFSC Code!",
          icon: "error",
        }).then(() => {
          setUpdatedDetails({ ...updatedDetails, merchantIfscCode: "" });
        });
        return;
      }
    }

    if (e.target.name === "authorizedPersonPan" && e.target.value !== "") {
      if (!isPanNumRegexIsValid(updatedDetails.authorizedPersonPan)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Authorized Person PAN Number !",
          icon: "error",
        }).then(() => {
          setUpdatedDetails({ ...updatedDetails, authorizedPersonPan: "" });
        });
        return;
      }
    }

    if (e.target.name === "websiteUrl" && e.target.value !== "") {
      if (!isWebsiteURLRegexIsValid(updatedDetails.websiteUrl)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Website URL!",
          icon: "error",
        }).then(() => {
          setUpdatedDetails({ ...updatedDetails, websiteUrl: "" });
        });
        return;
      }
    }
  };

  const handelPasteEvent = (event, name) => {
    let pastedText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    const addressFieldLength = 200;
    const StateLength = 50;
    const cityLength = 50;

    if (
      (name === "companyAddress" &&
        updatedDetails.companyAddress?.length + pastedText?.length >
          addressFieldLength) ||
      (name === "communicationAddress" &&
        updatedDetails.communicationAddress?.length + pastedText?.length >
          addressFieldLength) ||
      (name === "registeredOfficeAddress" &&
        updatedDetails.registeredOfficeAddress?.length + pastedText?.length >
          addressFieldLength)
    ) {
      swal({
        title: "Alert!",
        text: `Address must be lesser than ${addressFieldLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "companyAddressState" &&
        updatedDetails.companyAddressState?.length + pastedText?.length >
          StateLength) ||
      (name === "registeredOfficeAddressState" &&
        updatedDetails.registeredOfficeAddressState?.length +
          pastedText?.length >
          StateLength) ||
      (name === "communicationAddressState" &&
        updatedDetails.communicationAddressState?.length + pastedText?.length >
          StateLength)
    ) {
      swal({
        title: "Alert!",
        text: `State must be lesser than ${StateLength} characters`,
        icon: "error",
      });
      return;
    }

    if (
      (name === "registeredOfficeAddressCity" &&
        updatedDetails.registeredOfficeAddressCity?.length +
          pastedText?.length >
          cityLength) ||
      (name === "companyAddressCity" &&
        updatedDetails.companyAddressCity?.length + pastedText?.length >
          cityLength) ||
      (name === "communicationAddressCity" &&
        updatedDetails.communicationAddressCity?.length + pastedText?.length >
          cityLength)
    ) {
      swal({
        title: "Alert!",
        text: `City must be lesser than ${cityLength} characters`,
        icon: "error",
      });
      return;
    }
  };

  // useEffect(() => {
  //   // fetchRegistrationTypes();
  //   // fetchBusinessCategories();
  //   // fetchBusinessTypes();
  //   // fetchPaymentTypes();
  // }, [isEditing]);

  useEffect(() => {
    getProfileDetails(mid);
  }, []);
  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="admin-view-details">
        <Container>
          <Card className="shadow border-0 mb-4 ds-header-card">
            <Card.Body>
              <Row>
                <Col xs={12} xl={12} lg={12} sm={12}>
                  <div className="two-column-layout">
                    {isEditing ? (
                      ""
                    ) : (
                      <>
                        <div className="vertical-table mb-4">
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company Name
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.companyName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Merchant ID
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.mid}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Registration Type
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.registrationType}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Business Category
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.businessType}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Other Business Category
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.businessTypeName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Business Type
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.companyType}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Business As
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.businessAs}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Bank Name
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.merchantBankName}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Account Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.merchantAccountNumber}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              IFSC Code
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.merchantIfscCode}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company PAN Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.companyPanNumber}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              GST Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.gstNumber}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Authorized Person Name
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.authorizedPersonFirstName}{" "}
                              {/* {merchantData?.authorizedPersonMiddleName}{" "} */}
                              {merchantData?.authorizedPersonLastName}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              e-Mail ID
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.email}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Mobile Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.mobile}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Authorized Person PAN
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.authorizedPersonPan}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Aadhaar Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.aadharNumber}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Username
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.userName}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payin Encryption Key
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.encKey}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout API Key
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.apiKey}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout Secret Key
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.secretKey}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company Registered Address
                            </div>
                            <div className="vertical-table-cell-value  text-left scrollbar overflow-x-scroll ">
                              {merchantData?.companyAddress}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              {" "}
                              Pin Code
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.companyAddressPinCode}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              City
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.companyAddressCity}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left ">
                              State
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.companyAddressState}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payin callBack URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.callBackUrl}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payout CallBack URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.payoutCallbackUrl}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Chargeback CallBack URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.chargebackCallbackUrl}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Website URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.websiteUrl}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Application URL
                            </div>
                            <div className="vertical-table-cell-value text-left">
                              {merchantData?.aapUrl}
                            </div>
                          </div>
                        </div>

                        <div className="vertical-table">
                          {/* Second half of the table */}

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left ">
                              Authorized Signatory Address
                            </div>
                            <div className="vertical-table-cell-value  text-left scrollbar overflow-x-scroll">
                              {merchantData?.registeredOfficeAddress}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Pin Code
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.registeredOfficeAddressPinCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              City
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.registeredOfficeAddressCity}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              State
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.registeredOfficeAddressState}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Company Communication Address
                            </div>
                            <div className="vertical-table-cell-value  text-left scrollbar overflow-x-scroll">
                              {merchantData?.communicationAddress}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Pin Code
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.communicationAddressPinCode}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              City
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.communicationAddressCity}
                            </div>
                          </div>
                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              State
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              {merchantData?.communicationAddressState}
                            </div>
                          </div>

                          <div className="vertical-table-row">
                            <div className="vertical-table-cell text-left">
                              Payment Services
                            </div>
                            <div className="vertical-table-cell-value text-left d-flex flex-wrap">
                              {merchantData?.paymentService?.map((service) => {
                                return (
                                  <Chip
                                    label={service.paymentService}
                                    variant="outlined"
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {isEditing ? (
                      <>
                        <div className="vertical-table mb-4">
                        <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Business As
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.businessAs}
                                className="form-control"
                                name="businessAs"
                                onChange={handelInputEvent}
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                              />
                            </div>
                          </div>

                          {/* {merchantData.companyType === "PROPERITARY" ? (
                            ""
                          ) : (
                            <div className="vertical-table-row ">
                              <div className="vertical-table-cell text-left">
                                Company PAN Number
                                <sup className="text-danger">*</sup>
                              </div>
                              <div className="vertical-table-cell-value  text-left">
                                <input
                                  type="text"
                                  value={updatedDetails.companyPanNumber}
                                  className="form-control"
                                  name="companyPanNumber"
                                  onChange={handelInputEvent}
                                  onBlur={handelValidations}
                                  onKeyDown={(e) => {
                                    if (e.key === " ") {
                                      e.preventDefault();
                                    }
                                    setPressedKeyName(getKeyPressed(e));
                                  }}
                                />
                              </div>
                            </div>
                          )}

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              GST Number
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.gstNumber}
                                className="form-control"
                                name="gstNumber"
                                onChange={handelInputEvent}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Authorized Person First Name
                              <sup className="text-danger">*</sup>
                            </div>

                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.authorizedPersonFirstName}
                                name="authorizedPersonFirstName"
                                onChange={handelInputEvent}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Authorized Person Last Name
                              <sup className="text-danger">*</sup>
                            </div>

                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.authorizedPersonLastName}
                                name="authorizedPersonLastName"
                                onChange={handelInputEvent}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              e-Mail ID<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.email}
                                className="form-control"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                onBlur={handelValidations}
                                onChange={handelInputEvent}
                                name="email"
                              />
                            </div>
                          </div> */}

                          {/* <div className="vertical-table-row ">
                          <div className="vertical-table-cell text-left">
                            Mobile Number
                          </div>
                          <div className="vertical-table-cell-value  text-left">
                            <input
                              type="text"
                              value={updatedDetails.mobile}
                              className="form-control"
                              onKeyDown={(e) => {
                                if (e.key === " ") {
                                  e.preventDefault();
                                }
                                setPressedKeyName(getKeyPressed(e));
                              }}
                              name="mobile"
                              onChange={handelInputEvent}
                            />
                          </div>
                        </div> */}

                          {/* <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Authorized Person PAN
                              <sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.authorizedPersonPan}
                                className="form-control"
                                onBlur={handelValidations}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="authorizedPersonPan"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Aadhaar Number<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.aadharNumber}
                                className="form-control"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="aadharNumber"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div> */}

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Bank Name<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.merchantBankName}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="merchantBankName"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Account Number<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.merchantAccountNumber}
                                className="form-control"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="merchantAccountNumber"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              IFSC Code<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.merchantIfscCode}
                                className="form-control"
                                onBlur={handelValidations}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="merchantIfscCode"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                        

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Company Registered Address
                              <sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.companyAddress}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(e, "companyAddress")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                onChange={handelInputEvent}
                                name="companyAddress"
                              />
                            </div>

                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 - updatedDetails.companyAddress?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Pin Code<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.companyAddressPinCode}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="companyAddressPinCode"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              City<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.companyAddressCity}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(e, "companyAddressCity")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="companyAddressCity"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              State<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.companyAddressState}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(e, "companyAddressState")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="companyAddressState"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Payin callBack URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.callBackUrl}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="callBackUrl"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Payout callBack URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.payoutCallbackUrl}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="payoutCallbackUrl"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>
                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Chargeback CallBack URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.chargebackCallbackUrl}
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="chargebackCallbackUrl"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>


                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Website URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.websiteUrl}
                                className="form-control"
                                onBlur={handelValidations}
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="websiteUrl"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Application URL
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.aapUrl}
                                className="form-control"
                                onKeyDown={(e) => {
                                  if (e.key === " ") {
                                    e.preventDefault();
                                  }
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="aapUrl"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Authorized Signatory Address
                              <sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.registeredOfficeAddress}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(e, "registeredOfficeAddress")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="registeredOfficeAddress"
                                onChange={handelInputEvent}
                              />
                            </div>

                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 -
                                  updatedDetails.registeredOfficeAddress?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Pin Code<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={
                                  updatedDetails.registeredOfficeAddressPinCode
                                }
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="registeredOfficeAddressPinCode"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              City<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={
                                  updatedDetails.registeredOfficeAddressCity
                                }
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "registeredOfficeAddressCity"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="registeredOfficeAddressCity"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              State<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={
                                  updatedDetails.registeredOfficeAddressState
                                }
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "registeredOfficeAddressState"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="registeredOfficeAddressState"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Company Communication Address
                              <sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.communicationAddress}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(e, "communicationAddress")
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="communicationAddress"
                                onChange={handelInputEvent}
                              />
                            </div>

                            <div className="d-flex justify-content-end">
                              <span className="text-danger">
                                {`Remaining Characters ${
                                  200 -
                                  updatedDetails.communicationAddress?.length
                                } / 200`}{" "}
                              </span>
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              Pin Code<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={
                                  updatedDetails.communicationAddressPinCode
                                }
                                className="form-control"
                                onKeyDown={(e) => {
                                  setPressedKeyName(getKeyPressed(e));
                                }}
                                name="communicationAddressPinCode"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              City<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.communicationAddressCity}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "communicationAddressCity"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="communicationAddressCity"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          <div className="vertical-table-row ">
                            <div className="vertical-table-cell text-left">
                              State<sup className="text-danger">*</sup>
                            </div>
                            <div className="vertical-table-cell-value  text-left">
                              <input
                                type="text"
                                value={updatedDetails.communicationAddressState}
                                className="form-control"
                                onPaste={(e) =>
                                  handelPasteEvent(
                                    e,
                                    "communicationAddressState"
                                  )
                                }
                                onKeyDown={(e) =>
                                  setPressedKeyName(getKeyPressed(e))
                                }
                                name="communicationAddressState"
                                onChange={handelInputEvent}
                              />
                            </div>
                          </div>

                          {/* <div className="vertical-table-row overflow-x-scroll">
                          <div className="vertical-table-cell text-left">
                          Payment Services
                          </div>
                          <div className="vertical-table-cell-value text-left">
                            {paymentTypes?.map((service) => {
                              return (
                                <Chip
                                  label={service.paymentservice}
                                  variant="outlined"
                                />
                              );
                            })}
                          </div>
                          
                        </div> */}
                        </div>
                        {/* <div className="vertical-table-row  w-100">
                        <div className="vertical-table-cell text-left">
                          Payment Services
                        </div>
                        <div className="vertical-table-cell-value text-left overflow-x-scroll scrollbar">
                          <Row>
                            <Col xs={12} lg={12} md={12}>
                              <div className="form-group">
                                <div className="checkbox-select-services">
                                  {paymentTypes?.map((paymentType) => (
                                    <div
                                      className="checkservices-type"
                                      key={paymentType.paymentTypeId}
                                    >
                                      <input
                                        type="checkbox"
                                        style={{ border: "1px solid grey" }}
                                        name="typeOfServices"
                                        className="form-check-input"
                                        value={paymentType.paymentservice}
                                        checked={
                                          previousServices?.includes(
                                            paymentType.paymentservice
                                          )
                                            ? true
                                            : false
                                        }
                                        onChange={handleServiceChange}
                                      />
                                      <label className="form-label">
                                        {paymentType.paymentservice}
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div> */}
                      </>
                    ) : null}
                    <div className="button-toolbar groups-btn text-end">
                      {isEditing ? (
                        <>
                          <button
                            className="btn btn-success"
                            onClick={handelSave}
                          >
                            Update
                          </button>

                          <button
                            className="btn btn-danger"
                            onClick={() => setIsEditing(false)}
                          >
                            cancel
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-primary"
                          onClick={
                            merchantData === null ? ShowAlert : toggleEditing
                          }
                        >
                          Edit
                        </button>
                      )}

                      <button
                        className="btn btn-primary"
                        onClick={() => navigate(-1)}
                      >
                        Go Back
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>
    </>
  );
};

export default MyProfile;
