import { combineReducers, configureStore } from "@reduxjs/toolkit";
import reportSlice from "./slices/reportSlice";
import privilagesSlice from "./slices/privilagesSlice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import onBoardSlice from "./slices/onBoardSlice";


const reportReducer = reportSlice ;
const privilagesReducer = privilagesSlice ;
const onBoardReducer = onBoardSlice ;

const rootReducer = combineReducers({
  report: reportReducer,
  privilages: privilagesReducer,
  onBoard: onBoardReducer,
});

const persistConfig = {
  key: "root", // key is the name of the key in storage
  storage, // storage is the storage engine to use
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

// Create the persistor
const persistor = persistStore(store);

export { store, persistor };

 