
import SwipelincLogo from '../assets/images/SwipelincLogo.png'
import LincpayLogo from '../assets/images/LincpayLogo.jpeg'
import SilverPeLogo from '../assets/images/SilverPeLogo.jpeg'
import icepeLogo from '../assets/images/icepeLogo.png'
import LotusLogo from '../assets/images/LotusPayments.png'
import { useLocation } from 'react-router-dom';
import swal from 'sweetalert';

export const dateConvert = (date) => {
  const date1 = new Date(date);
  const formattedDate = date1.toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

export const convertDateFormat = (inputDate) => {
  if (!inputDate) {
    return null;
  }
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const getServerEnvironment = (hostName) => {
  
  if(hostName === "login.lincpay.in") return "productionLincpay"
  else if( hostName ==="login.swipelinc.com") return "productionSwipelinc"
  else if(hostName === "login.icepe.in") return "productionIcepe"
  else if(hostName === "login.silverpe.in") return "productionSilverPe"
  else if(hostName === "login.lotuspayments.online") return "productionLotus"
  else if(hostName === "localhost" || hostName ==="uatmerchant1.lincpay.in")  return "development"
  else {
    swal({
      title: "ALERT!",
      text: `Invalid Host "${hostName}" !! Please Contact to Admin`,
      icon: "error",
    }).then(() => {
      return;
    })
    return
  }
  

}

export function getCompleteDomain() {
  const { protocol, hostname, port } = window.location;
  console.log("HostName >>>>", hostname);
  // return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
  return hostname;
}

export const config= (serverEnvironment) => {

  // console.log("LOG FROM CONFIG FN >>>", serverEnvironment);

  const logos = {
    productionLincpay: LincpayLogo,
    productionSilverPe: SilverPeLogo,
    productionSwipelinc: SwipelincLogo,
    productionLotus: LotusLogo,
    development: LincpayLogo,
    productionIcepe: icepeLogo,
    
  };
  const bankDetails = {
    productionLincpay:{
      BName: "LincPay Solutions Private Limited",
      bankName: "IDFC Bank",
      branch: "Bhopal Kolar Road",
      Account: "10152683089",
      ifsc: "IDFB0041386",
    } ,
    productionSwipelinc: {
      BName: "Zenex e-Commerce Private Limited",
      bankName: "AU Small Finance Bank",
      branch: "Malad Branch",
      Account: "2021234226901022",
      ifsc: "AUBL0002342",
    },
    development: {
      BName: "LincPay Solutions Private Limited",
      bankName: "IDFC Bank",
      branch: "Bhopal Kolar Road",
      Account: "10152683089",
      ifsc: "IDFB0041386",
    } ,
    productionLotus: {
      BName: "LincPay Solutions Private Limited",
      bankName: "IDFC Bank",
      branch: "Bhopal Kolar Road",
      Account: "10152683089",
      ifsc: "IDFB0041386",
    } ,
    
    productionSilverPe: {
      BName: " XYZ Pvt. Ltd.",
      bankName: "XYZ Bank",
      branch: "XYZ Branch",
      Account: "000XXXXXXXX000",
      ifsc: "ABCD00000",
    } ,
    productionIcepe: {
      BName: "ICEWEB TECHNOLOGY SOLUTIONS (OPC) PVT LTD",
      bankName: "IDFC FIRST BANK",
      branch: "SEAWOODS BRANCH",
      Account: "10151493000",
      ifsc: "IDFB0040158",
    }
  };
  const Names = {
    productionLincpay: "Lincpay",
    productionSilverPe: "SilverPe",
    productionSwipelinc: "Swipelinc",
    productionLotus: "Lotus Payments",
    development: "Lincpay",
    productionIcepe: "Icepe",

  };

  const apiDocsUrl = {
    productionIcepe: {
      paymentIntegrationKitUrl:"https://pg.icepe.in/paymentrequest/seamless"
    },
    productionSilverPe: {
      paymentIntegrationKitUrl:"https://payment.silverpe.in/paymentrequest/seamless"
    },
    development: {
      paymentIntegrationKitUrl:"https://pg.icepe.in/paymentrequest/seamless"
    },
    productionLotus: {
      paymentIntegrationKitUrl:"https://pg.icepe.in/paymentrequest/seamless"
    },
    productionLincpay: {
      paymentIntegrationKitUrl:"https://pg.icepe.in/paymentrequest/seamless"
    },
    productionSwipelinc: {
      paymentIntegrationKitUrl:"https://pg.icepe.in/paymentrequest/seamless"
    },
  }
  const Urls = {
    productionLincpay: 'https://api.lincpay.in/',
    productionSwipelinc: 'https://api.icepe.in/',
    productionSilverPe: 'https://api.icepe.in/',
    development: 'https://uatmerchant.lincpay.in/',
    productionLotus: 'https://uatmerchant.lincpay.in/',
    productionIcepe: 'https://api.icepe.in/',
  };
  const  logoPath = logos[serverEnvironment] ;
  const  Name = Names[serverEnvironment] ;
  const  Url = Urls[serverEnvironment] ;
  const  bankDeatails = bankDetails[serverEnvironment] ;
  const panelApiDocsUrl = apiDocsUrl[serverEnvironment];
  
  return {
    logoPath,
    Name,
    Url,
    bankDeatails,
    panelApiDocsUrl
  }

}

export const getMonthName = (monthNumber) => {
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  // Check if the monthNumber is valid (between 1 and 12)
  if (monthNumber >= 1 && monthNumber <= 12) {
    // Array indices are 0-based, so subtract 1 from the monthNumber
    return months[monthNumber];
  } else {
    return 'Invalid month number';
  }
}

export const convertStringDateToDateType = (inputDateString) => {
  const dateParts = inputDateString.split(" ");

  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };

  const day = parseInt(dateParts[2], 10);
  const month = months[dateParts[1]];
  const year = parseInt(dateParts[5], 10);
  const hours = parseInt(dateParts[3].split(":")[0], 10);
  const minutes = parseInt(dateParts[3].split(":")[1], 10);
  const seconds = parseInt(dateParts[3].split(":")[2], 10);

  const date = new Date(year, month, day, hours, minutes, seconds);
  return date
};

export const convertDate=(date )=>{
  // Input string
const dateString =date ;

// Create a map to convert month names to numerical values
const monthMap = {
  'Jan': 1, 'Feb': 2, 'Mar': 3, 'Apr': 4, 'May': 5, 'Jun': 6,
  'Jul': 7, 'Aug': 8, 'Sep': 9, 'Oct': 10, 'Nov': 11, 'Dec': 12
};

// Split the input string into components
const dateComponents = dateString.split(' ');

// Extract day, month, year, and time components
const day = parseInt(dateComponents[2]);
const month = monthMap[dateComponents[1]];
const year = parseInt(dateComponents[5]);
const time = dateComponents[3];

// Create a new Date object with the extracted components
const formattedDate = new Date(year, month, day);

 return formattedDate  ;
}

export const isExpired = (toDate, expiryDate) => {
  const expirationDate = new Date(expiryDate);

  // Compare the two dates
  if (expirationDate < toDate) {
    return true; // The expiry date has passed
  } else {
    return false; // The expiry date is in the future
  }
}