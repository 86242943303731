import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../../assets/css/style.css";
import Logo from "../../assets/images/Logo.png";

import "../../stylesheet/Login.css";
import "../../stylesheet/icomoon.css";
import { useNavigate } from "react-router-dom";
import MyLoader from "../../common/MyLoader";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Container } from "react-bootstrap";


import { CHANGE_PASSWORD, FORGOT_PASSWORD_API, OTP_VERIFY_API } from "../../utils/constant";
import { config, getCompleteDomain, getServerEnvironment } from "../../utils/helper";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [otp, setOtp] = useState("");
    const [showLoader, setShowLoader] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [value, setValue] = useState();
    const [confirmPassword, setConfirmPassword] = useState("");
    const [generalError, setGeneralError] = useState(null);
    const [formError, setFormError] = useState({
        email: null,
        otp: null,
        password: null,
    });

    const navigate=useNavigate()
    const [success, setSuccess] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);

   
    const {logoPath,Name}=config(getServerEnvironment(getCompleteDomain()))
  
    const AppNameCapitalized=Name
    const AppName = AppNameCapitalized.toUpperCase()  
 

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormError({ ...formError, email: null });

        try {
            const requestPayload = {
                emailId: email,
            };
            setShowLoader(true)

            const apiResponse = await axios.post(FORGOT_PASSWORD_API, requestPayload);
            
            if (apiResponse.data.statusCode === 200) {
                // console.log(apiResponse.data);
                setSuccess(true);
            } else {
                setGeneralError(apiResponse.data.message);
            }
            setShowLoader(false)
        } catch (error) {
            // console.error(error);
            setShowLoader(false)
            setGeneralError("Something went wrong. Please try again.");
        }
         
    };

    const handleOtpSubmit = async (e) => {
        e.preventDefault();
        setFormError({ ...formError, otp: null });

        try {
            const otpPayload = {
                email:email,
                otp: otp,
            };

            const otpVerifyResponse = await axios.post(OTP_VERIFY_API, otpPayload);

            if (otpVerifyResponse.data.statusCode === 200) {
                // console.log(otpVerifyResponse.data);
                setOtpVerified(true);
            } else {
                setFormError({ ...formError, otp: otpVerifyResponse.data.message });
            }
        } catch (error) {
            console.error(error);
            setGeneralError("Something went wrong. Please try again.");
        }
    };

    const handleNewPasswordSubmit = async (e) => {
        e.preventDefault();
        setFormError({ ...formError, password: null });

        try {
            if (newPassword !== confirmPassword) {
                setFormError({ ...formError, password: "Passwords do not match" });
                return;
            }

            const changePasswordPayload = {
                emailId: email,
                newPassword: newPassword,
            };
            setShowLoader(true)
            const changePasswordResponse = await axios.post(CHANGE_PASSWORD, changePasswordPayload);

            if (changePasswordResponse.data.statusCode === 200) {
                // console.log(changePasswordResponse.data);
                setSuccess(true);
                setValue(changePasswordResponse.data.statusCode);
                setEmail("");
                setNewPassword("");
                setConfirmPassword("");
                // navigate("/")
            } else {
                setGeneralError(changePasswordResponse.data.message);
            }
            setShowLoader(false)
        } catch (error) {
            console.error(error);
            setShowLoader(false)
            setGeneralError("Something went wrong. Please try again.");
        }

    };

    return (
        <section className="swiperlinc_login login_inner">
        {showLoader ? <MyLoader/> : null}
            <Container>
                <Row>
                    <Col xs={12} lg={6} md={6} sm={12} className="login-bg">
                        <div class="welcome-login">
                            <div class="login-banner">
                                <img src={logoPath} alt="logo" class="img-fluid" />
                            </div>
                            <div class="mentor-course text-center">
                            <h2>Welcome to {AppName}</h2>
                <small className="text-white"><span className="fw-semibold fs-5">Dear Valued Merchant,</span><br /><br/>

                  We're excited to welcome you to the {AppNameCapitalized} family! As you log in to our merchant panel, <br />you're stepping into a world of seamless payment solutions and unparalleled convenience. <br />

                  At {AppNameCapitalized}, we're dedicated to supporting your business growth with our cutting-edge <br />payment gateway services.  Our merchant panel is your gateway to streamlined transactions,<br /> real-time insights, and powerful customization tools. 
                  <br />
                  Thank you for choosing {AppNameCapitalized} as your payment partner. Your success is our priority,  <br />and we're here to assist you every step of the way.

                </small>
                <p>Best regards,<br/>
The {AppNameCapitalized} Team</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} lg={6} md={6} sm={12} className="login-wrap-bg">
                        <div class="login-wrapper">
                            <div class="loginbox">
                                <div class="w-100">
                                    {/* <div class="img-logo">
                            <img src={Logo} alt="logo" />
                            <div class="back-home">
                                <a href="./home">Back to Home</a>
                            </div>
                        </div> */}
                                    <div className="mb-md-5 mb-3 login-right-head">
                                        <h2 className="title">Forgot Password?</h2>
                                        {/* <p className="mb-0">Welcome back! Please login to your account.</p> */}
                                    </div>

                                    <div className="login-right">
                                        <div className="mb-md-4 mb-4 login-right-head">
                                            <p className="mb-0">{success && otpVerified ? "Please Enter Your Email and New Password" : success ? "Please Enter the OTP" : "Please Enter Your Email"}</p>
                                        </div>

                                        {success && otpVerified ? (
                                            <form onSubmit={handleNewPasswordSubmit}>
                                                {generalError && <div className="alert alert-danger">{generalError}</div>}
                                                <div className="form-group">
                                                    <label className="form-label">Email</label>
                                                    <span className="icon icon-type-email"></span>
                                                    <input type="text" className="form-control" placeholder="Please write your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">New Password</label>
                                                    <span className="icon icon-type-email"></span>
                                                    <input type="password" className="form-control" placeholder="Please enter your new password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">Confirm Password</label>
                                                    <span className="icon icon-type-email"></span>
                                                    <input type="password" className="form-control" placeholder="Please confirm your new password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                                </div>
                                                {formError.password && <div className="error-message">{formError.password}</div>}

                                                <div className="login-btn-sec">
                                                    <button type="submit" className="btn btn-dark">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        ) : success ? (
                                            <form onSubmit={handleOtpSubmit}>
                                                {generalError && <div className="alert alert-danger">{generalError}</div>}
                                                <div className="form-group">
                                                    <label className="form-label">OTP</label>
                                                    <span className="icon icon-type-email"></span>
                                                    <input type="text" className="form-control" placeholder="Please enter the OTP" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                                </div>
                                                {formError.otp && <div className="alert alert-danger">{formError.otp}</div>}

                                                <div className="login-btn-sec">
                                                    <button type="submit" className="btn btn-dark">
                                                        Submit OTP
                                                    </button>
                                                </div>
                                            </form>
                                        ) : (
                                            <form onSubmit={handleFormSubmit}>
                                                {generalError && <div className="alert alert-danger">{generalError}</div>}
                                                <div className="form-group">
                                                    <label className="form-label">Email</label>
                                                    <span className="icon icon-type-email"></span>
                                                    <input type="text" className="form-control" placeholder="Please write your Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                </div>
                                                {formError.email && <div className="alert alert-danger">{formError.email}</div>}
                                                <div className="login-btn-sec">
                                                    <button type="submit" className="btn btn-dark">
                                                        Submit
                                                    </button>
                                                </div>
                                            </form>
                                        )}

                                        {success && value === 200 && (
                                            <div>
                                                <p className="password-success">Password changed successfully!</p>
                                                <p>
                                                    Go back to <Link to="/">Login</Link>
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ForgotPassword;
