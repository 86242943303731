import InactiveServices from "../common/InactiveServices";
import Dashboard from "../components/merchant/Dashboard";
import Earnings from "../components/merchant/Earnings";
import LoginForm from "../components/merchant/Login";
import MerchantProfile from "../components/merchant/MerchantProfile";
import MerchantSetting from "../components/merchant/MerchantSetting";
import MerchantTools from "../components/Tools/MerchantTools";
import Reports from "../components/reports/Reports";
import SettlementReport from "../components/reports/SettlementReport";
import ForgotPassword from "../components/merchant/ForgotPassword";
import MerchantInfoForm from "../components/onbord/MerchantInfoForm";
import EmailForm from "../components/onbord/EmailForm";
import AdharForm from "../components/onbord/AdharForm";
import CompanyAddForm from "../components/onbord/CompanyAddForm";
import FirstStage from "../components/merchant/FirstStage";
import AdhaarVerification from "../components/onbord/AdhaarVerification";
import PasswordChangeForm from "../components/merchant/PasswordChangeForm";
import VenderDetails from "../components/Tools/myVender/VenderDetails";
import Payment from "../components/payment/Payment";
import FAQ from "../components/faq's/FAQ";
import ChargebackReport from "../components/reports/ChargebackReport";
import ResumeOnboarding from "../components/onbord/ResumeOnboarding";
import ViewResponse from "../components/reports/ViewResponse";
import ViewPayoutResponse from "../components/reports/ViewPayoutResponse";
import TxnStatusCheckBtn from "../components/reports/TxnStatusCheckBtn";
import MyProfile from "../components/profile/MyProfile";
import Api from "../components/merchant/apis/Api";
import InstantWebCallback from "../components/merchant/apis/InstantWebCallback";
import PaymentIntegrationKit from "../components/merchant/apis/PaymentIntegrationKit";
import IntentCallback from "../components/merchant/apis/IntentCallback";
import SeamlessPaymentIntegration from "../components/merchant/apis/SeamlessPaymentIntegration";
import SwipelincS2SRellser from "../components/merchant/apis/SwipelincS2SRellser";
import PayoutDocs from "../components/merchant/apis/PayoutDocs";
import QrString from "../common/QrString";
import BLockVpaApi from "../components/merchant/apis/BLockVpaApi";

const routes = [
  {
    path: "/",
    element: <LoginForm />,
  },
  {
    path: "/Test",
    element: <QrString />,
  },
  {
    path: "/Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/Reports",
    element: <Reports />,
  },
  {
    path: "/Payment",
    element: <Payment />,
  },
  {
    path: "/FAQ",
    element: <FAQ />,
  },
  {
    path: "/instantWebCallback",
    element: <InstantWebCallback />,
  },
  {
    path: "/payment_integration_kit",
    element: <PaymentIntegrationKit />,
  },
  {
    path: "/intent_callback",
    element: <IntentCallback />,
  },
  {
    path: "/seamless_payment_integration",
    element: <SeamlessPaymentIntegration />,
  },
  {
    path: "/swipelinc_S2S_Rellser",
    element: <SwipelincS2SRellser />,
  },
  {
    path: "/payout_docs",
    element: <PayoutDocs />,
  },
  {
    path: "/block-vpa-api",
    element: <BLockVpaApi />,
  },
  {
    path: "/ChargebackReport",
    element: <ChargebackReport />,
  },
  {
    path: "/SettlementReport",
    element: <SettlementReport />,
  },
  {
    path: "/NotActivated",
    element: <InactiveServices />,
  },
  {
    path: "/Setting",
    element: <MerchantSetting />,
  },
  {
    path: "/Tools",
    element: <MerchantTools />,
  },
  {
    path: "/Vender-Details/:venderId",
    element: <VenderDetails />,
  },
  {
    path: "/Profile",
    element: <MerchantProfile />,
  },
  {
    path: "/apis",
    element: <Api />,
  },
  {
    path: "/Earnings",
    element: <Earnings />,
  },
  {
    path: "/ForgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/MerchantInfoForm",
    element: <MerchantInfoForm />,
  },
  {
    path: "/EmailForm",
    element: <EmailForm />,
  },
  {
    path: "/ContinueOnboarding",
    element: <ResumeOnboarding />,
  },
  {
    path: "/ViewResponse/:OrderNumber",
    element: <ViewResponse />,
  },{
    path: "/MyProfile",
    element: <MyProfile />,
  },
  {
    path: "/ViewPayoutResponse/:referenceId/:txnId",
    element: <ViewPayoutResponse />,
  },
  {
    path: "/ViewTxnStatus/:orderNo",
    element: <TxnStatusCheckBtn />,
  },
  {
    path: "/AdharForm",
    element: <AdharForm />,
  },
  {
    path: "/CompanyAddForm",
    element: <CompanyAddForm />,
  },
  {
    path: "/AdhaarVerification",
    element: <AdhaarVerification />,
  },
  {
    path: "/FirstStage",
    element: <FirstStage />,
  },
  {
    path: "/PasswordChangeForm",
    element: <PasswordChangeForm />,
  },
];

export default routes;