import jwtDecode from "jwt-decode";
import React, { useState } from "react";
import { frmBlockVPA } from "../../../services/apiService";
import swal from "sweetalert";
import { isEmailIdRegexIsValid, isIfscRegexIsValid, isValidIMEINumber, isValidMobileNumber, isVpaUpiRegexIsValid } from "../../../utils/validations";

const parameter = [
  { id: 1, name: "VPA", payloadName: "vpa" },
  { id: 2, name: "IMEI Number / Device Id", payloadName: "imei" },
  { id: 3, name: "Email ID", payloadName: "email" },
  { id: 4, name: "Mobile Number", payloadName: "mobile" },
  { id: 5, name: "IFSC Code", payloadName: "ifscCode" },
  { id: 6, name: "Location", payloadName: "location" },
];

const AddUserForm = () => {
  const [selectParameterData, setSelectParameterData] = useState(parameter);
  const [selectedParameter, setSelectedParameter] = useState([]);
//   const [data, setData] = useState();
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const handelParameterChange = (e) => {
    // setData({ ...data, [e.target.name]: e.target.value });

    // const selectedOption = e.target.options[e.target.selectedIndex];
    // const prifix = selectedOption.getAttribute("data-prifix");

    let val = e.target.value;

    // console.log(typeof val);
    // console.log(selectParameterData);

    let select = selectParameterData.filter(
      (data) => data.id === parseInt(val)
    );

    // console.log("select >>>>", select);

    const remaining = selectedParameter.filter((elem) => {
      return elem.id !== select[0].id;
    });

    // console.log("remaining >>>>>>", remaining);

    setSelectedParameter([...remaining, ...select]);

    // console.log(selectedParameter);

    setSelectParameterData(
      selectParameterData.filter((elem) => {
        return elem.id !== parseInt(val);
      })
    );
  };

  const removeParameter = (id, elem) => {
    setSelectedParameter(
      selectedParameter?.filter((elem) => {
        return elem.id !== id;
      })
    );
    setSelectParameterData([...selectParameterData, elem]);
  };

  const handelParameterInput = (e, id) => {
    // if (stopSpecialCharacterInput(keyName) || stopCharacterInput(keyName)) {
    //   return;
    // }

    if (id === 6) {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, [e.target.name]: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    } else {
      let data = selectParameterData?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectParameterData(data);

      let data1 = selectedParameter?.map((elem) => {
        if (elem.id === id) {
          return { ...elem, inputVal: e.target.value };
        }
        return elem;
      });
      setSelectedParameter(data1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // console.log("Selected ", selectedParameter);

    let parameterName = selectedParameter.map(elem => elem.payloadName);

    // console.log("Name", parameterName);

    if(parameterName.includes("vpa")) {
      let elem = selectedParameter.filter(elem => elem.payloadName==="vpa")[0]
     
      if(!isVpaUpiRegexIsValid(elem?.inputVal) ) {
        swal({
          title: "Alert",
          text: "Please Enter valid VPA",
          icon: "error",
        });
        return
      }
    }

    if(parameterName.includes("ifscCode")) {
      let elem = selectedParameter.filter(elem => elem.payloadName==="ifscCode")[0]
     
      if(!isIfscRegexIsValid(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid IFSC Code",
          icon: "error",
        });
        return
      }
    }

    if(parameterName.includes("email")) {
      let elem = selectedParameter.filter(elem => elem.payloadName==="email")[0]
     
      if(!isEmailIdRegexIsValid(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid Email",
          icon: "error",
        });
        return
      }
    }

    if(parameterName.includes("mobile")) {
      let elem = selectedParameter.filter(elem => elem.payloadName==="mobile")[0]
     
      if(!isValidMobileNumber(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid Mobile Number",
          icon: "error",
        });
        return
      }
    }

    if(parameterName.includes("imei")) {
      let elem = selectedParameter.filter(elem => elem.payloadName==="imei")[0]
     
      if(!isValidIMEINumber(elem?.inputVal)) {
        swal({
          title: "Alert",
          text: "Please Enter valid IMEI Number",
          icon: "error",
        });
        return
      }
    }

    
    if(parameterName.includes("location")) {
      let elem = selectedParameter.filter(elem => elem.payloadName==="location")[0]
     
      // console.log(elem);
      // console.log(elem?.Latitude);

      if(elem?.Latitude ==="" || elem?.Latitude === null || elem?.Latitude === undefined) {
        swal({
          title: "Alert",
          text: "Please Enter valid Location Latitude",
          icon: "error",
        });
        return
      }

      if(elem?.longitude ==="" || elem?.longitude === null || elem?.longitude === undefined) {
        swal({
          title: "Alert",
          text: "Please Enter valid Location Longitude",
          icon: "error",
        });
        return
      }
    }

    // return

    const payload = {
      doBlock: true,
      email: "",
      ifscCode: "",
      imei: "",
      letitude: "",
      longitude: "",
      mid: mid,
      mobile: "",
      vpa: "",
    };

    selectedParameter.forEach((elem) => {
      if (elem.id === 1) payload.vpa = elem.inputVal;
      if (elem.id === 2) payload.imei = elem.inputVal;
      if (elem.id === 3) payload.email = elem.inputVal;
      if (elem.id === 4) payload.mobile = elem.inputVal;
      if (elem.id === 5) payload.ifscCode = elem.inputVal;
      if (elem.id === 6) {
        payload.letitude = elem.Latitude;
        payload.longitude = elem.Longitude;
      }
    });

    // console.log("payload >>>", payload);

    let res = await frmBlockVPA(payload, token);
    // console.log("RES >>>>", res);
    if (res.statusCode === 200) {
      swal({
        title: "Success!",
        text: res.message,
        icon: "success",
      });
      setSelectParameterData(parameter)
      setSelectedParameter([])
    } else {
      swal({
        title: "Alert!",
        text: res.message,
        icon: "error",
      });
    }
  };

  return (
    <>
      <section className="w-100 lincpay-dashboard-sec merchant-admin-info-form company-add-form card py-3 ">
        {/* {showLoader ? <MyLoader /> : ""} */}
        <div className="containerflag">
          <div className="row mt-4">
            <div className="col-md-6 offset  ">
              <div className="col-sm-12 col-md-8 offset-md-3">
                <label htmlFor="email">Select A Parameter</label>
                <select
                  id="companyDropdown"
                  className="form-control"
                  onChange={handelParameterChange}
                  //   value={data.merchantSelect}
                  name="merchantSelect"
                >
                  <option value="">Select a Parameter</option>
                  {selectParameterData?.map((parameter) => (
                    <option key={parameter.id} value={parameter.id}>
                      {parameter.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="col-sm-12 col-md-8 offset-md-3">
                {selectedParameter?.map((elem, index) => {
                  if (elem.id === 6) {
                    return (
                      <>
                        <div key={elem.sid} className=" mt-2  py-2  ">
                          <div className="form-group  mt-2 ">
                            <div className="d-flex ">
                              <div className="col-md-11">
                                <label htmlFor="email">
                                  {elem.name} (Latitude)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txnAmt"
                                  name="Latitude"
                                  placeholder={`Enter ${elem.name}`}
                                  value={elem.Latitude}
                                  // onKeyDown={getKeyName}
                                  onChange={(e) =>
                                    handelParameterInput(e, elem.id)
                                  }
                                />
                              </div>
                              <div className="col-md-11 mx-3">
                                <label htmlFor="email">
                                  {elem.name} (Longitude)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txnAmt"
                                  name="Longitude"
                                  placeholder={`Enter ${elem.name}`}
                                  value={elem.Longitude}
                                  // onKeyDown={getKeyName}
                                  onChange={(e) =>
                                    handelParameterInput(e, elem.id)
                                  }
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-end align-items-end">
                                <span
                                  onClick={() => {
                                    removeParameter(elem.id, elem);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-danger fs-4 "
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  } else
                    return (
                      <>
                        <div key={elem.sid} className=" mt-2  py-2  ">
                          <div className="form-group  mt-2 ">
                            <div className="d-flex ">
                              <div className="col-md-11">
                                <label htmlFor="email">{elem.name}</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="txnAmt"
                                  name="txnAmt"
                                  placeholder={`Enter ${elem.name}`}
                                  value={elem.inputVal}
                                  // onKeyDown={getKeyName}
                                  onChange={(e) =>
                                    handelParameterInput(e, elem.id)
                                  }
                                />
                              </div>
                              <div className="d-flex flex-column justify-content-end align-items-end mx-3">
                                <span
                                  onClick={() => {
                                    removeParameter(elem.id, elem);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  className="text-danger fs-4 "
                                >
                                  <i className="bi bi-trash-fill"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                })}
              </div>
            </div>

            <div className="d-flex justify-content-center  ">
              <div className=" "></div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button className="btn btn-success" onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddUserForm;
