import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SwipelincS2SRellser = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const htmlContent = `<html xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office"
    xmlns:w="urn:schemas-microsoft-com:office:word"
    xmlns:dt="uuid:C2F41010-65B3-11d1-A29F-00AA00C14882"
    xmlns:m="http://schemas.microsoft.com/office/2004/12/omml"
    xmlns="http://www.w3.org/TR/REC-html40">
    
    <head>
    <meta http-equiv=Content-Type content="text/html; charset=windows-1252">
    <meta name=ProgId content=Word.Document>
    <meta name=Generator content="Microsoft Word 12">
    <meta name=Originator content="Microsoft Word 12">
    <link rel=File-List
    href="Swipelinc-S2S-Rellser-API-DOC%20(2)_files/filelist.xml">
    <link rel=Edit-Time-Data
    href="Swipelinc-S2S-Rellser-API-DOC%20(2)_files/editdata.mso">
    <!--[if !mso]>
    <style>
    v\:* {behavior:url(#default#VML);}
    o\:* {behavior:url(#default#VML);}
    w\:* {behavior:url(#default#VML);}
    .shape {behavior:url(#default#VML);}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
     <o:DocumentProperties>
      <o:Author>Paresh</o:Author>
      <o:LastAuthor>lincpay</o:LastAuthor>
      <o:Revision>3</o:Revision>
      <o:TotalTime>1</o:TotalTime>
      <o:Created>2023-12-26T12:49:00Z</o:Created>
      <o:LastSaved>2023-12-26T12:49:00Z</o:LastSaved>
      <o:Pages>1</o:Pages>
      <o:Words>207</o:Words>
      <o:Characters>1181</o:Characters>
      <o:Lines>9</o:Lines>
      <o:Paragraphs>2</o:Paragraphs>
      <o:CharactersWithSpaces>1386</o:CharactersWithSpaces>
      <o:Version>12.00</o:Version>
     </o:DocumentProperties>
     <o:CustomDocumentProperties>
      <o:Created dt:dt="date">2023-09-08</o:Created>
      <o:Creator dt:dt="string">Microsoft® Word 2019</o:Creator>
      <o:LastSaved dt:dt="date">2023-12-26</o:LastSaved>
      <o:Producer dt:dt="string">Microsoft® Word 2019</o:Producer>
     </o:CustomDocumentProperties>
    </xml><![endif]-->
    <link rel=themeData
    href="Swipelinc-S2S-Rellser-API-DOC%20(2)_files/themedata.thmx">
    <link rel=colorSchemeMapping
    href="Swipelinc-S2S-Rellser-API-DOC%20(2)_files/colorschememapping.xml">
    <!--[if gte mso 9]><xml>
     <w:WordDocument>
      <w:TrackMoves>false</w:TrackMoves>
      <w:TrackFormatting/>
      <w:PunctuationKerning/>
      <w:DrawingGridHorizontalSpacing>5.5 pt</w:DrawingGridHorizontalSpacing>
      <w:DisplayHorizontalDrawingGridEvery>2</w:DisplayHorizontalDrawingGridEvery>
      <w:ValidateAgainstSchemas/>
      <w:SaveIfXMLInvalid>false</w:SaveIfXMLInvalid>
      <w:IgnoreMixedContent>false</w:IgnoreMixedContent>
      <w:AlwaysShowPlaceholderText>false</w:AlwaysShowPlaceholderText>
      <w:DoNotPromoteQF/>
      <w:LidThemeOther>EN-US</w:LidThemeOther>
      <w:LidThemeAsian>X-NONE</w:LidThemeAsian>
      <w:LidThemeComplexScript>X-NONE</w:LidThemeComplexScript>
      <w:Compatibility>
       <w:ULTrailSpace/>
       <w:ShapeLayoutLikeWW8/>
       <w:BreakWrappedTables/>
       <w:SnapToGridInCell/>
       <w:WrapTextWithPunct/>
       <w:UseAsianBreakRules/>
       <w:DontGrowAutofit/>
       <w:SplitPgBreakAndParaMark/>
       <w:DontVertAlignCellWithSp/>
       <w:DontBreakConstrainedForcedTables/>
       <w:DontVertAlignInTxbx/>
       <w:Word11KerningPairs/>
       <w:CachedColBalance/>
      </w:Compatibility>
      <w:DoNotOptimizeForBrowser/>
      <m:mathPr>
       <m:mathFont m:val="Cambria Math"/>
       <m:brkBin m:val="before"/>
       <m:brkBinSub m:val="--"/>
       <m:smallFrac m:val="off"/>
       <m:dispDef/>
       <m:lMargin m:val="0"/>
       <m:rMargin m:val="0"/>
       <m:defJc m:val="centerGroup"/>
       <m:wrapIndent m:val="1440"/>
       <m:intLim m:val="subSup"/>
       <m:naryLim m:val="undOvr"/>
      </m:mathPr></w:WordDocument>
    </xml><![endif]--><!--[if gte mso 9]><xml>
     <w:LatentStyles DefLockedState="false" DefUnhideWhenUsed="true"
      DefSemiHidden="true" DefQFormat="false" DefPriority="99"
      LatentStyleCount="267">
      <w:LsdException Locked="false" Priority="0" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Normal"/>
      <w:LsdException Locked="false" Priority="9" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="heading 1"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 2"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 3"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 4"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 5"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 6"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 7"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 8"/>
      <w:LsdException Locked="false" Priority="9" QFormat="true" Name="heading 9"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 1"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 2"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 3"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 4"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 5"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 6"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 7"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 8"/>
      <w:LsdException Locked="false" Priority="39" Name="toc 9"/>
      <w:LsdException Locked="false" Priority="35" QFormat="true" Name="caption"/>
      <w:LsdException Locked="false" Priority="10" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Title"/>
      <w:LsdException Locked="false" Priority="1" Name="Default Paragraph Font"/>
      <w:LsdException Locked="false" Priority="11" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Subtitle"/>
      <w:LsdException Locked="false" Priority="22" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Strong"/>
      <w:LsdException Locked="false" Priority="20" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Emphasis"/>
      <w:LsdException Locked="false" Priority="59" SemiHidden="false"
       UnhideWhenUsed="false" Name="Table Grid"/>
      <w:LsdException Locked="false" UnhideWhenUsed="false" Name="Placeholder Text"/>
      <w:LsdException Locked="false" Priority="1" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="No Spacing"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading Accent 1"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List Accent 1"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid Accent 1"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1 Accent 1"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1 Accent 1"/>
      <w:LsdException Locked="false" UnhideWhenUsed="false" Name="Revision"/>
      <w:LsdException Locked="false" Priority="34" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="List Paragraph"/>
      <w:LsdException Locked="false" Priority="29" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Quote"/>
      <w:LsdException Locked="false" Priority="30" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Intense Quote"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1 Accent 1"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2 Accent 1"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3 Accent 1"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List Accent 1"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading Accent 1"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List Accent 1"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid Accent 1"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading Accent 2"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List Accent 2"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid Accent 2"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1 Accent 2"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2 Accent 2"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3 Accent 2"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List Accent 2"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading Accent 2"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List Accent 2"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid Accent 2"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading Accent 3"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List Accent 3"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid Accent 3"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1 Accent 3"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2 Accent 3"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3 Accent 3"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List Accent 3"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading Accent 3"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List Accent 3"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid Accent 3"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading Accent 4"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List Accent 4"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid Accent 4"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1 Accent 4"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2 Accent 4"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3 Accent 4"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List Accent 4"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading Accent 4"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List Accent 4"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid Accent 4"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading Accent 5"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List Accent 5"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid Accent 5"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1 Accent 5"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2 Accent 5"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3 Accent 5"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List Accent 5"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading Accent 5"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List Accent 5"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid Accent 5"/>
      <w:LsdException Locked="false" Priority="60" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Shading Accent 6"/>
      <w:LsdException Locked="false" Priority="61" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light List Accent 6"/>
      <w:LsdException Locked="false" Priority="62" SemiHidden="false"
       UnhideWhenUsed="false" Name="Light Grid Accent 6"/>
      <w:LsdException Locked="false" Priority="63" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="64" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Shading 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="65" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="66" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium List 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="67" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 1 Accent 6"/>
      <w:LsdException Locked="false" Priority="68" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 2 Accent 6"/>
      <w:LsdException Locked="false" Priority="69" SemiHidden="false"
       UnhideWhenUsed="false" Name="Medium Grid 3 Accent 6"/>
      <w:LsdException Locked="false" Priority="70" SemiHidden="false"
       UnhideWhenUsed="false" Name="Dark List Accent 6"/>
      <w:LsdException Locked="false" Priority="71" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Shading Accent 6"/>
      <w:LsdException Locked="false" Priority="72" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful List Accent 6"/>
      <w:LsdException Locked="false" Priority="73" SemiHidden="false"
       UnhideWhenUsed="false" Name="Colorful Grid Accent 6"/>
      <w:LsdException Locked="false" Priority="19" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Subtle Emphasis"/>
      <w:LsdException Locked="false" Priority="21" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Intense Emphasis"/>
      <w:LsdException Locked="false" Priority="31" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Subtle Reference"/>
      <w:LsdException Locked="false" Priority="32" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Intense Reference"/>
      <w:LsdException Locked="false" Priority="33" SemiHidden="false"
       UnhideWhenUsed="false" QFormat="true" Name="Book Title"/>
      <w:LsdException Locked="false" Priority="37" Name="Bibliography"/>
      <w:LsdException Locked="false" Priority="39" QFormat="true" Name="TOC Heading"/>
     </w:LatentStyles>
    </xml><![endif]-->
    <style>
    <!--
     /* Font Definitions */
     @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;
        mso-font-charset:0;
        mso-generic-font-family:roman;
        mso-font-pitch:variable;
        mso-font-signature:-536869121 1107305727 33554432 0 415 0;}
    @font-face
        {font-family:Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;
        mso-font-charset:0;
        mso-generic-font-family:swiss;
        mso-font-pitch:variable;
        mso-font-signature:-469750017 -1073732485 9 0 511 0;}
    @font-face
        {font-family:Tahoma;
        panose-1:2 11 6 4 3 5 4 4 2 4;
        mso-font-charset:0;
        mso-generic-font-family:swiss;
        mso-font-pitch:variable;
        mso-font-signature:-520081665 -1073717157 41 0 66047 0;}
    @font-face
        {font-family:"Palatino Linotype";
        panose-1:2 4 5 2 5 5 5 3 3 4;
        mso-font-alt:"Palatino Linotype";
        mso-font-charset:0;
        mso-generic-font-family:roman;
        mso-font-pitch:variable;
        mso-font-signature:-536870265 1073741843 0 0 415 0;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
        {mso-style-priority:1;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        mso-style-parent:"";
        margin:0cm;
        margin-bottom:.0001pt;
        mso-pagination:none;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-fareast-font-family:Calibri;}
    p.MsoHeader, li.MsoHeader, div.MsoHeader
        {mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-link:"Header Char";
        margin:0cm;
        margin-bottom:.0001pt;
        mso-pagination:none;
        tab-stops:center 234.0pt right 468.0pt;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-fareast-font-family:Calibri;}
    p.MsoFooter, li.MsoFooter, div.MsoFooter
        {mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-link:"Footer Char";
        margin:0cm;
        margin-bottom:.0001pt;
        mso-pagination:none;
        tab-stops:center 234.0pt right 468.0pt;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-fareast-font-family:Calibri;}
    p.MsoBodyText, li.MsoBodyText, div.MsoBodyText
        {mso-style-priority:1;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        margin:0cm;
        margin-bottom:.0001pt;
        mso-pagination:none;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-fareast-font-family:Calibri;}
    p.MsoAcetate, li.MsoAcetate, div.MsoAcetate
        {mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-link:"Balloon Text Char";
        margin:0cm;
        margin-bottom:.0001pt;
        mso-pagination:none;
        text-autospace:none;
        font-size:8.0pt;
        font-family:"Tahoma","sans-serif";
        mso-fareast-font-family:Calibri;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
        {mso-style-priority:1;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        margin-top:0cm;
        margin-right:0cm;
        margin-bottom:0cm;
        margin-left:27.95pt;
        margin-bottom:.0001pt;
        text-indent:-18.0pt;
        mso-pagination:none;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-fareast-font-family:Calibri;}
    p.TableParagraph, li.TableParagraph, div.TableParagraph
        {mso-style-name:"Table Paragraph";
        mso-style-priority:1;
        mso-style-unhide:no;
        mso-style-qformat:yes;
        margin:0cm;
        margin-bottom:.0001pt;
        mso-pagination:none;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-fareast-font-family:Calibri;}
    span.BalloonTextChar
        {mso-style-name:"Balloon Text Char";
        mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-unhide:no;
        mso-style-locked:yes;
        mso-style-link:"Balloon Text";
        mso-ansi-font-size:8.0pt;
        mso-bidi-font-size:8.0pt;
        font-family:"Tahoma","sans-serif";
        mso-ascii-font-family:Tahoma;
        mso-fareast-font-family:Calibri;
        mso-hansi-font-family:Tahoma;
        mso-bidi-font-family:Tahoma;}
    span.HeaderChar
        {mso-style-name:"Header Char";
        mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-unhide:no;
        mso-style-locked:yes;
        mso-style-link:Header;
        font-family:"Calibri","sans-serif";
        mso-ascii-font-family:Calibri;
        mso-fareast-font-family:Calibri;
        mso-hansi-font-family:Calibri;
        mso-bidi-font-family:Calibri;}
    span.FooterChar
        {mso-style-name:"Footer Char";
        mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-unhide:no;
        mso-style-locked:yes;
        mso-style-link:Footer;
        font-family:"Calibri","sans-serif";
        mso-ascii-font-family:Calibri;
        mso-fareast-font-family:Calibri;
        mso-hansi-font-family:Calibri;
        mso-bidi-font-family:Calibri;}
    .MsoChpDefault
        {mso-style-type:export-only;
        mso-default-props:yes;
        mso-ascii-font-family:Calibri;
        mso-ascii-theme-font:minor-latin;
        mso-fareast-font-family:Calibri;
        mso-fareast-theme-font:minor-latin;
        mso-hansi-font-family:Calibri;
        mso-hansi-theme-font:minor-latin;
        mso-bidi-font-family:"Times New Roman";
        mso-bidi-theme-font:minor-bidi;}
    .MsoPapDefault
        {mso-style-type:export-only;
        mso-pagination:none;
        text-autospace:none;}
     /* Page Definitions */
     @page
        {mso-footnote-separator:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") fs;
        mso-footnote-continuation-separator:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") fcs;
        mso-endnote-separator:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") es;
        mso-endnote-continuation-separator:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") ecs;}
    @page Section1
        {size:612.0pt 792.0pt;
        margin:78.0pt 31.0pt 54.0pt 51.0pt;
        mso-header-margin:41.6pt;
        mso-footer-margin:44.15pt;
        border:solid black 1.0pt;
        mso-border-alt:solid black .5pt;
        padding:24.0pt 24.0pt 24.0pt 24.0pt;
        mso-even-header:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") eh1;
        mso-header:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") h1;
        mso-footer:url("Swipelinc-S2S-Rellser-API-DOC%20\(2\)_files/header.htm") f1;
        mso-paper-source:0;}
    div.Section1
        {page:Section1;}
     /* List Definitions */
     @list l0
        {mso-list-id:1964270528;
        mso-list-type:hybrid;
        mso-list-template-ids:255498728 -607093124 -881298996 123365124 1622824882 -647432636 959322624 -164605956 -368515270 419460800;}
    @list l0:level1
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:\F0B7;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:27.95pt;
        text-indent:-18.0pt;
        font-family:Symbol;
        mso-fareast-font-family:Symbol;
        mso-bidi-font-family:Symbol;
        letter-spacing:0pt;
        mso-font-width:99%;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level2
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:78.2pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level3
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:128.4pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level4
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:178.6pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level5
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:228.8pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level6
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:279.0pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level7
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:329.2pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level8
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:379.4pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    @list l0:level9
        {mso-level-start-at:0;
        mso-level-number-format:bullet;
        mso-level-text:•;
        mso-level-tab-stop:none;
        mso-level-number-position:left;
        margin-left:429.6pt;
        text-indent:-18.0pt;
        mso-ansi-language:EN-US;
        mso-fareast-language:EN-US;
        mso-bidi-language:AR-SA;}
    ol
        {margin-bottom:0cm;}
    ul
        {margin-bottom:0cm;}
    -->
    </style>
    <!--[if gte mso 10]>
    <style>
     /* Style Definitions */
     table.MsoNormalTable
        {mso-style-name:"Table Normal";
        mso-tstyle-rowband-size:0;
        mso-tstyle-colband-size:0;
        mso-style-noshow:yes;
        mso-style-priority:99;
        mso-style-qformat:yes;
        mso-style-parent:"";
        mso-padding-alt:0cm 5.4pt 0cm 5.4pt;
        mso-para-margin:0cm;
        mso-para-margin-bottom:.0001pt;
        mso-pagination:none;
        text-autospace:none;
        font-size:11.0pt;
        font-family:"Calibri","sans-serif";
        mso-ascii-font-family:Calibri;
        mso-ascii-theme-font:minor-latin;
        mso-hansi-font-family:Calibri;
        mso-hansi-theme-font:minor-latin;
        mso-bidi-font-family:"Times New Roman";
        mso-bidi-theme-font:minor-bidi;}
    </style>
    <![endif]--><!--[if gte mso 9]><xml>
     <o:shapedefaults v:ext="edit" spidmax="3074"/>
    </xml><![endif]--><!--[if gte mso 9]><xml>
     <o:shapelayout v:ext="edit">
      <o:idmap v:ext="edit" data="1"/>
     </o:shapelayout></xml><![endif]-->
    </head>
    
    <body lang=EN-US style='tab-interval:36.0pt'>
    
    <div class=Section1>
    
    <p class=MsoBodyText style='margin-top:5.25pt'><span style='font-size:13.0pt;
    font-family:"Times New Roman","serif";mso-hansi-font-family:Calibri;mso-bidi-font-family:
    Calibri'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoListParagraph style='margin-top:3.05pt;mso-list:l0 level1 lfo1;
    tab-stops:27.95pt'><![if !supportLists]><span style='font-size:13.0pt;
    font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
    mso-font-width:99%'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>CallBack URL End-Point (Shared by Merchant) : </span></b><span
    style='font-size:13.0pt'><a href="https://abcd-xyz.com/api/reseller/endpoint"><span
    style='color:blue'>https://abcd-<span style='letter-spacing:-.1pt'>xyz.com/api/reseller/endpoint</span></span></a></span><span
    style='font-size:13.0pt;font-family:Symbol'><o:p></o:p></span></p>
    
    <p class=MsoListParagraph style='margin-top:12.2pt;mso-list:l0 level1 lfo1;
    tab-stops:27.95pt'><![if !supportLists]><span style='font-size:13.0pt;
    font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
    mso-font-width:99%'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>CallBack Response <span style='letter-spacing:-.2pt'>Json:</span></span></b><span
    style='font-size:13.0pt;font-family:Symbol'><o:p></o:p></span></p>
    
    <p class=MsoNormal style='margin-top:.1pt;margin-right:0cm;margin-bottom:0cm;
    margin-left:27.95pt;margin-bottom:.0001pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt;letter-spacing:-.5pt'>[</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:27.95pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>data=Bon6NhrN0qHKCtiBf1G4Vhd3agrDW8VTbXYDoe9mNbiZ+6wTv9B13aiU90N3GWW39A/bJH5VuBsw70HQ
    0mSkA6Iar88rEqUjrWpXNl0uiKKSlYkpTYpOi08RZ82z847BDIscNFMmULvWyPuiPI+qhk3uifSf59fu1Jr9lWCsT1WtfI
    3KKDs9R2m/sWvULcv8n9NB1DO94kk5P2ZbIF5ONXE+1Qce7Y+A2LRun9qUNnsrhw8c5kueP3D0a0rk2Lu5JEB2X+
    y/ARE01xkDhYyxzSPmi9kFgrhdELiQ4xaCqn8OIu0YwfvOV4Q7vLXdPDLV3GSyJ1ts/BRvzP/L/uBjWQ==,
    mid=SWIPEPROD100007,</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>signature=782923988</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoNormal style='margin-left:27.95pt'><span style='font-size:13.0pt;
    letter-spacing:-.5pt'>]</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoListParagraph style='margin-top:12.15pt;mso-list:l0 level1 lfo1;
    tab-stops:27.95pt'><![if !supportLists]><span style='font-size:13.0pt;
    font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
    mso-font-width:99%'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'> Data : </span></b><span style='font-size:13.0pt'>It’s encrypted value, for check the response please decrypt it by using your encryption key and<span
    style='letter-spacing:-.2pt'> same </span></span><span style='font-size:13.0pt;
    font-family:Symbol'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:27.95pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>algorithm which you using during sent request to payment <span
    style='letter-spacing:-.1pt'>server.</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:12.25pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoListParagraph style='mso-list:l0 level1 lfo1;tab-stops:27.95pt'><![if !supportLists]><span
    style='font-size:13.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;
    mso-bidi-font-family:Symbol;mso-font-width:99%'><span style='mso-list:Ignore'>·<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>After decryption:</span></b><span style='font-size:
    13.0pt'> After decryption response data will <span style='letter-spacing:-.25pt'>be-</span></span><span
    style='font-size:13.0pt;font-family:Symbol'><o:p></o:p></span></p>
    
    <p class=MsoNormal style='margin-left:27.95pt'><span style='font-size:13.0pt;
    letter-spacing:-.5pt'>{</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>&quot;amount&quot;:<span
    style='letter-spacing:-.1pt'>&quot;100&quot;,</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt'>&quot;orderNo&quot;:<span
    style='letter-spacing:-.1pt'>&quot;ORD23072214392200916&quot;,</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:0cm;margin-right:366.1pt;margin-bottom:
    0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnStatus&quot;:
    &quot;success&quot;, &quot;txnRespCode&quot;:
    &quot;200&quot;,&quot;mid&quot;:&quot;SWIPEPROD100007&quot;,<o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;currency&quot;:<span
    style='letter-spacing:-.1pt'>&quot;INR&quot;,</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;txnDate&quot;:&quot;2023-07-22<span
    style='letter-spacing:-.1pt'>14:39:20.796&quot;,</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt'>&quot;customerIfsc&quot;:<span
    style='letter-spacing:-.1pt'>&quot;none&quot;,</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>&quot;txnId&quot;:<span
    style='letter-spacing:-.1pt'>&quot;47209036589869018322&quot;,</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:0cm;margin-right:344.6pt;margin-bottom:
    0cm;margin-left:33.0pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>&quot;paymentType&quot;:
    &quot;UPI&quot;, &quot;<span style='color:black;background:yellow;mso-highlight:
    yellow'>childMid</span><span style='color:black'>&quot;:&quot;SWIPECDIH100907&quot;<span
    style='letter-spacing:-.1pt'>&quot;udf3&quot;:&quot;&quot;,</span></span><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>&quot;udf4&quot;:&quot;&quot;,</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>&quot;udf1&quot;:&quot;&quot;,</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:33.0pt'><span style='font-size:13.0pt;
    letter-spacing:-.1pt'>&quot;udf2&quot;:&quot;&quot;</span><span
    style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoNormal style='margin-left:27.95pt'><span style='font-size:13.0pt;
    letter-spacing:-.5pt'>}</span><span style='font-size:13.0pt'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:.05pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:27.95pt'><span style='font-size:13.0pt'>&quot;<span
    style='color:black;background:yellow;mso-highlight:yellow'>childMid</span><span
    style='color:black'>&quot; : it’ sareseller(childMerchant)<span style='letter-spacing:
    -.2pt'>mid.</span></span><o:p></o:p></span></p>


    <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>

    

    <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>Excepected Response Json from merchant<o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>{<o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>“status&quot;:&quot;205&quot;,<o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-left:22.5pt;line-height:13.35pt;mso-line-height-rule:
    exactly'><span style='font-size:13.0pt'>}<o:p></o:p></span></p>
  
    <p class=MsoBodyText style='margin-top:2.2pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:0cm;margin-bottom:.0001pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>

    
    <p class=MsoListParagraph style='margin-top:13.35pt;margin-right:20.9pt;
    margin-bottom:0cm;margin-left:27.95pt;margin-bottom:.0001pt;mso-list:l0 level1 lfo1;
    tab-stops:27.95pt'><![if !supportLists]><span style='font-size:13.0pt;
    font-family:Symbol;mso-fareast-font-family:Symbol;mso-bidi-font-family:Symbol;
    mso-font-width:99%'><span style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt'>Signature/Checksum : </span></b><span style='font-size:
    13.0pt'> Signature is checksum value that is generate to check the data integrity.For generate
    checksum/signature please use response field at given sequence-</span><span
    style='font-size:13.0pt;font-family:Symbol'><o:p></o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:.05pt;margin-right:0cm;margin-bottom:
    0cm;margin-left:27.35pt;margin-bottom:.0001pt'><span style='font-size:13.0pt'>Mid+txnId+orderNo+
    <span style='letter-spacing:-.1pt'>txnStatus</span><o:p></o:p></span></p>
    
    <p class=MsoBodyText><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoBodyText style='margin-top:12.35pt'><span style='font-size:13.0pt'><o:p>&nbsp;</o:p></span></p>
    
    <p class=MsoListParagraph style='mso-list:l0 level1 lfo1;tab-stops:27.95pt'><![if !supportLists]><span
    style='font-size:13.0pt;font-family:Symbol;mso-fareast-font-family:Symbol;
    mso-bidi-font-family:Symbol;color:red;mso-font-width:99%'><span
    style='mso-list:Ignore'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span></span><![endif]><b style='mso-bidi-font-weight:normal'><span
    style='font-size:13.0pt;color:red'>Note: It’s Mandatory to map reseller (child) merchant id in Parent <span
    style='letter-spacing:-.1pt'>Application.</span></span></b><span
    style='font-size:13.0pt;font-family:Symbol;color:red'><o:p></o:p></span></p>
    
    </div>
    
    </body>
    
    </html>
    `;

  return (
    <section className="dashboard-header-sec merchant-settings-sec mt-3 rounded-top">
      <Container>
        <div className="ds-header-sec">
          <Row>
            <div className="d-flex justify-content-end">
              <button className="btn btn-success mb-3" onClick={goBack}>
                {" "}
                &#8592; Back
              </button>
            </div>

            <Col xs={12} lg={12} md={12}>
              <Card className="shadow border-0 mb-4 ds-header-card">
                <Card.Body>
                  <div
                    className="merchant-tab-btn  p-3  "
                    dangerouslySetInnerHTML={{ __html: htmlContent }}
                  ></div>
                </Card.Body>
              </Card>
              {/* ./card */}
            </Col>
          </Row>
          <div className="d-flex justify-content-end">
            <button className="btn btn-success mb-3" onClick={goBack}>
              {" "}
              &#8592; Back
            </button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default SwipelincS2SRellser;
