import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Card, Col, Row } from "react-bootstrap";
 
import { GET_ALL_PRIVILAGES, SAVE_USER } from "../../../utils/constant";
import { setAllPrivilagesData } from "../../../store/slices/privilagesSlice";
import { useDispatch } from "react-redux";
import { getKeyPressed, isEmailIdRegexIsValid } from "../../../utils/validations";
import { SaveFunctionForAllPostApiCall, getAllFunctionForApiCalls } from "../../../services/apiService";
 

const CreateUser = ({ setShowLoader }) => {
  const [privilagesData, setPrivilagesData] = useState([]);
  const [privilages, setPrivilages] = useState([]);
  const [pressedKeyName, setPressedKeyName] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [HeadPrivilages, setHeadPrivilages] = useState([]);
  const tokenproxy = localStorage.getItem("token");
  const token = tokenproxy.slice(1,-1);
  const dispatch = useDispatch();

  const [formDataInputs, setFormDataInputs] = useState({
    AggregatorName: "",
    password: "",
    MobileNumber: "",
    Prifix: "",
    emailId: "",
  });

  const getAllPrivilages = async () => {
    try {
      const response = await getAllFunctionForApiCalls(
        GET_ALL_PRIVILAGES,
        token
      );

      if (response.statusCode === 200) {
        setPrivilagesData(response?.data);
        dispatch(setAllPrivilagesData(response?.data));
        console.log(response.data);
      } else {
        setPrivilagesData([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPrivilages();
  }, []);

  const handleformDataInputs = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    setFormDataInputs({ ...formDataInputs, [name]: value });
  };

  const handlePrivilages = (e) => {
    const adminPrivilegeSubTypeId = e.target.name;
    const head = e.target.dataset.head + "";
    console.log(head);

    // setHeadPrivilages((prevPrivilages) => {
    //   if (!e.target.checked) {
    //     // If any sub-privilege is unchecked, remove the head privilege
    //     return prevPrivilages.filter((id) => id !== head);
    //   }
    //   return prevPrivilages;
    // });

    setPrivilages((prevPrivilages) => {
      if (e.target.checked) {
        if (!prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return [...prevPrivilages, adminPrivilegeSubTypeId];
        }
      } else {
        if (prevPrivilages.includes(adminPrivilegeSubTypeId)) {
          return prevPrivilages.filter((id) => id !== adminPrivilegeSubTypeId);
        }
      }
      return prevPrivilages;
    });
  };
  const handleHeadPrivilages = (e) => {
    const adminPrivilegeId = e.target.name;
    console.log(e.target.checked);

    const subtypeArray = privilagesData?.find((item) => {
      return String(item.privilegeId) === adminPrivilegeId;
    });

    const array = subtypeArray?.subTypes.map((item) => {
      return item.privilegeSubTypeId + "";
    });

    if (e.target.checked) {
      setPrivilages((prev) => [...prev, ...array]);
      setHeadPrivilages((prevPrivilages) => [
        ...prevPrivilages,
        adminPrivilegeId,
      ]);
    } else {
      setPrivilages((prev) => prev?.filter((id) => !array.includes(id)));
      setHeadPrivilages((prevPrivilages) =>
        prevPrivilages.filter((id) => id !== adminPrivilegeId)
      );
    }
  };
  useEffect(() => {
    console.log(privilages);
  }, [privilages]);

  const handleSubmit = async (e) => {
    const privilegs = privilages?.map((item) => {
      return parseInt(item);
    });

    const payload = {
      fullName: formDataInputs.Name.trim(),
      password: formDataInputs.password.trim(),
      mobile: parseInt(formDataInputs.MobileNumber),
    //   midprifix: formDataInputs.Prifix.trim(),
      email: formDataInputs.emailId.trim(),
      privilges: privilegs,
    };

    if (formDataInputs.Name === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Master Merchant Name",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.MobileNumber === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Mobile Number",
        icon: "error",
      });
      return;
    }
    
    if (formDataInputs.emailId === "") {
      swal({
        title: "Alert!",
        text: "Please Enter Email Id",
        icon: "error",
      });
      return;
    }
    if (formDataInputs.password === "") {
      swal({
        title: "Alert!",
        text: "Please Enter password",
        icon: "error",
      });
      return;
    }

    if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
      swal({
        title: "Alert!",
        text: "Please Enter Valid Merchant E-Mail ID !",
        icon: "error",
      }).then(() => {
        setFormDataInputs({ ...formDataInputs });
      });
      return;
    }

    if (privilages.length === 0) {
      swal({
        title: "Alert!",
        text: "Please select at least one privilege",
        icon: "error",
      });
      return;
    }
    console.log(payload);

    e.preventDefault();
    const swalConfirm = await swal({
      title: "Are you sure?",
      text: "Do you want to submit the application?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });
    if (swalConfirm) {
      setShowLoader(true);
      const res = await SaveFunctionForAllPostApiCall(SAVE_USER,payload, token);
      setShowLoader(false);

      if (res.statusCode === 200) {
        swal({
          title: "Success",
          text: "User Saved Successfully.",
          icon: "success",
        });
        setFormDataInputs({
          Name: "",
          password: "",
          MobileNumber: "",
        //   Prifix: "",
          emailId: "",
        });
        setPrivilages([]);
        setPrivilagesData([]);
        getAllPrivilages();
      } else {
        swal({
          title: "Failed",
          text: res.message,
          icon: "error",
        });
      }
    }
  };

// const handlePasswordValid= (e)=>{
//   if (e.target.name === "password" && e.target.value !== "") {
//     if (!isPasswordRegexIsValid(formDataInputs.password)) {
//       setPasswordError("Password must Contain 8 letters, Atleast 1 Capital Letter, Atleast 1 Special Character , Atleast 1 Numberic value")
//       return;
//     }else{
//       setPasswordError("")
//     }
//   }
// }

  const handleValidations = (e) => {
    if (e.target.name === "email" && e.target.value !== "") {
      if (!isEmailIdRegexIsValid(formDataInputs.emailId)) {
        swal({
          title: "Alert!",
          text: "Please Enter Valid Merchant E-Mail ID !",
          icon: "error",
        }).then(() => {
          formDataInputs({ ...formDataInputs, email: "" });
        });
        return;
      }
    }
    
  };

  return (
    <div className="merchant-report-view">
      <div className="report-table-view">
        <div>
          <Row className="px-4">
            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Name
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="Name"
                    value={formDataInputs.Name}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
            </Col>

            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  e-Mail Id
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    type="email"
                    className="form-control"
                    name="emailId"
                    value={formDataInputs.emailId}
                    onChange={handleformDataInputs}
                    onKeyDown={(e) => setPressedKeyName(getKeyPressed(e))}
                    onBlur={handleValidations}
                  />
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label htmlFor="MobileNumber" className="form-label">
                Mobile Number
                  <sup className="text-danger">*</sup>{" "}
                </label>
                <input
                  maxLength={10}
                  minLength={10}
                  name="MobileNumber"
                  value={formDataInputs.MobileNumber}
                  onChange={handleformDataInputs}
                  required
                  pattern="[0-9]{10}"
                  title="Please enter a 10-digit mobile number"
                  className="form-control"
                />
              </div>
            </Col>
            {/* <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Prifix
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="Prifix"
                    value={formDataInputs.Prifix}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
            </Col> */}
            <Col xs={12} lg={4} md={6} className="mt-5">
              <div className="form-group">
                <label className="form-label">
                  Password
                  <sup className="text-danger">*</sup>
                </label>
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    name="password"
                    value={formDataInputs.password}
                    onChange={handleformDataInputs}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <lable className="fs-3 fw-bold mx-3 mt-2 mb-3">Privileges</lable>
            <div className="d-flex flex-column gap-4">
              {privilagesData &&
                privilagesData.map((itemMain, i) => (
                  <div key={i} className="d-flex flex-column">
                    <Card.Header className="form-label mt-4 mb-3 fs-4 mx-3 gap-3">
                      <div className="s-lg form-check">
                        {itemMain.privilegeName}
                        <input
                          id="flexSwitchCheckDefault"
                          className="form-check-input fs-4"
                          type="checkbox"
                          name={itemMain.privilegeId}
                          value={HeadPrivilages.includes(
                            itemMain.privilegeId + ""
                          )}
                          onChange={handleHeadPrivilages}
                        />
                      </div>
                    </Card.Header>

                    <div className="d-flex flex-wrap">
                      {itemMain.subTypes.map((item, i) => (
                        <div
                          key={i}
                          className="bg-light d-flex justify-content-center align-items-center gap-5 px-4 py-1 rounded rounded-5 mt-2 mx-2   form-group"
                        >
                          <label className="form-label mt-1 fs-4 mx-1">
                            {item.privilegeSubTypeName}
                          </label>
                          <div className="s-lg form-check form-switch ">
                            <input
                              id="flexSwitchCheckDefault"
                              className="form-check-input fs-4"
                              data-head={itemMain.adminPrivilegeId}
                              type="checkbox"
                              name={item.privilegeSubTypeId}
                              checked={privilages.includes(
                                item.privilegeSubTypeId + ""
                              )}
                              onChange={handlePrivilages}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </Row>
          <div className="d-flex justify-content-center mt-4">
            <button className="btn btn-primary" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;
