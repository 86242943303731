import { MDBDataTable } from "mdbreact";
import React, { useEffect, useState } from "react";
import { Card, Col, Container } from "react-bootstrap";
import MyLoader from "../../common/MyLoader";
import jwtDecode from "jwt-decode";
import Timer from "./Timer";
import { isVpaUpiRegexIsValid } from "../../utils/validations";
import {
  VIRTUAL_TERMINAL_REQUEST_SUBMIT_API,
  fetchQrString,
} from "../../services/apiService";
import swal from "sweetalert";
import InactiveService2 from "../../common/inactiveService2";

const VirtualTerminal = ({ setShowLoader }) => {
  const tokenValue = localStorage.getItem("token");
  const token = tokenValue ? tokenValue.slice(1, -1) : null;
  const decodedToken = jwtDecode(token);
  const { mid } = decodedToken;

  const [formData, setFormData] = useState({
    email: "",
    mobile: "",
    upi: "",
    amount: "",
  });

  
  const Type= localStorage.getItem("typeOfServices")
  const paymentType= JSON.parse(Type)

  const [responseData, setResponseData] = useState();

  const HandleForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // Check if the entered value is a valid number
 
    
  setFormData({ ...formData, [name]: value });
  };

  const handleValidations = (e) => {
    if (e.target.name === "upi") {
      if (!isVpaUpiRegexIsValid(e.target.value)) {
        document.getElementById("upiErr").classList.remove("d-none");
        document.getElementById("SubMitBtn").disabled = true;
        return;
      } else {
        document.getElementById("upiErr").classList.add("d-none");
        document.getElementById("SubMitBtn").disabled = false;
        return;
      }
    }

    if (e.target.name === "mobile") {
      // Mobile validation (example: 10 digits)
      if (!/^\d{10}$/.test(e.target.value)) {
        document.getElementById("MobileErr").classList.remove("d-none");
        document.getElementById("SubMitBtn").disabled = true;
        return;
      } else {
        document.getElementById("MobileErr").classList.add("d-none");
        document.getElementById("SubMitBtn").disabled = false;
        return;
      }
    }

    if (e.target.name === "email") {
      // Email validation
      if (
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e.target.value)
      ) {
        document.getElementById("emailErr").classList.remove("d-none");
        document.getElementById("SubMitBtn").disabled = true;
        return;
      } else {
        document.getElementById("emailErr").classList.add("d-none");
        document.getElementById("SubMitBtn").disabled = false;
        return;
      }
    }

    document.getElementById("upiErr").classList.add("d-none");
    document.getElementById("SubMitBtn").disabled = false;
  };

  const handleSearch = async () => {
    const payload = {
      amount: parseInt(formData.amount),
      mid: mid,
      mobileNo: formData.mobile,
      emailId: formData.email,
      udf1: formData.upi,
    };

    if (formData.amount === "") {
      swal({
        title: "Failed",
        text: "Please Enter Amount.",
        icon: "warning",
      });
      return;
    }

    if (parseInt(formData.amount) <= 0) {
      swal({
        title: "Failed",
        text: "Amount should be greater than zero.",
        icon: "warning",
      });
      return;
    }

    if (formData.upi === "") {
      swal({
        title: "Failed",
        text: "Please Enter UPI ID.",
        icon: "warning",
      });
      return;
    }
    setShowLoader(true);
    try {
      const response = await VIRTUAL_TERMINAL_REQUEST_SUBMIT_API(
        payload,
        token
      );

      if (response.message === "SUCCESS") {
        setResponseData(response.responseData);

        swal({
          title: "Success",
          text: "Request Sent Successfully.",
          icon: "success",
        });
      } else {
        swal({
          title: "Failed",
          text: "Failed To Sent Request.",
          icon: "failed",
        });
      }
      setShowLoader(false);
      setFormData({
        email: "",
        mobile: "",
        upi: "",
        amount: "",
      });
      console.log(response.responseData);
    } catch (error) {
      swal({
        title: "Something Went Wrong",
        text: "",
        icon: "failed",
      });
      setShowLoader(false);
    }
  };

  return (
    <>
      <section className="lincpay-dashboard-sec merchant-admin-info-form aadharform-sec">
        <Container>
          { paymentType.includes("VIRTUAL TERMINAL") ? (
             <div className="cart-view-sec mt-4 d-flex gap-5 justify-content-evenly align-items-center">
          <Col xs={12} lg={6} md={6}>
              <div className="merchant-form-info d-flex justify-content-center">
                <Col>
                  <div className="form-group">
                    <label htmlFor="companyPanNumber" className="form-label">
                      e-Mail Id
                    </label>
                    <div className="input-field-validate">
                      <input
                        name="email"
                        type="text"
                        value={formData.email}
                        onChange={HandleForm}
                        onBlur={handleValidations}
                        className="form-control"
                      />
                    </div>
                    <span id="emailErr" className="text-danger d-none">
                      E-Mail ID Invalid*
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="companyPanNumber" className="form-label">
                      Mobile Number
                    </label>
                    <div className="input-field-validate">
                      <input
                        name="mobile"
                        type="number"
                        value={formData.mobile}
                        onChange={HandleForm}
                        onBlur={handleValidations}
                        className="form-control"
                      />
                    </div>
                    <span id="MobileErr" className="text-danger d-none">
                      Mobile Number Invalid*
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="companyPanNumber" className="form-label">
                      Enter Amount
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="input-field-validate">
                      <input
                        name="amount"
                        type="number"
                        value={formData.amount}
                        onChange={HandleForm}
                        className="form-control"
                      />
                    </div>
                    <span id="Amount" className="text-danger d-none"></span>
                  </div>
                </Col>
              </div>
              <div className="merchant-form-info d-flex justify-content-center">
                <Col>
                  <div className="form-group">
                    <label htmlFor="companyPanNumber" className="form-label">
                      Enter UPI ID
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="input-field-validate">
                      <input
                        name="upi"
                        type="text"
                        value={formData.upi}
                        onChange={HandleForm}
                        onBlur={handleValidations}
                        className="form-control"
                      />
                    </div>
                    <span id="upiErr" className="text-danger d-none">
                      UPI ID / VPA Invalid*
                    </span>
                    <div className="login-btn-sec mt-2 d-flex justify-content-center">
                      <button
                        id="SubMitBtn"
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSearch}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </Col>
              </div>
            </Col>

            {/* <Col className="p-5">
              <div className="form-group">
                <label htmlFor="companyPanNumber" className="form-label">
                  Order Number
                </label>
                <div className="input-field-validate">
                  <input
                    name="email"
                    type="text"
                    value={responseData?.orderNo}
                    readOnly
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="companyPanNumber" className="form-label">
                  Transaction Id
                </label>
                <div className="input-field-validate">
                  <input
                    name="email"
                    type="text"
                    value={responseData?.txnId}
                    readOnly
                    className="form-control"
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="companyPanNumber" className="form-label">
                  Status
                </label>
                <div className="input-field-validate">
                  <input
                    name="email"
                    type="text"
                    value={responseData?.status}
                    readOnly
                    className="form-control"
                  />
                </div>
              </div>
            </Col> */}
          </div>) : (<InactiveService2/>)}
          
        </Container>
      </section>
    </>
  );
};

export default VirtualTerminal;