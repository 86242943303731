import React, { useState } from "react";
import InactiveServices from "../../common/InactiveServices";
// new import link
import "../../stylesheet/Reports.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import WithdrawSettlementSetting from "./settting/WithdrawSettlementSetting";
import SubTabForFRM from "./settting/SubTabForFRM";
import CheckExportConst from "../Tools/UserManagement/CheckexportConst";
import UserSubTab from "../Tools/UserManagement/UserSubTab";
import { useSelector } from "react-redux";
 

const MerchantSetting = () => {
    const [activeButton, setactiveButton] = useState();
    const { privilagesData } = useSelector((state) => state.privilages);
    const {
        SettingPrivilages ,hasPrivilagesSecurityManage,hasPrivilagesSSettlement ,hasPrivilagesNotification, hasPrivilagesSWithdraw ,hasPrivilagesFRM,
      } = CheckExportConst();
console.log(hasPrivilagesFRM,hasPrivilagesSWithdraw)

const {userType} = useSelector(state=>state.privilages)
  

    return (
        <>
            <section className="dashboard-header-sec merchant-settings-sec">
                <Container>
                    <div className="ds-header-sec">
                        <Row>
                            <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4 ds-header-card">
                                    <Card.Body>
                                        {/* <Card.Title className="h3">Reports</Card.Title>  */}
                                        <div className="merchant-tab-btn">
                                            <ul className="report-link nav-report-btn">
                                            {hasPrivilagesFRM ?  <li className={activeButton === 1 ? "active" : ""} onClick={() => setactiveButton(1)}>
                                                    Fraud Management
                                                </li>: null}
                                           {userType ?  <li className={activeButton === 2 ? "active" : ""}onClick={() =>setactiveButton(2)}>
                                                     User Management
                                                </li> : null} 
                                                { hasPrivilagesSecurityManage? 
                                                <li className={activeButton === 3 ? "active" : ""} onClick={() => setactiveButton(3)}>
                                                    Security Mangement
                                                </li> : null}
                                                {/* { hasPrivilagesSSettlement?   <li className={activeButton === 4 ? "active" : ""} onClick={() => setactiveButton(4)}>
                                                    Settlement
                                                </li> : null}
                                                 */}
                                                 
                                                { hasPrivilagesNotification ?  <li className={activeButton === 5 ? "active" : ""} onClick={() => setactiveButton(5)}>
                                                    Notifications
                                                </li>: null}
                                                { hasPrivilagesSWithdraw?  <li className={activeButton === 6 ? "active" : ""} onClick={() => setactiveButton(6)}>
                                                    Withdraw
                                                </li>: null}
                                                
                                                
                                            </ul>
                                        </div>
                                    </Card.Body>
                                </Card>
                                {/* ./card */}
                            </Col>

                            {/* <Col xs={12} lg={12} md={12}>
                                <Card className="shadow border-0 mb-4">
                                    <Card.Body> */}
                                        <div className="merchant-report-view">
                                            {activeButton === 1 ? <SubTabForFRM /> 
                                            :
                                            activeButton === 2 ? <UserSubTab/> 
                                            : null}
                                        </div>
                                    {/* </Card.Body>
                                </Card>
                            </Col> */}
                        </Row>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MerchantSetting;