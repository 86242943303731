import React, { useState } from "react";
import InactiveServices from "../../common/InactiveServices";
// new import link
import "../../stylesheet/Reports.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RegisterPayout from "./RegisterPayout";
import RegisteredVendor from "./RegisteredVendor";
import AddVender from "./payout/AddVender";
import Card from "react-bootstrap/Card";
import PayoutSection from "./PayoutSection";
import WithdrawAmount from "../Tools/WithdrawAmount";
import MyLoader from "../../common/MyLoader";
import TransactionCallback from "./TransactionCallback";
import AdvanceSearcgBox from "../reports/AdvanceSearcgBox";
import PendingVenders from "./PendingVenders";
import AddPayoutBalance from "./AddPayoutBalance";
import Refund from "./Refund";
import UserSubTab from "./UserManagement/UserSubTab";
import CheckExportConst from "./UserManagement/CheckexportConst";
import { useSelector } from "react-redux";

function MerchantTools() {
  const [activeButton, setactiveButton] = useState();
  const [reportType, setReportType] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const { privilagesData } = useSelector((state) => state.privilages);
  console.log(privilagesData.length);

  const handleToolsButtonClick = (type, buttonNumber) => {
    setReportType(type);
    setactiveButton(buttonNumber);
  };

  
  const {userType} = useSelector(state=>state.privilages)
  
  const {
    reportPrivilages,
    SettingPrivilages,
    refferelPrivilages,
    PaymentsPrivilages,
    AddWalletBalancePrivilages,
    hasPrivilagesRegisterVendor,
    WithdrawPrivilages,
    hasPrivilagesSubmittedVendor,
    hasPrivilagesPayoutTxnCallback,
    hasPrivilagesTxnCallback,
    hasPrivilagesTxnStatusCheck,
    PayoutReqPrivilages,
  } = CheckExportConst();

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec merchant-tool-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {/* <li
                          className={activeButton === 3 ? "active" : ""}
                          onClick={() => handleToolsButtonClick("Deposit", 3)}
                        >
                          Deposit
                        </li> */}
                        {WithdrawPrivilages ? (
                          <li
                            className={activeButton === 4 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("WidhdrawAmount", 4)
                            }
                          >
                            Withdraw Amount
                          </li>
                        ) : null}

                         
                        {hasPrivilagesRegisterVendor ? (
                          <li
                            className={activeButton === 2 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("VendorRegister", 2)
                            }
                          >
                            Register Vendor
                          </li>
                        ) : null}

                        {PayoutReqPrivilages ? (
                          <li
                            className={activeButton === 5 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("registerPayout", 5)
                            }
                          >
                            Payout Request
                          </li>
                        ) : null}

                        {hasPrivilagesTxnStatusCheck ? (
                          <li
                            className={activeButton === 6 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("txnStatus", 6)
                            }
                          >
                            Txn Status Check
                          </li>
                        ) : null}

                        {hasPrivilagesTxnCallback ? (
                          <li
                            className={activeButton === 7 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("txnCallBack", 7)
                            }
                          >
                            Txn Callback
                          </li>
                        ) : null}
                        {hasPrivilagesPayoutTxnCallback ? (
                          <li
                            className={activeButton === 10 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("payoutTxnCallBack", 10)
                            }
                          >
                            Payout Txn Callback
                          </li>
                        ) : null}
                        {hasPrivilagesSubmittedVendor ? (
                          <li
                            className={activeButton === 8 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("pendingVenders", 8)
                            }
                          >
                            Submitted Vendors
                          </li>
                        ) : null}

                        {AddWalletBalancePrivilages ? (
                          <li
                            className={activeButton === 9 ? "active" : ""}
                            onClick={() =>
                              handleToolsButtonClick("AddPayout", 9)
                            }
                          >
                            Add Wallet Balance
                          </li>
                        ) : null}
                        {/* {userType ?  <li
                          className={activeButton === 11 ? "active" : ""}
                          onClick={() =>
                            handleToolsButtonClick("CreateUser", 11)
                          }
                        >
                          Create User
                        </li> : null} */}
                        
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>

              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0">
                  <div>
                    {reportType === "registerPayout" ? (
                      <PayoutSection setShowLoader={setShowLoader} />
                    ) : reportType === "txnStatus" ? (
                      <AdvanceSearcgBox setShowLoader={setShowLoader} />
                    ) : reportType === "VendorRegister" ? (
                      <AddVender setShowLoader={setShowLoader} />
                    ) : reportType === "txnCallBack" ? (
                      <TransactionCallback
                        reportType={reportType}
                        setShowLoader={setShowLoader}
                      />
                    ) : reportType === "payoutTxnCallBack" ? (
                      <TransactionCallback
                        reportType={reportType}
                        setShowLoader={setShowLoader}
                      />
                    ) : reportType === "WidhdrawAmount" ? (
                      <WithdrawAmount />
                    ) : reportType === "AddPayout" ? (
                      <AddPayoutBalance setShowLoader={setShowLoader} />
                    ) : reportType === "pendingVenders" ? (
                      <PendingVenders setShowLoader={setShowLoader} />
                    ) : reportType === "refund" ? (
                      <Refund />
                    ) : reportType === "CreateUser" ? (
                      <UserSubTab />
                    ) : (
                       null
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default MerchantTools;
