import React, { useEffect, useState } from "react";
// import Calendar from "react-calendar";
import MBDReportSorting from "./MBDReportSorting";
import "react-calendar/dist/Calendar.css";
import AdvancedSearchPopup from "./AdvancedSearchPopup";
import ChargebackReport from "./ChargebackReport";
import ViewRequestPayout from "./ViewRequestPayout";
import RegisteredVendor from "../Tools/RegisteredVendor";
import "../../assets/css/style.css";
import "../../stylesheet/Reports.css";
import { Container } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import MultipleFilter from "./MultipleFilter";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import TableForReports from "./TableForReports";
import MyLoader from "../../common/MyLoader";
import SettlementMultipleFilter from "./MultipleFilterComponents/SettlementMultipleFilter";
import SettlementAdvanceSearchPopup from "./AdvanceFilterComponents/SettlementAdvanceSearchPopup";
import WithdrawMultipleFilter from "./MultipleFilterComponents/WithdrawMultipleFilter";
import ChargebackMultipleFilter from "./MultipleFilterComponents/ChargebackMultipleFilter";
import ChargebackAdvanceFilter from "./AdvanceFilterComponents/ChargebackAdvanceFilter";
import GuaranteeMultipleFilter from "./MultipleFilterComponents/GuaranteeMultipleFilter";
import PayoutMultipleFilter from "./MultipleFilterComponents/PayoutMultipleFilter";
import PayoutAdvanceFilter from "./AdvanceFilterComponents/PayoutAdvanceFilter";
import CreditReport from "./CreditReport";
import ReconciliationAdvanceFilter from "./AdvanceFilterComponents/ReconciliationAdvanceFilter";
import ReconciliationMultipleFilter from "./MultipleFilterComponents/ReconciliationMutipleFilter";
import CheckExportConst from "../Tools/UserManagement/CheckexportConst";
import { fetchLiveTrackingReport } from "../../services/apiService";

function Reports() {
  const [reportType, setReportType] = useState("");
  const [isAdvancedSearchOpen, setIsAdvancedSearchOpen] = useState(false);
  const [apiResponse, setApiResponse] = useState();
  const [boxApiResponse, setBoxApiResponse] = useState();
  const [filterApiResponse, setFilterApiResponse] = useState([]);
  const [withdrawApiResponse, setWithdrawApiResponse] = useState([]);
  // const[reconciliationApiResponse ,setReconciliationApiResponse] = useState(false);
  const [activeButton, setActiveButton] = useState();
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [isMultipleFilterOpen, setIsMultipleFilterOpen] = useState(false);
  const [show2, setShow2] = useState(true);
  const [show, setShow] = useState(true);
  const [showLoader, setShowLoader] = useState(false);

  const handleMultipleFilter = () => {
    setIsMultipleFilterOpen(!isMultipleFilterOpen);
    setIsAdvancedSearchOpen(false);
    setIsDateFilterOpen(false);
    setShow2(!show2);
  };
  const handleAdvanceSearch = () => {
    setIsAdvancedSearchOpen(!isAdvancedSearchOpen);
    setIsDateFilterOpen(false);
    setIsMultipleFilterOpen(false);
    setShow(!show);
  };

  const handleCustomApiResponse = (response) => {
    setApiResponse(response);
  };
  const handleMultiCustomApiResponse = (response) => {
    setApiResponse(response);
  };

  const handleFilterMultiCustomApiResponse = (response) => {
    console.log(response);
    setFilterApiResponse(response);
  };

  const handleWithdrawMultiCustomApiResponse = (response) => {
    setWithdrawApiResponse(response);
  };

  useEffect(() => {
    console.log(boxApiResponse)
  }, [boxApiResponse])

  const handleReportButtonClick = (type, buttonNumber) => {
    setReportType(type);

    setActiveButton(buttonNumber);
  };
 
  const handleReset = () => {
    window.location.reload();
  };

  const {
    hasPrivilagesPayin,
    hasPrivilagesSettlement,
    hasPrivilagesGuarantee,
    hasPrivilagesLadger,
    hasPrivilagesRecon,
    hasPrivilagesChargeback,
    hasPrivilagesMyVendor,
    hasPrivilagesPayout,
    hasPrivilagesWithdraw,
    hasPrivilagesDeposit,
    hasPrivilagesHold,
    hasPrivilagesCredit,
    hasPrivilagesWalletBalance,
    hasPrivilagesRelease,
    hasPrivilagesEkyc,
    reportPrivilages
  } = CheckExportConst();

  console.log(hasPrivilagesPayin);

  return (
    <>
      {showLoader ? <MyLoader /> : ""}
      <section className="dashboard-header-sec">
        <Container>
          <div className="ds-header-sec">
            <Row>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4 ds-header-card">
                  <Card.Body>
                    {/* <Card.Title className="h3">Reports</Card.Title>  */}
                    <div className="merchant-tab-btn">
                      <ul className="report-link nav-report-btn">
                        {hasPrivilagesPayin ? (
                          <li
                            className={activeButton === 1 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("PayinReport", 1)
                            }
                          >
                            Payin
                          </li>
                        ) : null}
                        {hasPrivilagesSettlement ? (
                          <li
                            className={activeButton === 2 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("settlement", 2)
                            }
                          >
                            Settlement
                          </li>
                        ) : null}
                        {hasPrivilagesChargeback ? (
                          <li
                            className={activeButton === 3 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("chargeback", 3)
                            }
                          >
                            Chargeback
                          </li>
                        ) : null}
                        {hasPrivilagesMyVendor ? (
                          <li
                            className={activeButton === 4 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("myVender", 4)
                            }
                          >
                            My Vender
                          </li>
                        ) : null}

                        {/* <li
                          className={activeButton === 5 ? "active" : ""}
                          onClick={() =>
                            handleReportButtonClick("DepositeReport", 5)
                          }
                        >
                          Deposit
                        </li> */}
                        {hasPrivilagesPayout ? (
                          <li
                            className={activeButton === 6 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("PayoutReport", 6)
                            }
                          >
                            Payout
                          </li>
                        ) : null}
                        {hasPrivilagesWithdraw ? (
                          <li
                            className={activeButton === 7 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("WithdrawReport", 7)
                            }
                          >
                            Withdraw
                          </li>
                        ) : null}
                        {hasPrivilagesEkyc ? (
                          <li
                            className={activeButton === 8 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("eKycReport", 8)
                            }
                          >
                            e-KYC
                          </li>
                        ) : null}
                        {hasPrivilagesLadger ? (
                          <li
                            className={activeButton === 9 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("LedgerReport", 9)
                            }
                          >
                            Ledger
                          </li>
                        ) : null}

                        {hasPrivilagesGuarantee ? (
                          <li
                            className={activeButton === 10 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("GuaranteeReport", 10)
                            }
                          >
                            Guarantee
                          </li>
                        ) : null}

                        {hasPrivilagesRecon ? (
                          <li
                            className={activeButton === 11 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick(
                                "reconciliationReport",
                                11
                              )
                            }
                          >
                            Reconciliation
                          </li>
                        ) : null}
                        {hasPrivilagesCredit ? (
                          <li
                            className={activeButton === 12 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("creditReport", 12)
                            }
                          >
                            Credit Balance
                          </li>
                        ) : null}
                        {hasPrivilagesRelease ? (
                          <li
                            className={activeButton === 14 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("releaseReport", 14)
                            }
                          >
                            Release
                          </li>
                        ) : null}
                        {hasPrivilagesWalletBalance ? (
                          <li
                            className={activeButton === 15 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("walletBalanceReport", 15)
                            }
                          >
                            Wallet Balance
                          </li>
                        ) : null}

                        {hasPrivilagesHold ? (
                          <li
                            className={activeButton === 16 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("holdReport", 16)
                            }
                          >
                            Hold
                          </li>
                        ) : null}
                        {hasPrivilagesDeposit ? (
                          <li
                            className={activeButton === 17 ? "active" : ""}
                            onClick={() =>
                              handleReportButtonClick("depositReport", 17)
                            }
                          >
                            Deposit
                          </li>
                        ) : null}
                      </ul>
                    </div>
                  </Card.Body>
                </Card>
                {/* ./card */}
              </Col>
              <Col xs={12} lg={12} md={12}>
                <Card className="shadow border-0 mb-4">
                  <Card.Body>
                    <div className="merchant-report-view">
                      <div className="table-controls search-records-view">
                        <div className="parent-filter custom-filter-box">
                          {activeButton === 1 ||
                          activeButton === 12 ||
                          activeButton === 3 ||
                          activeButton === 2 ||
                          activeButton === 6 ||
                          activeButton === 7 ||
                          activeButton === 11 ||
                          activeButton === 10 ? (
                            <div className="Advanced-btn">
                              <button
                                className={`advanced-btn btn btn-primary   text-capitlize ${
                                  isMultipleFilterOpen ? "active" : ""
                                }`}
                                onClick={handleMultipleFilter}
                              >
                                <i className="bi bi-funnel-fill mx-1"></i>More
                                Filter
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          {activeButton === 1 ||
                          activeButton === 2 ||
                          activeButton === 2 ||
                          activeButton === 3 ||
                          activeButton === 11 ||
                          activeButton === 6 ? (
                            <div className="Advanced-btn">
                              <button
                                className={`advanced-btn btn btn-primary text-capitlize ${
                                  isAdvancedSearchOpen ? "active" : ""
                                }`}
                                onClick={handleAdvanceSearch}
                              >
                                <i className="bi bi-funnel-fill mx-1"></i>
                                Advanced Filter
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                          {activeButton === 1 ||
                          activeButton === 2 ||
                          activeButton === 3 ||
                          activeButton === 6 ||
                          activeButton === 12 ||
                          activeButton === 11 ||
                          activeButton === 7 ||
                          activeButton === 10 ? (
                            <div className="Advanced-btn">
                              <button
                                className={
                                  "advanced-btn btn btn-primary   text-capitlize"
                                }
                                onClick={handleReset}
                              >
                                <i class="bi bi-arrow-clockwise mx-1"> </i>Reset
                              </button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>

                        {isMultipleFilterOpen && activeButton === 1 ? (
                          <MultipleFilter
                            setCustomMultiApiResponse={
                              handleMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                            setBoxData={setBoxApiResponse}
                          />
                        ) : (
                          ""
                        )}

                        {isMultipleFilterOpen && activeButton === 2 ? (
                          <SettlementMultipleFilter
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                          />
                        ) : (
                          ""
                        )}

                        {/* {isMultipleFilterOpen && activeButton === 12
                          ? "jggjh"
                          : ""} */}

                        {isMultipleFilterOpen && activeButton === 3 ? (
                          <ChargebackMultipleFilter
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                          />
                        ) : (
                          ""
                        )}

                        {isMultipleFilterOpen && activeButton === 6 ? (
                          <PayoutMultipleFilter
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                          />
                        ) : (
                          ""
                        )}

                        {isMultipleFilterOpen && activeButton === 7 ? (
                          <WithdrawMultipleFilter
                            setCustomMultiApiResponse={
                              handleWithdrawMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                          />
                        ) : (
                          ""
                        )}

                        {isMultipleFilterOpen && activeButton === 10 ? (
                          <GuaranteeMultipleFilter
                            setCustomMultiApiResponse={
                              handleWithdrawMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                          />
                        ) : (
                          ""
                        )}
                        {isMultipleFilterOpen && activeButton === 11 ? (
                          <ReconciliationMultipleFilter
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                            setIsMultipleFilterOpen={setIsMultipleFilterOpen}
                            setShowLoader={setShowLoader}
                          />
                        ) : (
                          ""
                        )}

                        {isAdvancedSearchOpen && activeButton === 1 ? (
                          <div className="date-report">
                            <AdvancedSearchPopup
                              setShowLoader={setShowLoader}
                              setCustomApiResponse={handleCustomApiResponse}
                              setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {isAdvancedSearchOpen && activeButton === 2 ? (
                          <div className="date-report">
                            <SettlementAdvanceSearchPopup
                              setShowLoader={setShowLoader}
                              setCustomApiResponse={
                                handleFilterMultiCustomApiResponse
                              }
                              setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}

                        {isAdvancedSearchOpen && activeButton === 3 ? (
                          <div className="date-report">
                            <ChargebackAdvanceFilter
                              setShowLoader={setShowLoader}
                              setCustomApiResponse={
                                handleFilterMultiCustomApiResponse
                              }
                              setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {isAdvancedSearchOpen && activeButton === 6 ? (
                          <div className="date-report">
                            <PayoutAdvanceFilter
                              setShowLoader={setShowLoader}
                              setCustomApiResponse={
                                handleFilterMultiCustomApiResponse
                              }
                              setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {isAdvancedSearchOpen && activeButton === 11 ? (
                          <div className="date-report">
                            <ReconciliationAdvanceFilter
                              setShowLoader={setShowLoader}
                              setCustomApiResponse={
                                handleFilterMultiCustomApiResponse
                              }
                              setIsAdvanceFilterOpen={setIsAdvancedSearchOpen}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {reportType === "settlement" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData={filterApiResponse}
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                          />
                        ) : reportType === "chargeback" ? (
                          <ChargebackReport
                            setShowLoader={setShowLoader}
                            responseData={filterApiResponse}
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                          />
                        ) : reportType === "myVender" ? (
                          <RegisteredVendor setShowLoader={setShowLoader} />
                        ) : reportType === "PayinReport" ? (
                          <MBDReportSorting
                            data={apiResponse}
                            boxApiResponse={boxApiResponse}
                            setShowLoader={setShowLoader}
                          />
                        ) : reportType === "DepositeReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "PayoutReport" ? (
                          <ViewRequestPayout
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData={filterApiResponse}
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                          />
                        ) : reportType === "WithdrawReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData={withdrawApiResponse}
                            setCustomMultiApiResponse={
                              handleWithdrawMultiCustomApiResponse
                            }
                          />
                        ) : reportType === "eKycReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "LedgerReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "GuaranteeReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData={withdrawApiResponse}
                            setCustomMultiApiResponse={
                              handleWithdrawMultiCustomApiResponse
                            }
                          />
                        ) : reportType === "reconciliationReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData={filterApiResponse}
                            setCustomMultiApiResponse={
                              handleFilterMultiCustomApiResponse
                            }
                          />
                        ) : reportType === "settlementAdjustmentReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "releaseReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "creditReport" ? (
                          <CreditReport
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "walletBalanceReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "holdReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "depositReport" ? (
                          <TableForReports
                            reportType={reportType}
                            setShowLoader={setShowLoader}
                            responseData=""
                            setCustomMultiApiResponse=""
                          />
                        ) : reportType === "PayinReport" ? (
                          <MBDReportSorting
                            data={apiResponse}
                            setShowLoader={setShowLoader}
                          />
                        ) : null}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Reports;
